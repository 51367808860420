import { Explosives } from './Explosives';
import { Boosters }   from './Explosives';

export default {
    breachpop1QCC:          {
        name:         'Breachpop # 1 QCC',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 17.78, // 7 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop2DirectPrimed: {
        name:         'Breachpop # 2 Direct Primed',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 7.62, // 3 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop2QCC:          {
        name:         'Breachpop # 2 QCC',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 17.78, // 7 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop2MiniNunchuck: {
        name:         'Breachpop # 2 Mini Nunchuck',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 2,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 33.02, // 13 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop3QCC:          {
        name:         'Breachpop # 3 QCC',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 2,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 17.78, // 7 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop3MiniNunchuck: {
        name:         'Breachpop # 3 Mini Nunchuck',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 2,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 3,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 4,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 33.02, // 13 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop4QCC:          {
        name:         'Breachpop # 4 QCC',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 2,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 3,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 17.78, // 7 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpop4MiniNunchuck: {
        name:         'Breachpop # 4 Mini Nunchuck',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 1,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 2,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 3,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 4,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 5,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 12.7, // 5 inch
                },
                {
                    id:                 6,
                    explosiveType:      Explosives.DetCord10Gram.key,
                    lengthInCentimeter: 33.02, // 13 inch
                },
            ],
            primingCount: 1,
        },
    },
    breachpopC410Grams:     {
        name:         'Breachpop C4 10 grams',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                             0,
                    explosiveType:                  Explosives.plasticC4.key,
                    weightInGramPerCubicCentimeter: 10,
                },
            ],
            primingCount: 1,
        },
    },
    breachpopC415Grams:     {
        name:         'Breachpop C4 15 grams',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                             0,
                    explosiveType:                  Explosives.plasticC4.key,
                    weightInGramPerCubicCentimeter: 15,
                },
            ],
            primingCount: 1,
        },
    },
    breachpopSheetC1:       {
        name:         'Breachpop Sheet C1',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.sheetC1.key,
                    lengthInCentimeter: 12.7,
                    widthInCentimeter:  1.27,
                },
            ],
            primingCount: 1,
        },
    },
    breachpopSheetC2:       {
        name:         'Breachpop Sheet C2',
        breachReport: {
            boosters:     [
                {
                    id:          0,
                    boosterType: Boosters.none,
                },
            ],
            explosives:   [
                {
                    id:                 0,
                    explosiveType:      Explosives.sheetC2.key,
                    lengthInCentimeter: 12.7,
                    widthInCentimeter:  1.27,
                },
            ],
            primingCount: 1,
        },
    },
};