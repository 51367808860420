import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import Link            from '../Link';

class FooterMenuItem extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    renderLink () {
        if (this.props.external) {
            return (
                <a
                    className={styles.footerLink}
                    href={this.props.to}
                    target={'_blank'}
                >
                    {this.props.label}
                </a>
            );
        }

        return (
            <Link
                className={styles.footerLink}
                to={this.props.to}
            >
                {this.props.label}
            </Link>
        );
    }

    render () {
        return (
            <div className={styles.container}>
                {this.renderLink()}
            </div>
        );
    }
}

const Component = FooterMenuItem;

Component.propTypes = {
    label:    PropTypes.string,
    to:       PropTypes.string,
    external: PropTypes.bool,
};

Component.defaultProps = {
    label:    '',
    to:       '',
    external: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;