const english = {
    activeUnit:                                             'Active Unit',
    alertBoxErrorText:                                      'Error',
    alertBoxSuccessText:                                    'Success',
    and:                                                    'and',
    automatic:                                              'Automatic',
    back:                                                   'Back',
    blast:                                                  'Blast',
    blockWall:                                              'Block Wall (See note)',
    bookmarkBannerText:                                     'Hint: Bookmark this page to get offline access to the app',
    booster:                                                'Booster',
    boosters:                                               'Boosters',
    breach:                                                 'Breach',
    breacher:                                               'Breacher',
    breacherInfo:                                           'Breacher Info',
    breacherInfoFormTitle:                                  'Please enter your information here.\n This data is used to autofill your breach reports.',
    breacherTape:                                           'Breacher Tape',
    breachpops:                                             'Breachpop’s',
    breachReport:                                           'Breach Report',
    breachReportListHeadline:                               'Saved Breach Reports',
    breachReportPrintMultipleTitle:                         'Breach Reports',
    breachReportPrintTitle:                                 'Breach Report {{shotNumber}}',
    breachReports:                                          'Breach Reports',
    breachReportTitleWithShotNumber:                        'Shot Number: {{shotNumber}}',
    brickWall:                                              'Brick Wall (See note)',
    buyLicenseButtonText:                                   'Buy license',
    buyLicenseText:                                         'You don\'t have a license yet? Buy one now and save unlimited breach reports.',
    buyOrEnterLicenseButtonText:                            'Buy or enter license',
    calculation:                                            'Calculation',
    cancelPrint:                                            'Cancel Print',
    carpetTape:                                             'Carpet Tape',
    checkLicenseKey:                                        'Check license key',
    checkLicenseKeyError1:                                  'No license found for this key. Please double check your key.',
    clamLicenseKeyError1:                                   'An error occurred while claiming your license. Please try again later.',
    clamLicenseKeySuccess1:                                 'Thank you. The application was unlocked successfully!',
    class1Metal:                                            'Class I (Light) Metal',
    class2Metal:                                            'Class II (Heavy) Metal',
    cm:                                                     'cm',
    comments:                                               'Comments',
    copyright:                                              'Copyright 2020 P3D Solutions',
    country:                                                'Country',
    custom:                                                 'Custom',
    customCalculation:                                      'Custom Calculation',
    cut:                                                    'Cut',
    dataProtection:                                         'Data Protection',
    date:                                                   'Date',
    dateBuild:                                              'Date build',
    dateInputDateFormat:                                    'MM/dd/YYY HH:mm',
    dateUsed:                                               'Date used',
    day:                                                    'Day',
    delete:                                                 'Delete',
    developmentAndDesign:                                   'Development and Design',
    description:                                            'Description',
    diagram:                                                'Diagram',
    doubleSidedTape:                                        'Double sided Tape',
    dual:                                                   'Dual',
    duplicate:                                              'Duplicate',
    eardrumRupture:                                         'Eardrum Rupture',
    eardrumRuptureColumnTitle:                              '35 kPa / 5 PSI',
    edit:                                                   'Edit',
    effectTableHeader:                                      'Effect (Short duration- 3 milliseconds)',
    error404:                                               '404',
    explosive:                                              'Explosive',
    explosives:                                             'Explosives',
    explosiveValueHeightInCentimeter:                       'Height',
    explosiveValueLengthInCentimeter:                       'Length',
    explosiveValueWeightInGramPerCubicCentimeter:           'Weight',
    explosiveValueWidthInCentimeter:                        'Width',
    feet:                                                   'Feet',
    feetShort:                                              'ft',
    fiberglass:                                             'Fiberglass',
    footerLogoAlternativeText:                              'Footer Logo',
    germany:                                                'Germany',
    glassBreakage:                                          'Glass Breakage',
    glassBreakageColumnTitle:                               '3 kPa / 0.5 PSI',
    grain:                                                  'grain',
    grainShort:                                             'Gr',
    grams:                                                  'grams',
    gramShort:                                              'g',
    height:                                                 'Height',
    hollowCoreWood:                                         'Hollow Core (Light) Wood',
    home:                                                   'Home',
    hydrogel:                                               'Hydrogel',
    imperial:                                               'Standard',
    imprint:                                                'Imprint',
    inches:                                                 'inches',
    info:                                                   'Info',
    inside:                                                 'Inside',
    installableBannerText:                                  'You can install the "BreachPop Breacher App" to get offline access to the app',
    interior:                                               'Interior',
    internalPoundForcePerSquareInchShortLabel:              'Internal PSI',
    internalPressure:                                       'Internal pressure',
    kiloPascalShort:                                        'kPa',
    landingPageOptionSelectSubTitle:                        'Explosive Calculator and Breach Reports',
    landingPageOptionSelectTitle:                           'Breacher App',
    length:                                                 'Length',
    license:                                                'License',
    licenseKey:                                             'License key',
    licenseRequiredError1:                                  'You have to buy a license if you want to create a breach report.',
    licenseRequiredError2:                                  'Buy a license to access calculations for the full list of explosives. 18gr det cord and C1.5 sheet available for free.',
    light:                                                  'Light',
    loading:                                                'Loading...',
    location:                                               'Location',
    logoAlternativeText:                                    'Breach pop logo',
    lungDamage:                                             'Lung Damage',
    lungDamageColumnTitle:                                  '200 kPa / 29 PSI',
    manual:                                                 'Manual',
    masterBreacher:                                         'Master Breacher',
    maximumIncidentPressure:                                'Maximum Incident Pressure',
    meter:                                                  'Meter',
    meterShort:                                             'm',
    method:                                                 'Method',
    methodOfAttachment:                                     'Method of Attachment',
    methodOfAttachmentDescription:                          'Method of Attachment Description',
    methodOfAttachmentOption:                               'Method of Attachment Option',
    metric:                                                 'Metric',
    minimumSafeStackingDistance:                            'MSD with K=20; Double in Training',
    minimumSafeStackingDistanceColumnTitle:                 '27.5 kPa / 4 PSI',
    minimumSafeStackingDistanceShort:                       'MSD',
    mission:                                                'Mission',
    more:                                                   'more',
    name:                                                   'Name',
    nameCallSign:                                           'Name/Call Sign',
    negative:                                               'Negative',
    netExplosiveWeightShort:                                'N.E.W.',
    netExplosiveWeightToMinimumSafeStackingDistanceDiagram: 'NEW to MSD Diagram',
    netExplosiveWeightToMinimumSafeStackingDistanceShort:   'NEW to MSD',
    newBreachReport:                                        'New Breach Report',
    newReport:                                              'New Report',
    newVersionHintLaterButton:                              'Later',
    newVersionHintText:                                     'A new version of this application is available, please update now.',
    newVersionHintUpdateButton:                             'Update',
    night:                                                  'Night',
    no:                                                     'No',
    none:                                                   'None',
    norway:                                                 'Norway',
    notSafeToStackInRoomAlertMessage:                       'DO NOT stack in the room/hallway. Internal overpressure is too high.',
    oclock:                                                 'o\'clock',
    ok:                                                     'Ok',
    operator:                                               'Operator',
    option:                                                 'Option',
    or:                                                     'or',
    other:                                                  'Other',
    p3dSolutions:                                           'P3D Solutions',
    p3dSolutionsLink:                                       'https://www.p3dsolutions.com/',
    pageNotFound:                                           'Page not found',
    pageTitle:                                              'Breach Pop',
    partial:                                                'Partial',
    photos:                                                 'Photos',
    positive:                                               'Positive',
    poundForcePerSquareInchShort:                           'PSI',
    preCalculated:                                          'Pre Calculated',
    preCalculatedBreachpops:                                'Pre Calculated Breachpops',
    priming:                                                'Priming',
    print:                                                  'Print',
    printDateFormat:                                        'MM/DD/YYYY',
    printSelected:                                          'Print Selected',
    printTimeFormat:                                        'HH:mm',
    privacyAndSafety:                                       'Privacy and Safety',
    push:                                                   'Push',
    releaseLicense:                                         'Release license',
    releaseLicenseKeyError1:                                'An error occurred while releasing your license, please try again later.',
    releaseLicenseKeySuccess1:                              'You license was successfully released, you can now use it on another computer.',
    reportListDateFormat:                                   'MM/DD/YYYY HH:mm',
    reports:                                                'Reports',
    result:                                                 'Result',
    room:                                                   'Room',
    roomVolumeLabel:                                        'Room volume {{unit}}³',
    save:                                                   'Save',
    saveAsBreachReport:                                     'Save as Breach Report',
    search:                                                 'Search',
    searchFieldPlaceholder:                                 'Search by Name/Call Sign, Target Description, Date (xx/xx/xxxx), or Results',
    searchPatternError:                                     'Please enter at least 3 characters to search.',
    securityDoor:                                           'Security Door',
    selectBooster:                                          'Select booster',
    selectCountry:                                          'Select country',
    selectExplosive:                                        'Select explosive',
    selectMultiple:                                         'Select Multiple',
    selectNoSearchResults:                                  'No results',
    selectShotNumberBaseDate:                               'Select shot number base date',
    selectShotNumberMode:                                   'Select shot number mode',
    semiAutomatic:                                          'Semi-Automatic',
    settings:                                               'Settings',
    sheetrockWall:                                          'Sheetrock Wall (See note)',
    shotNumber:                                             'Shot number',
    shotNumberAutomation:                                   'Shot number automation',
    shotNumberBaseDate:                                     'Shot number base date',
    shotNumberMode:                                         'Shot number mode',
    single:                                                 'Single',
    solidWood:                                              'Solid (Heavy) Wood',
    squadron:                                               'Squadron',
    target:                                                 'Target',
    targetDescription:                                      'Target Description',
    targetOption:                                           'Target Option',
    team:                                                   'Team',
    teamSquadron:                                           'Team/Squadron',
    termsConditionsAndDisclaimer:                           'Terms, Conditions & Disclaimer',
    time:                                                   'Time',
    title:                                                  'Breach Report Title',
    tntEquivalent:                                          'TNT Equivalent',
    totalNetExplosiveWeight:                                'Total N.E.W.',
    totalNetExplosiveWeightMinimumSafeStackingDistance:     'Total N.E.W MSD',
    training:                                               'Training',
    type:                                                   'Type',
    typeOfBreachpop:                                        'Type of Breachpop',
    typeOfCharge:                                           'Charge Name',
    user:                                                   'User',
    versionNumberDateFormat:                                'MM/DD/YYYY HH:mm:ss',
    width:                                                  'Width',
    window:                                                 'Window (See note)',
    windowSize:                                             'Window size',
    yes:                                                    'Yes',
};

module.exports = english;
