import convert        from 'convert-units';
import _              from 'lodash';
import { Explosives } from '../constants/Explosives';
import { Initiator }  from '../constants/Explosives';
import { Boosters }   from '../constants/Explosives';
import Units          from '../constants/Units';

class ExplosiveCalculator {
    static calculateBreachValues ({ explosives, room = {}, primingCount, boosters }) {
        const netExplosiveWeight   = ExplosiveCalculator.calculateNetExplosiveWeight(
            explosives,
            primingCount,
            boosters,
        );
        const tntEquivalentInGram  = ExplosiveCalculator.calculateTotalTNTEquivalentInGram(
            explosives,
            primingCount,
            boosters,
        );
        const internalPSI          = ExplosiveCalculator.calculateInternalPressureInPSI(
            explosives,
            primingCount,
            boosters,
            room,
            tntEquivalentInGram,
        );
        const safeStackingDistance = ExplosiveCalculator.calculateSafeStackingDistance(
            explosives,
            primingCount,
            boosters,
            tntEquivalentInGram,
        );

        return {
            internalPSI,
            netExplosiveWeight,
            safeStackingDistance,
            tntEquivalentInGram,
        };
    }

    static calculateNetExplosiveWeight (explosives = [], primingCount = 0, boosters = null) {
        let netExplosiveWeight = 0;

        _.forEach(boosters, (booster) => {
            const boosterValues           = _.get(Boosters, [booster.boosterType]);
            const boosterBaseWeightInGram = this.getBoosterWeightInGram(boosterValues);

            netExplosiveWeight += boosterBaseWeightInGram;
        });

        _.forEach(explosives, (explosive) => {
            netExplosiveWeight += this.calculateExplosiveWeight(explosive);
        });

        netExplosiveWeight += primingCount * Initiator.weightInGram;

        return netExplosiveWeight;
    }

    static calculateExplosiveWeight (explosive) {
        const explosiveType          = _.get(explosive, 'explosiveType', null);
        const explosiveConfiguration = Explosives[explosiveType];

        if (!explosiveConfiguration) {
            return 0;
        }

        const heightInCentimeter             = _.get(
            explosive,
            'heightInCentimeter',
            explosiveConfiguration.values.heightInCentimeter,
        );
        const widthInCentimeter              = _.get(
            explosive,
            'widthInCentimeter',
            explosiveConfiguration.values.widthInCentimeter,
        );
        const lengthInCentimeter             = _.get(
            explosive,
            'lengthInCentimeter',
            explosiveConfiguration.values.lengthInCentimeter,
        );
        const weightInGramPerCubicCentimeter = _.get(
            explosive,
            'weightInGramPerCubicCentimeter',
            explosiveConfiguration.values.weightInGramPerCubicCentimeter,
        );

        const explosiveNetWeightInGram = (
            heightInCentimeter
            * widthInCentimeter
            * lengthInCentimeter
            * weightInGramPerCubicCentimeter
        );

        if (!isNaN(explosiveNetWeightInGram)) {
            return explosiveNetWeightInGram;
        }

        return 0;
    }

    static calculateTotalTNTEquivalentInGram (explosives, primingCount, boosters) {
        let tntEquivalentInGram            = 0;
        const explosiveTntEquivalentInGram = this.getTotalExplosiveTntEquivalentInGram(explosives);
        const boosterTntEquivalentInGram   = this.getTotalBoosterTntEquivalentInGram(boosters);

        tntEquivalentInGram += explosiveTntEquivalentInGram;
        tntEquivalentInGram += boosterTntEquivalentInGram;
        tntEquivalentInGram += primingCount * Initiator.weightInGram * Initiator.relativeEffectivenessFactor;

        return tntEquivalentInGram;
    }

    static getTotalExplosiveTntEquivalentInGram (explosives) {
        let totalExplosiveTntEquivalentInGram = 0;

        _.forEach(explosives, (explosive) => {
            const explosiveType          = _.get(explosive, 'explosiveType', null);
            const explosiveConfiguration = Explosives[explosiveType];

            if (!explosiveConfiguration) {
                return 0;
            }

            const relativeEffectivenessFactor  = explosiveConfiguration.values.relativeEffectivenessFactor;
            const explosiveNetWeightInGram     = this.calculateExplosiveWeight(explosive);
            const explosiveTntEquivalentInGram = explosiveNetWeightInGram * relativeEffectivenessFactor;

            if (!isNaN(explosiveTntEquivalentInGram)) {
                totalExplosiveTntEquivalentInGram += explosiveNetWeightInGram * relativeEffectivenessFactor;
            }
        });

        return totalExplosiveTntEquivalentInGram;
    }

    static getTotalBoosterTntEquivalentInGram (boosters) {
        let totalBoosterTntEquivalentInGram = 0;

        _.forEach(boosters, (booster) => {
            const boosterValues                      = _.get(Boosters, [booster.boosterType]);
            const boosterBaseWeightInGram            = this.getBoosterWeightInGram(boosterValues);
            const boosterRelativeEffectivenessFactor = this.getBoosterRelativeEffectivenessFactor(boosterValues);
            const boosterTntEquivalentInGram         = boosterBaseWeightInGram * boosterRelativeEffectivenessFactor;

            if (!isNaN(boosterTntEquivalentInGram)) {
                totalBoosterTntEquivalentInGram += boosterTntEquivalentInGram;
            }
        });

        return totalBoosterTntEquivalentInGram;
    }

    static getBoosterRelativeEffectivenessFactor (boosterValues) {
        return _.get(boosterValues, 'relativeEffectivenessFactor', 0);
    }

    static getBoosterWeightInGram (boosterValues) {
        return _.get(boosterValues, 'weightInGram', 0);
    }

    static calculateInternalPressureInPSI (explosives, primingCount, boosters = null, room, tntEquivalentInGram) {
        const psiFactor               = 0.72;
        const gramToGrainFactor       = 15.6;
        const roomVolumeInSquareMeter = room.widthInMeter * room.lengthInMeter * room.heightInMeter;

        if (roomVolumeInSquareMeter > 0) {
            const gramPerSquareMeter    = tntEquivalentInGram / roomVolumeInSquareMeter;
            const internalPressureInkPa = Math.pow(gramPerSquareMeter, psiFactor) * gramToGrainFactor;

            return convert(internalPressureInkPa).from(Units.InternalPressureKiloPascalUnit).to(Units.InternalPressureBase);
        }

        return null;
    }

    /**
     * Rounds a value up to the next value with on decimal.
     * e.g: 1.73 => 1.8
     *      1.70 => 1.7
     *      1.79 => 1.8
     *
     * @param value
     * @returns {number}
     */
    static roundUpToOneAfterDecimal (value) {
        return Math.ceil(value * 10) / 10;
    }

    static calculateSafeStackingDistance (explosives, primingCount, boosters = null, tntEquivalentInGram) {
        const scaledDistanceFor25kPa     = 6.297183946;
        const hemisphericalBlastMach     = tntEquivalentInGram * 2;
        const hemisphericalBlastMachInKg = hemisphericalBlastMach / 1000;
        const cubeRoot                   = Math.cbrt(hemisphericalBlastMachInKg);
        const distanceInMeter            = cubeRoot * scaledDistanceFor25kPa;

        return !isNaN(distanceInMeter) ? distanceInMeter : 0;
    }
}

export default ExplosiveCalculator;