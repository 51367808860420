import PropTypes from '../../PropTypes';
import React from 'react';
import ComponentHelper from '../../../helper/ComponentHelper';
import HeadlineWithButton from '../HeadlineWithButton';
import I18n from 'i18next';
import _ from 'lodash';

class BreachReportListHeader extends React.Component {
    render () {
        return (
            <HeadlineWithButton
                buttonPressed={this.props.newReportPressed}
                buttonText={I18n.t('newReport')}
                headlineText={I18n.t('breachReportListHeadline')}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreachReportListHeader;

Component.propTypes = {
    newReportPressed: PropTypes.func,
};

Component.defaultProps = {
    newReportPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;