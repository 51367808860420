import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import Header          from '../components/stateless/Header';
import ImageBackground from '../components/stateless/ImageBackground';
import Colors          from '../styles/colors.scss';
import CenterContainer from '../components/stateless/CenterContainer';
import Headline        from '../components/stateless/Headline';
import HeadlineTheme   from '../components/stateless/Headline/HeadlineTheme';
import LongText        from '../components/stateless/LongText';
import Link            from '../components/stateless/Link';
import Routes          from '../constants/Routes';
import Logo            from '../components/stateless/Logo';
import LogoType        from '../components/stateless/Logo/LogoType';
import styles          from '../styles.module.scss';

class Screen extends Component {
    render () {
        return (
            <div>
                <Header
                    title={I18n.t('pageNotFound')}
                />
                <ImageBackground
                    image={require('../assets/images/landingPageBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'100%'}
                >
                    <div
                        className={styles.contentViewWithPadding}
                    >
                        <Headline
                            theme={HeadlineTheme.whiteBigCentered}
                            title={I18n.t('error404')}
                        />
                        <LongText>
                            {I18n.t('pageNotFound')}
                        </LongText>
                    </div>
                </ImageBackground>
            </div>
        );
    }
}

export default Screen;