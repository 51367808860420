import PropTypes          from '../../PropTypes';
import React              from 'react';
import { connect }        from 'react-redux';
import ComponentHelper    from '../../../helper/ComponentHelper';
import StatelessStartMenu from '../../stateless/StartMenu';

class StartMenu extends React.Component {
    render () {
        return (
            <StatelessStartMenu
                breachReportCount={Object.keys(this.props.breachReports).length}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = StartMenu;

Component.propTypes = {
    breachReports: PropTypes.object,
};

Component.defaultProps = {
    breachReports: {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        breachReports: state.breachReports.breachReports,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);