import React              from 'react';
import PropTypes          from '../../PropTypes';
import I18n               from 'i18next';
import ComponentHelper    from '../../../helper/ComponentHelper';
import FormGroup          from '../FormGroup';
import InputContainer     from '../InputContainer';
import SelectInput        from '../SelectInput';
import _                  from 'lodash';
import { PrimingOptions } from '../../../constants/Breach';

class PrimingSelectionInput extends React.Component {
    render () {
        return (
            <FormGroup
                label={I18n.t('priming')}
            >
                <InputContainer
                    label={I18n.t('priming')}
                >
                    <SelectInput
                        value={this.props.value}
                        onChange={this.props.onValueChanged}
                        options={PrimingOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = PrimingSelectionInput;

Component.propTypes = {
    onValueChanged: PropTypes.func,
    value:          PropTypes.number,
};

Component.defaultProps = {
    onValueChanged: _.noop,
    value:          1,
};

Component.renderAffectingProps = [
    'value',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;