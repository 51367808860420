import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import React           from 'react';
import PropTypes       from '../../PropTypes';
import styles          from './styles.module.scss';
import _               from 'lodash';
import DatePicker      from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import moment          from 'moment';

class DateInput extends React.Component {
    onChangeRaw = (event) => {
        // This will prevent typing in the date input
        event.preventDefault();
    };

    render () {
        const date = this.props.date ? moment(this.props.date).toDate() : null;

        return (
            <div className={styles.dateInputWrapper}>
                <DatePicker
                    dateFormat={I18n.t('dateInputDateFormat')}
                    id={this.props.id}
                    onChange={this.props.onChange}
                    onChangeRaw={this.onChangeRaw}
                    selected={date}
                    showPopperArrow={false}
                    showWeekNumbers={true}
                    showTimeSelect
                    timeIntervals={5}
                />
                <div
                    className={
                        styles.calendarIcon
                    }
                >
                    <Icon
                        iconType={IconType.calendar}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = DateInput;

Component.propTypes = {
    date:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number,
    ]),
    id:       PropTypes.string,
    onChange: PropTypes.func,
};

Component.defaultProps = {
    date:     null,
    id:       null,
    onChange: _.noop,
};

Component.renderAffectingProps = [
    'date',
];

Component.renderAffectingStates = [];

export default DateInput;