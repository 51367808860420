import PropTypes from '../../PropTypes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {BreachReportsActions} from '../../../store/actions/breachReports';

import React from 'react';
import styles from './styles.module.scss';
import ComponentHelper from '../../../helper/ComponentHelper';
import ColorButton from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import _ from 'lodash';
import classNames from 'classnames';
import ImageStorage from '../../../helper/ImageStore';

class HeadlineWithButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filesUpload: []
        };
    }

    uploadFile = (e) => {
        const files = e.target.files;

        _.map(files, (file) => {
            const reader = new FileReader();
            reader.readAsText(file,  "UTF-8");
            reader.onload = async (e) => {
                const breachReport = JSON.parse(e.target.result);

                if (breachReport.id && breachReport.images) {
                    for (let image of breachReport.images) {
                        ImageStorage.saveImage(image);
                    }
                    breachReport.images = await Promise.all(
                        breachReport.images.map((image) => {
                            return ImageStorage.saveImage(image);
                        })
                    )
                    this.props.uploadFilesRequest({breachReport});
                }
            }
          });
    }

    render() {
     return (
            <div
                className={classNames(
                    styles.container,
                    {
                        [styles.containerNoMarginBottom]: !this.props.hasMarginBottom,
                    },
                )}
            >
                <div className={styles.headerContent}>
                    <h2>
                        {this.props.headlineText}
                    </h2>
                </div>
                <div className={styles.headerOptions}>
                    <ColorButton
                        theme={ColorButtonTheme.yellow}
                        text={this.props.buttonText}
                        onClick={this.props.buttonPressed}
                    />
                    {
                        this.props.isUnlocked &&  <label className={styles.fileUpload}>
                        <input className={ styles.uploadInput} accept="application/json" multiple type='file' onChange={this.uploadFile}/>
                            Upload
                        </label>
                    }
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = HeadlineWithButton;

Component.propTypes = {
    buttonPressed: PropTypes.func,
    buttonText: PropTypes.string,
    hasMarginBottom: PropTypes.bool,
    headlineText: PropTypes.string,
};

Component.defaultProps = {
    buttonPressed: _.noop,
    buttonText: '',
    hasMarginBottom: true,
    headlineText: '',
};

Screen.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isUnlocked:  state.license.isUnlocked,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    BreachReportsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
