import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import PropTypes       from '../../PropTypes';
import styles          from './styles.module.scss';
import classNames      from 'classnames';

class CenterContainer extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={classNames(
                    styles.centerContainer,
                    {
                        [styles.centerContainerCenterContent]: this.props.centerContent,
                    },
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

const Component = CenterContainer;

Component.propTypes = {
    centerContent: PropTypes.bool,
    children:      PropTypes.children,
};

Component.defaultProps = {
    centerContent: false,
    children:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;