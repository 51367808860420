import _                        from 'lodash';
import moment                   from 'moment';
import { ShotNumberMode }       from '../constants/ShotNumberGenerator';

class ShotNumberGenerator {
    static getNextShotNumberByStats (state) {
        const breachReports      = state.breachReports.breachReports;
        const shotNumberMode     = state.settings.shotNumberMode;
        const shotNumberBaseDate = state.settings.shotNumberBaseDate;
        const nextShotNumber     = ShotNumberGenerator.findNextShotNumber(
            moment(),
            breachReports,
            shotNumberMode,
            shotNumberBaseDate,
        );

        return nextShotNumber;
    };

    static findNextShotNumber (date, breachReports, shotNumberMode, shotNumberBaseDate) {
        let filteredBreachReports = [];

        if (shotNumberMode === ShotNumberMode.manual) {
            return null;
        }

        if (
            shotNumberMode === ShotNumberMode.automatic ||
            shotNumberMode === ShotNumberMode.semiAutomatic
        ) {

            filteredBreachReports = _.filter(
                _.values(breachReports),
                (breachReport) => {
                    const reportDate = breachReport[shotNumberBaseDate];

                    if (
                        reportDate &&
                        date
                    ) {
                        return moment(reportDate).isSame(moment(date), 'day');
                    }

                    return false;
                },
            );
        } else {
            filteredBreachReports = breachReports;
        }

        const maxValue                      = _.orderBy(filteredBreachReports, ['shotNumber'], ['desc']);
        const breachReportWithMaxShotNumber = _.head(maxValue);
        let nextShotNumber                  = 1;

        if (breachReportWithMaxShotNumber) {
            nextShotNumber += breachReportWithMaxShotNumber.shotNumber;
        }

        return nextShotNumber;
    };
}

export default ShotNumberGenerator;