import _                             from 'lodash';
import I18n                          from 'i18next';
import moment                        from 'moment';
import update                        from 'immutability-helper';
import { BreachReportsTypes }        from '../actions/breachReports';
import { Boosters }                  from '../../constants/Explosives';
import { Explosives }                from '../../constants/Explosives';
import convert                       from 'convert-units';
import Units                         from '../../constants/Units';
import ExplosiveCalculator           from '../../helper/ExplosiveCalculator';
import { BreachResultTargetOptions } from '../../constants/Breach';

const initialState = {
    breachReports:        {},
    nextShotNumber:       1,
    searchQuery:          null,
    foundBreachReports:   {},
    breachReportsToPrint: [],
};

const convertValues = (value, from, to) => {
    return convert(value)
        .from(from)
        .to(to);
};

const getHumanReadableDateFormat = (timestamp) => {
    const date = moment(timestamp);

    if (date.isValid()) {
        return date.format(I18n.t('reportListDateFormat'));
    }

    return null;

};

const getHumanReadableName = (item, elements) => {
    return _.get(_.find(elements, (element) => element.value === item), 'label', null);
};

const getHumanReadableNameForRelations = (items, fieldName, elements) => {
    items.forEach((item) => {
        const value = item[fieldName];

        item.name = _.get(
            elements,
            [
                value,
                'name',
            ],
            '',
        );
    });

    return items;
};

const removeFalseAndNullValues = (breachReport) => {
    Object.keys(breachReport).forEach((breachReportKey) => {
        const value = breachReport[breachReportKey];

        if (!value) {
            delete breachReport[breachReportKey];
        }
    });

    return breachReport;
};

const findBreachReportsBySearchQuery = (breachReports, searchQuery) => {
    if (!searchQuery) {
        return initialState.foundBreachReports;
    }

    const matchingBreachReports = {};
    const preparedBreachReports = {};

    Object
        .keys(breachReports)
        .forEach((breachReportIndex) => {
            const breachReport                       = _.cloneDeep(breachReports[breachReportIndex]);
            const saveDistance                       = convertValues(
                breachReport.safeStackingDistance,
                Units.Distance.metric,
                Units.Distance.imperial,
            );
            breachReport.humanCreationDate           = getHumanReadableDateFormat(breachReport.creationDate);
            breachReport.humanDateUsed               = getHumanReadableDateFormat(breachReport.dateUsed);
            breachReport.roundedNetExplosiveWeightGr = convertValues(
                breachReport.netExplosiveWeight,
                Units.NetExplosiveWeight.metric,
                Units.NetExplosiveWeight.imperial,
            ).toFixed(2);
            breachReport.roundedNetExplosiveWeightOz = convertValues(
                breachReport.netExplosiveWeight,
                Units.NetExplosiveWeight.metric,
                Units.NetExplosiveWeight.alternative,
            ).toFixed(2);
            breachReport.roundedTntEquivalentInGram  = convertValues(
                breachReport.tntEquivalentInGram,
                'g',
                'lb',
            ).toFixed(3);
            breachReport.roundedMetricSafeDistance   = ExplosiveCalculator
                .roundUpToOneAfterDecimal(saveDistance)
                .toFixed(2);
            breachReport.roundedImperialSafeDistance = ExplosiveCalculator
                .roundUpToOneAfterDecimal(breachReport.safeStackingDistance)
                .toFixed(2);
            breachReport.boosters                    = getHumanReadableNameForRelations(
                breachReport.boosters,
                'boosterType',
                Boosters,
            );
            breachReport.explosives                  = getHumanReadableNameForRelations(
                breachReport.explosives,
                'explosiveType',
                Explosives,
            );
            breachReport.humanTargetOption           = getHumanReadableName(
                breachReport.targetOption,
                BreachResultTargetOptions,
            );

            removeFalseAndNullValues(breachReport);

            preparedBreachReports[breachReportIndex] = breachReport;
        })
    ;

    Object
        .keys(preparedBreachReports)
        .forEach((breachReportIndex) => {
            const breachReport       = preparedBreachReports[breachReportIndex];
            const breachReportString = JSON.stringify(breachReport).toLowerCase();

            if (breachReportString.indexOf(searchQuery.toLowerCase()) > -1) {
                matchingBreachReports[breachReportIndex] = breachReport;
            }
        })
    ;

    return matchingBreachReports;
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BreachReportsTypes.SAVE_BREACH_REPORT:
            return update(state, {
                breachReports: {
                    [action.breachReport.id]: {
                        $set: {
                            ...action.breachReport,
                        },
                    },
                },
            });

        case BreachReportsTypes.SEARCH_BREACH_REPORT:
            const searchQuery = action.searchQuery ? action.searchQuery : null;

            return update(state, {
                searchQuery:        {
                    $set: searchQuery,
                },
                foundBreachReports: {
                    $set: findBreachReportsBySearchQuery(state.breachReports, searchQuery),
                },
            });

        case BreachReportsTypes.DELETE_BREACH_REPORT_SUCCESS:
            return update(state, {
                breachReports: {
                    $unset: [
                        action.breachReportId,
                    ],
                },
            });

        case BreachReportsTypes.SET_NEXT_SHOT_NUMBER:
            return update(state, {
                nextShotNumber: {
                    $set: action.nextShotNumber,
                },
            });

        case BreachReportsTypes.SHARE_BREACH_REPORT:
            return update(state, {
                breachReportsToPrint: {
                    $set: action.breachReportIds,
                },
            });

        case BreachReportsTypes.UPLOAD_FILES_REQUEST:
            return update(state, {
                breachReports: {
                    [action.breachReport.id]: {
                        $set: {
                            ...action.breachReport,
                        },
                    },
                },
            });

        default:
            return state;
    }
};
