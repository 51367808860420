import React              from 'react';
import PropTypes          from '../../PropTypes';
import I18n               from 'i18next';
import ComponentHelper    from '../../../helper/ComponentHelper';
import FormGroup          from '../FormGroup';
import InputContainer     from '../InputContainer';
import SelectInput        from '../SelectInput';
import _                  from 'lodash';
import { PrimingOptions } from '../../../constants/Breach';
import { countries }      from 'countries-list';

const countryOptions = _.map(countries, (country) => {
    const countryName = country.name;

    return {
        label: countryName,
        value: countryName,
    };
});

class CountrySelectionInput extends React.Component {
    onValueChanged = (country) => {
        this.props.onValueChanged(country);
    };

    render () {
        return (
            <InputContainer
                label={I18n.t('country')}
            >
                <SelectInput
                    label={I18n.t('country')}
                    onChange={this.onValueChanged}
                    options={countryOptions}
                    placeholder={I18n.t('selectCountry')}
                    value={this.props.value}
                />
            </InputContainer>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CountrySelectionInput;

Component.propTypes = {
    onValueChanged: PropTypes.func,
    value:          PropTypes.string,
};

Component.defaultProps = {
    onValueChanged: _.noop,
    value:          '',
};

Component.renderAffectingProps = [
    'value',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;