import Units   from '../constants/Units';
import convert from 'convert-units';
import _       from 'lodash';
import Cast    from './Cast';

class UnitConverter {
    static convertRoomSizeFromSystemToBaseUnitSystem (currentUnitSystem, value) {
        const baseUnit                       = Units.Room[Units.Systems.metric.name];
        const currentUnit                    = Units.Room[currentUnitSystem];
        const valueWithNormalizedPunctuation = Cast.float(value);

        if (!isNaN(valueWithNormalizedPunctuation)) {
            return convert(valueWithNormalizedPunctuation).from(currentUnit).to(baseUnit);
        }

        return null;
    }

    static convertRoomValuesToCurrentUnit (currentUnit, room) {
        const unitLabel  = Units.Room[currentUnit];
        const clonedRoom = _.cloneDeep(room);

        if (clonedRoom) {
            clonedRoom.lengthInMeter = convert(clonedRoom.lengthInMeter).from('m').to(unitLabel);
            clonedRoom.widthInMeter  = convert(clonedRoom.widthInMeter).from('m').to(unitLabel);
            clonedRoom.heightInMeter = convert(clonedRoom.heightInMeter).from('m').to(unitLabel);
        }

        return clonedRoom;
    }

    static calculateRoomVolumeInCurrentUnit (room, unitSystem) {
        const roomBaseUnit       = Units.Room[Units.Systems.metric.name];
        const expectedRoomUnit   = Units.Room[unitSystem];
        const volumeInCustomUnit = convert(parseFloat(room.lengthInMeter)).from(roomBaseUnit).to(expectedRoomUnit)
            * convert(parseFloat(room.widthInMeter)).from(roomBaseUnit).to(expectedRoomUnit)
            * convert(parseFloat(room.heightInMeter)).from(roomBaseUnit).to(expectedRoomUnit);

        return volumeInCustomUnit;
    }
}

export default UnitConverter;