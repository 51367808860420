import { all }             from 'redux-saga/effects';
import { REHYDRATE }       from 'redux-persist';
import { takeLatest }      from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

// Types
import { ActiveBreachReportTypes } from '../actions/activeBreachReport';
import { BreacherInfoTypes }       from '../actions/breacherInfo';
import { BreachReportsTypes }      from '../actions/breachReports';
import { CustomCalculationTypes }  from '../actions/customCalculation';
import { LicenseTypes }            from '../actions/license';
import { LoadingTypes }            from '../actions/loading';
import { PreCalculatedTypes }      from '../actions/preCalculatedBreachpop';

// Sagas
import ActiveBreachReportSagas from './activeBreachReport';
import BreacherSagas           from './breacherInfo';
import BreachReportsSagas      from './breachReports';
import CustomCalculationSagas  from './customCalculation';
import LicenseSagas            from './license';
import LoadingSagas            from './loading';
import PreCalculatedSagas      from './preCalculatedBreachpop';

const root = function* () {
    yield all([
        // @formatter:off
        takeLatest(ActiveBreachReportTypes.APPLY_VALUES,              ActiveBreachReportSagas.applyValues),
        takeLatest(ActiveBreachReportTypes.UPDATE_ROOM_LENGTH,        ActiveBreachReportSagas.updateRoomLength),
        takeLatest(ActiveBreachReportTypes.DUPLICATE_BREACH_REPORT,   ActiveBreachReportSagas.duplicateBreachReport),
        takeLatest(ActiveBreachReportTypes.UPDATE_ROOM_WIDTH,         ActiveBreachReportSagas.updateRoomWidth),
        takeLatest(ActiveBreachReportTypes.UPDATE_ROOM_HEIGHT,        ActiveBreachReportSagas.updateRoomHeight),
        takeLatest(ActiveBreachReportTypes.SAVE_ACTIVE_BREACH_REPORT, ActiveBreachReportSagas.saveActiveBreachReport),
        takeLatest(ActiveBreachReportTypes.NEW_BREACH_REPORT,         ActiveBreachReportSagas.newBreachReport),
        takeLatest(
            [
                ActiveBreachReportTypes.RECALCULATE_VALUES,
                ActiveBreachReportTypes.SET_BOOSTERS,
                ActiveBreachReportTypes.SET_EXPLOSIVES,
                ActiveBreachReportTypes.SET_INSIDE,
                ActiveBreachReportTypes.SET_PRIMING_COUNT,
                ActiveBreachReportTypes.SET_ROOM_HEIGHT,
                ActiveBreachReportTypes.SET_ROOM_LENGTH,
                ActiveBreachReportTypes.SET_ROOM_WIDTH,
            ],
            ActiveBreachReportSagas.calculateValues,
        ),

        takeLatest(BreacherInfoTypes.SAVE,                            BreacherSagas.save),

        takeLatest(BreachReportsTypes.DELETE_BREACH_REPORT_REQUEST,   BreachReportsSagas.deleteBreachReport),
        takeLatest(BreachReportsTypes.OPEN_BREACH_REPORT,             BreachReportsSagas.openBreachReport),
        takeLatest(BreachReportsTypes.SAVE_BREACH_REPORT,             BreachReportsSagas.saveBreachReport),
        takeLatest(BreachReportsTypes.UPLOAD_FILES_REQUEST,             BreachReportsSagas.uploadFiles),
        takeLatest(BreachReportsTypes.SHARE_BREACH_REPORT,            BreachReportsSagas.shareBreachReport),

        takeLatest(CustomCalculationTypes.UPDATE_ROOM_LENGTH,         CustomCalculationSagas.updateRoomLength),
        takeLatest(CustomCalculationTypes.UPDATE_ROOM_WIDTH,          CustomCalculationSagas.updateRoomWidth),
        takeLatest(CustomCalculationTypes.UPDATE_ROOM_HEIGHT,         CustomCalculationSagas.updateRoomHeight),
        takeLatest(CustomCalculationTypes.SAVE_AS_BREACH_REPORT,      CustomCalculationSagas.saveAsBreachReport),
        takeLatest(
            [
                CustomCalculationTypes.SET_BOOSTERS,
                CustomCalculationTypes.RECALCULATE_VALUES,
                CustomCalculationTypes.SET_EXPLOSIVES,
                CustomCalculationTypes.SET_INSIDE,
                CustomCalculationTypes.SET_PRIMING_COUNT,
                CustomCalculationTypes.SET_ROOM_HEIGHT,
                CustomCalculationTypes.SET_ROOM_LENGTH,
                CustomCalculationTypes.SET_ROOM_WIDTH,
            ],
            CustomCalculationSagas.calculateValues,
        ),

        takeLatest(LicenseTypes.CHECK_LICENSE_KEY,                    LicenseSagas.checkLicenseKey),
        takeLatest(LicenseTypes.CHECK_LICENSE_KEY_FAILED,             LicenseSagas.checkLicenseKeyFailed),
        takeLatest(LicenseTypes.CHECK_LICENSE_KEY_SUCCEEDED,          LicenseSagas.checkLicenseKeySucceeded),
        takeLatest(LicenseTypes.CLAIM_LICENSE_KEY,                    LicenseSagas.claimLicenseKey),
        takeLatest(LicenseTypes.CLAIM_LICENSE_KEY_FAILED,             LicenseSagas.claimLicenseKeyFailed),
        takeLatest(LicenseTypes.CLAIM_LICENSE_KEY_SUCCEEDED,          LicenseSagas.claimLicenseKeySucceeded),
        takeLatest(LicenseTypes.RELEASE_LICENSE_KEY,                  LicenseSagas.releaseLicenseKey),
        takeLatest(LicenseTypes.RELEASE_LICENSE_KEY_FAILED,           LicenseSagas.releaseLicenseKeyFailed),
        takeLatest(LicenseTypes.RELEASE_LICENSE_KEY_SUCCEEDED,        LicenseSagas.releaseLicenseKeySucceeded),
        takeLatest(LicenseTypes.BUY_LICENSE_KEY,                      LicenseSagas.buyLicenseKey),

        takeLatest(PreCalculatedTypes.SAVE_AS_BREACH_REPORT,          PreCalculatedSagas.saveAsBreachReport),

        takeLatest(LoadingTypes.OVERLAY_CLICKED,                      LoadingSagas.overlayClicked),

        takeLatest(REHYDRATE,                  rehydrationDone),
        takeLatest(LOCATION_CHANGE,            scrollToTop),
        // @formatter:on
    ])
    ;
};

const rehydrationDone = function* () {
    yield preLoading();
};

const scrollToTop = function* () {
    window.scrollTo(0, 0);
};

const preLoading = function* () {
};

export default {
    root,
};
