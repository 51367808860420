import _         from 'lodash';
import classNames         from 'classnames';
import ComponentHelper    from '../../../helper/ComponentHelper';
import PropTypes          from '../../PropTypes';
import React              from 'react';
import styles             from './styles.module.scss';

class LoadingOverlay extends React.Component {
    render () {
        return (
            <div
                className={classNames(
                    styles.loadingOverlay,
                    {
                        [styles.loadingOverlayVisible]: this.props.isLoading,
                    },
                )}
            >
                <div
                    className={styles.loadingOverlayPulse}
                >
                    <span onClick={this.props.loadingOverlayClicked}></span>
                    <span onClick={this.props.loadingOverlayClicked}></span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    isLoading:             PropTypes.bool,
    loadingOverlayClicked: PropTypes.func,
    text:                  PropTypes.string,
};

Component.defaultProps = {
    isLoading:             false,
    loadingOverlayClicked: _.noop(),
    text:                  '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;