import _                          from 'lodash';
import React                      from 'react';
import { Component }              from 'react';
import ImageBackground            from '../components/stateless/ImageBackground';
import Header                     from '../components/stateless/Header';
import styles                     from '../styles.module.scss';
import Footer                     from '../components/stateless/Footer';
import I18n                       from 'i18next';
import FormGroup                  from '../components/stateless/FormGroup';
import ColorButton                from '../components/stateless/ColorButton';
import SelectInput                from '../components/stateless/SelectInput';
import Colors                     from '../styles/colors.scss';
import ColorButtonTheme           from '../components/stateless/ColorButton/ColorButtonTheme';
import InputContainer             from '../components/stateless/InputContainer';
import { Helmet }                 from 'react-helmet';
import PageTitleHelper            from '../helper/PageTitle';
import ActiveUnitFormGroup        from '../components/connected/ActiveUnitFormGroup';
import PreCalculatedBreachpops    from '../constants/PreCalculatedBreachpops';
import ExplosiveCalculator        from '../helper/ExplosiveCalculator';
import MinimumSafeToStackDistance from '../components/stateless/MinimumSafeToStackDistance';
import { bindActionCreators }     from 'redux';
import { PreCalculatedActions }   from '../store/actions/preCalculatedBreachpop';
import { connect }                from 'react-redux';
import TotalNetExplosiveWeight    from '../components/stateless/TotalNetExplosiveWeight';
import FormGroupTheme             from '../components/stateless/FormGroup/FormGroupTheme';
import PurchaseLicenseHint        from '../components/connected/PurchaseLicenseHint';
import AlertBoxManager            from '../components/connected/AlertBoxManager';
import { AlertBoxActions }        from '../store/actions/alertBox';
import Routes                     from '../constants/Routes';

const preCalculatedBreachPopOptions = _.map(
    PreCalculatedBreachpops, (preCalculatedBreachpop, key) => {
        return {
            label: preCalculatedBreachpop.name,
            value: key,
        };
    },
);

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            breachReport:           null,
            preCalculatedBreachPop: null,
        };
    }

    renderUnitGroup = () => {
        return (
            <ActiveUnitFormGroup />
        );
    };

    onPreCalculatedBreachpopChanged = (option) => {
        this.setState({
            preCalculatedBreachPop: option.value,
            breachReport:           _.get(PreCalculatedBreachpops, [option.value, 'breachReport'], {}),
        });
    };

    renderTypeOfBreachpopGroup = () => {
        return (
            <div>
                <FormGroup
                    label={I18n.t('typeOfBreachpop')}
                >
                    <InputContainer
                        label={I18n.t('type')}
                    >
                        <SelectInput
                            label={I18n.t('typeOfBreachpop')}
                            onChange={this.onPreCalculatedBreachpopChanged}
                            options={preCalculatedBreachPopOptions}
                            placeholder={I18n.t('typeOfBreachpop')}
                            value={this.state.preCalculatedBreachPop}
                        />
                    </InputContainer>
                </FormGroup>
            </div>
        );
    };

    renderBreachReportValuesGroup = () => {
        let breachValues        = {};
        let tntEquivalentInGram = 0;

        if (this.state.breachReport) {
            const explosives   = this.state.breachReport.explosives;
            const primingCount = this.state.breachReport.primingCount;
            const boosters     = this.state.breachReport.booster;

            breachValues = ExplosiveCalculator.calculateBreachValues({
                explosives,
                primingCount,
                boosters,
            });

            tntEquivalentInGram = ExplosiveCalculator.calculateTotalTNTEquivalentInGram(
                explosives,
                primingCount,
                boosters,
            );
        }

        return (
            <FormGroup>
                <TotalNetExplosiveWeight
                    unitSystem={this.props.unit}
                    netExplosiveWeight={breachValues.netExplosiveWeight}
                    tntEquivalentInGram={tntEquivalentInGram}
                />
                <MinimumSafeToStackDistance
                    unitSystem={this.props.unit}
                    safeStackingDistanceInMeter={breachValues.safeStackingDistance}
                />
            </FormGroup>
        );
    };

    saveButtonPressed = () => {
        const breachReports = this.props.breachReports;

        if (this.props.isUnlocked) {
            this.props.clearAlerts();

            if (this.state.breachReport) {
                this.props.saveAsBreachReport({
                    preCalculatedBreachpop: this.state.breachReport,
                });
            }
        } else {
            this.props.clearAlerts();
            this.props.showErrorAlert({
                text: I18n.t('licenseRequiredError1'),
            });
            this.setState({
                showPurchaseLicenseHint: true,
            });
            window.scrollTo(0, 0);
        }
    };

    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('preCalculatedBreachpops'))}
                    </title>
                </Helmet>
                <Header
                    title={I18n.t('preCalculatedBreachpops')}
                />
                <ImageBackground
                    image={require('../assets/images/breacherInfoBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'60%'}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <AlertBoxManager />
                            {this.renderPurchaseLicenseHint()}
                            {this.renderUnitGroup()}
                            {this.renderTypeOfBreachpopGroup()}
                            {this.renderBreachReportValuesGroup()}
                            <FormGroup
                                theme={FormGroupTheme.submit}
                            >
                                <ColorButton
                                    onClick={this.saveButtonPressed}
                                    text={I18n.t('saveAsBreachReport')}
                                    theme={ColorButtonTheme.yellow}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }

    renderPurchaseLicenseHint = () => {
        if (this.state.showPurchaseLicenseHint) {
            return (
                <PurchaseLicenseHint />
            );
        }

        return null;
    };
}

Screen.renderAffectingStates = [
    'type',
];

const mapStateToProps = state => (
    {
        breachReports: state.breachReports.breachReports,
        isUnlocked:    state.license.isUnlocked,
        unit:          state.settings.unit,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    PreCalculatedActions,
    AlertBoxActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
