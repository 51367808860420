import english              from './english';
import Environment          from '../helper/Environment';
import I18n                 from 'i18next';
import LanguageHelper       from '../helper/Language';
import Languages            from '../constants/Languages';
import { initReactI18next } from 'react-i18next';

const resources = {
    [Languages.english]: {
        translation: english,
    },
};

I18n.use(initReactI18next)
    .init({
        resources,
        lng:           LanguageHelper.getDefaultLanguage(),
        fallbackLng:   Languages.english,
        debug:         Environment.isDevelopment(),
        interpolation: {
            escapeValue: false,
        },
    })
;

export default I18n;