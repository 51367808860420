import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import StartMenuItem   from '../StartMenuItem';
import Routes          from '../../../constants/Routes';
import I18n            from 'i18next';
import PropTypes       from '../../PropTypes';

class StartMenu extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.container}>
                {this.renderBreacherInfo()}
                {this.renderSettings()}
                {this.renderCustomCalculation()}
                {this.renderBreachReports()}
                {this.renderPreCalculatedBreachPops()}
                {this.renderNewToMSDDiagram()}
            </div>
        );
    }

    renderBreacherInfo = () => {
        return (
            <StartMenuItem
                to={Routes.breacherInfo}
                title={I18n.t('breacher')}
                subTitle={I18n.t('info')}
                backgroundImage={require('../../../assets/images/startMenu/breacher-info.jpg')}
            />
        );
    };

    renderSettings = () => {
        return (
            <StartMenuItem
                to={Routes.settings}
                title={I18n.t('settings')}
                backgroundImage={require('../../../assets/images/startMenu/settings.jpg')}
            />
        );
    };

    renderBreachReports = () => {
        return (
            <StartMenuItem
                to={Routes.breachReports}
                title={I18n.t('breach')}
                subTitle={I18n.t('reports')}
                backgroundImage={require('../../../assets/images/startMenu/breach-reports.jpg')}
                badgeCount={this.props.breachReportCount}
            />
        );
    };

    renderCustomCalculation = () => {
        return (
            <StartMenuItem
                to={Routes.customCalculation}
                title={I18n.t('custom')}
                subTitle={I18n.t('calculation')}
                backgroundImage={require('../../../assets/images/startMenu/custum-calculation.jpg')}
            />
        );
    };

    renderNewToMSDDiagram = () => {
        return (
            <StartMenuItem
                to={Routes.newToMSDDiagram}
                title={I18n.t('netExplosiveWeightToMinimumSafeStackingDistanceShort')}
                highlightedSubTitle={I18n.t('diagram')}
                backgroundImage={require('../../../assets/images/startMenu/new-to-msd.jpg')}
            />
        );
    };

    renderPreCalculatedBreachPops = () => {
        return (
            <StartMenuItem
                to={Routes.preCalculatedBreachPops}
                title={I18n.t('preCalculated')}
                highlightedSubTitle={I18n.t('breachpops')}
                backgroundImage={require('../../../assets/images/startMenu/pre-calculated.jpg')}
            />
        );
    };
}

const Component = StartMenu;

Component.propTypes = {
    breachReportCount: PropTypes.number,
};

Component.defaultProps = {
    breachReportCount: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;