import update           from 'immutability-helper';
import { LicenseTypes } from '../actions/license';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    clientKey:    uuidv4(),
    isUnlocked:   false,
    licenseKey:   null,
    licenseKeyId: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LicenseTypes.CLAIM_LICENSE_KEY_SUCCEEDED:
            return update(state, {
                isUnlocked:   {
                    $set: true,
                },
                licenseKeyId: {
                    $set: action.licenseKeyId,
                },
            });

        case LicenseTypes.RELEASE_LICENSE_KEY_SUCCEEDED:
            return update(state, {
                isUnlocked:   {
                    $set: false,
                },
                licenseKeyId: {
                    $set: null,
                },
            });

        case LicenseTypes.SET_LICENSE_KEY:
            return update(state, {
                licenseKey: {
                    $set: action.licenseKey,
                },
            });

        default:
            return state;
    }
}