import _ from 'lodash';
import Routes from '../../constants/Routes';
import {push} from 'connected-react-router';
import {put, select} from 'redux-saga/effects';
import ExplosiveCalculator from '../../helper/ExplosiveCalculator';
import UnitConverter from '../../helper/UnitConverter';
import {ActiveBreachReportActions} from '../actions/activeBreachReport';
import {BreachReportsActions} from '../actions/breachReports';
import ShotNumberGenerator from '../../helper/ShotNumberGenerator';

const calculateValues = function* () {
    const breachReportStore     = yield select(state => state.activeBreachReport);
    const calculateBreachValues = ExplosiveCalculator.calculateBreachValues(breachReportStore);

    yield put(
        ActiveBreachReportActions.setInternalPSI({
            internalPSI: calculateBreachValues.internalPSI,
        }),
    );

    yield put(
        ActiveBreachReportActions.setNetExplosiveWeight({
            netExplosiveWeight:  calculateBreachValues.netExplosiveWeight,
            tntEquivalentInGram: calculateBreachValues.tntEquivalentInGram,
        }),
    );

    yield put(
        ActiveBreachReportActions.setSafeStackingDistance({
            safeStackingDistance: calculateBreachValues.safeStackingDistance,
        }),
    );

    return null;
};

const duplicateBreachReport = function* () {
    yield put(push(Routes.newBreachReport));
};

const convertRoomSizeToBaseUnit = function* ({ value }) {
    const currentUnitSystem = yield select(state => state.settings.unit);

    return UnitConverter.convertRoomSizeFromSystemToBaseUnitSystem(currentUnitSystem, value);
};

const newBreachReport = function* () {
    const state          = yield select(state => state);
    const nextShotNumber = ShotNumberGenerator.getNextShotNumberByStats(state);

    yield put(BreachReportsActions.setNextShotNumber({
            nextShotNumber,
        }),
    );
    yield put(
        ActiveBreachReportActions.applyValues({
            values: {},
        }),
    );
    yield put(push(Routes.newBreachReport));
};

const roomChanged = function* () {
    yield put(push(Routes.home));
};

const saveActiveBreachReport = function* () {
    const activeBreachReport = yield select(state => state.activeBreachReport);

    if (activeBreachReport) {
        yield put(BreachReportsActions.saveBreachReport({ breachReport: activeBreachReport }));
    }

    return null;
};

const updateRoomHeight = function* ({ roomHeight }) {
    const convertedRoomHeight = yield convertRoomSizeToBaseUnit({
        value: roomHeight,
    });

    if (!isNaN(convertedRoomHeight)) {
        yield put(ActiveBreachReportActions.setRoomHeight({
            roomHeight: convertedRoomHeight,
        }));
    }
};

const updateRoomLength = function* ({ roomLength }) {
    const convertedRoomLength = yield convertRoomSizeToBaseUnit({
        value: roomLength,
    });

    if (!isNaN(convertedRoomLength)) {
        yield put(ActiveBreachReportActions.setRoomLength({
            roomLength: convertedRoomLength,
        }));
    }
};

const updateRoomWidth = function* ({ roomWidth }) {
    const convertedRoomWidth = yield convertRoomSizeToBaseUnit({
        value: roomWidth,
    });

    if (!isNaN(convertedRoomWidth)) {
        yield put(ActiveBreachReportActions.setRoomWidth({
            roomWidth: convertedRoomWidth,
        }));
    }
};

const applyValues = function* ({ values }) {
    const breacherInfo       = yield select(state => state.breacherInfo);
    const breachReportsStore = yield select(state => state.breachReports);
    const nextShotNumber     = _.get(breachReportsStore, 'nextShotNumber', 1);
    const operator           = breacherInfo.breacherType;
    const country            = breacherInfo.country;
    const name               = breacherInfo.name;
    const team               = breacherInfo.squadron;
    const breacherValues     = {
        operator,
        country,
        name,
        team,
    };

    yield put(
        ActiveBreachReportActions.setValues({
            values: {
                ...values,
                ...breacherValues,
                shotNumber: nextShotNumber,
            },
        }),
    );
};

export default {
    applyValues,
    calculateValues,
    duplicateBreachReport,
    newBreachReport,
    roomChanged,
    saveActiveBreachReport,
    updateRoomHeight,
    updateRoomLength,
    updateRoomWidth,
};
