//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LoadingActions } from '../actions/loading';
import { put }            from 'redux-saga/effects';
import { select }         from 'redux-saga/effects';

const overlayClicked = function* () {
    const clickCount = yield select(state => state.loading.clickCount);

    if (clickCount > 10) {
        yield put(LoadingActions.resetOverlay());
    }
};

export default {
    overlayClicked,
};
