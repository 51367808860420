import ComponentHelper    from '../../../helper/ComponentHelper';
import React              from 'react';
import HeadlineWithButton from '../HeadlineWithButton';
import I18n               from 'i18next';
import Spacer             from '../Spacer';
import FormGroup          from '../FormGroup';
import _                  from 'lodash';
import PropTypes          from '../../PropTypes';

class PurchaseLicenseHint extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <FormGroup>
                <HeadlineWithButton
                    buttonPressed={this.props.buyLicenseButtonPressed}
                    buttonText={I18n.t('buyOrEnterLicenseButtonText')}
                    hasMarginBottom={false}
                    headlineText={I18n.t('buyLicenseText')}
                />
                <Spacer height={50} />
            </FormGroup>
        );
    }
}

const Component = PurchaseLicenseHint;

Component.propTypes = {
    buyLicenseButtonPressed: PropTypes.func,
};

Component.defaultProps = {
    buyLicenseButtonPressed: _.noop,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;