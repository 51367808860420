import { put }             from 'redux-saga/effects';
import { select }          from 'redux-saga/effects';
import { AlertBoxActions } from '../actions/alertBox';
import { LicenseActions }  from '../actions/license';
import * as Api            from '../../api';
import HydraHelper         from '../../helper/Hydra';
import { call }            from 'redux-saga/effects';
import I18n                from 'i18next';
import Routes              from '../../constants/Routes';
import { push }            from 'connected-react-router';

const checkLicenseKey = function* (action) {
    yield put(AlertBoxActions.clearAlerts());

    const licenseStore = yield select(state => state.license);
    const response     = yield call(
        Api.checkForValidLicense,
        licenseStore.licenseKey,
    );

    if (response.ok) {
        const cleanHydraResponse = HydraHelper.cleanupObject(response.data);
        const licenses           = cleanHydraResponse.member;

        if (licenses.length > 0) {
            yield put(LicenseActions.checkLicenseKeySucceeded({
                licenseKeyId: licenses[0].iri,
            }));
        } else {
            yield put(LicenseActions.checkLicenseKeyFailed());
        }
    } else {
        yield put(LicenseActions.checkLicenseKeyFailed());
    }
};

const checkLicenseKeyFailed = function* () {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('checkLicenseKeyError1'),
    }));
};

const checkLicenseKeySucceeded = function* (action) {
    yield put(LicenseActions.claimLicenseKey({
        licenseKeyId: action.licenseKeyId,
    }));
};

const claimLicenseKey = function* (action) {
    yield put(AlertBoxActions.clearAlerts());

    const licenseStore = yield select(state => state.license);
    const response     = yield call(
        Api.claimLicense,
        licenseStore.licenseKey,
        action.licenseKeyId,
        licenseStore.clientKey,
    );

    if (response.ok) {
        const data = response.data;

        yield put(LicenseActions.claimLicenseKeySucceeded({
            licenseKeyId: data.iri,
        }));
    } else {
        yield put(LicenseActions.checkLicenseKeyFailed());
    }
};

const claimLicenseKeyFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('clamLicenseKeyError1'),
    }));
};

const claimLicenseKeySucceeded = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('clamLicenseKeySuccess1'),
    }));
};

const releaseLicenseKey = function* (action) {
    yield put(AlertBoxActions.clearAlerts());

    const licenseStore = yield select(state => state.license);
    const response     = yield call(
        Api.releaseLicense,
        licenseStore.licenseKey,
        licenseStore.licenseKeyId,
        licenseStore.clientKey,
    );

    if (response.ok) {
        yield put(LicenseActions.releaseLicenseKeySucceeded());
    } else {
        yield put(LicenseActions.releaseLicenseKeyFailed());
    }
};

const releaseLicenseKeyFailed = function* (action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('releaseLicenseKeyError1'),
    }));
};

const releaseLicenseKeySucceeded = function* (action) {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('releaseLicenseKeySuccess1'),
    }));
};

const buyLicenseKey = function* (action) {
    yield put(AlertBoxActions.clearAlerts());
    yield put(push(Routes.settings));
};

export default {
    buyLicenseKey,
    checkLicenseKey,
    checkLicenseKeyFailed,
    checkLicenseKeySucceeded,
    claimLicenseKey,
    claimLicenseKeyFailed,
    claimLicenseKeySucceeded,
    releaseLicenseKey,
    releaseLicenseKeyFailed,
    releaseLicenseKeySucceeded,
};