import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import ImageBackground from '../components/stateless/ImageBackground';
import styles          from '../styles.module.scss';
import CenterContainer from '../components/stateless/CenterContainer';
import Headline        from '../components/stateless/Headline';
import HeadlineTheme   from '../components/stateless/Headline/HeadlineTheme';
import LongText        from '../components/stateless/LongText';
import Footer          from '../components/stateless/Footer';
import Header          from '../components/stateless/Header';
import Spacer          from '../components/stateless/Spacer';

class Screen extends Component {
    render () {
        return (
            <div>
                <Header
                    title={I18n.t('imprint')}
                />
                <ImageBackground
                    image={require('../assets/images/landingPageBackground.jpg')}
                >
                    <div
                        className={styles.contentViewWithPadding}
                    >
                        <Helmet>
                            <title>
                                {PageTitleHelper.getPageTitle(I18n.t('imprint'))}
                            </title>
                        </Helmet>
                        <CenterContainer
                            centerContent={true}
                        >
                            <Headline
                                theme={HeadlineTheme.whiteSmall}
                                title={I18n.t('termsConditionsAndDisclaimer')}
                            />
                            <LongText>
                                <p>
                                    This app has been developed to enhance the operational capabilities of the breacher
                                    by providing a quick way to determine NEW, MSD and internal PSI for a given charge,
                                    while retaining an electronic breach report for future reference. The use of this
                                    app for activities other than that is expressly forbidden. Breaching is a
                                    fundamentally dangerous activity. P3D Solutions, Inc and BreachPop, LLC assume no
                                    liability, expressed or implied, from the use or misuse of the calculations and data
                                    provided in this app.
                                </p>
                                <br />
                                <p>
                                    Further, this app is no substitute for a lack of training. Your ability to enter
                                    numbers into a computer program does not equate to you being capable of safely, or
                                    effectively, handling explosives. If you do not have the proper training, please
                                    contact us so we can recommend schools to attend that will ensure your safety and
                                    effectiveness operationally.
                                </p>
                                <br />
                                Please have a look at our
                                <a
                                    href={'https://www.p3dsolutions.com/'}
                                    target={'_blank'}
                                >
                                    imprint
                                </a>
                                .
                            </LongText>
                            {this.renderLulububu()}
                        </CenterContainer>
                        <Footer />
                    </div>
                </ImageBackground>
            </div>
        );
    }

    renderLulububu = () => {
        return (
            <>
                <Spacer height={50} />
                <Headline
                    theme={HeadlineTheme.whiteSmall}
                    title={I18n.t('developmentAndDesign')}
                />
                <LongText>
                    Concept, design and development of this app was made by
                    <br />
                    <a
                        href={'https://lulububu.de/'}
                        target={'_blank'}
                    >
                        Lulububu Software GmbH
                    </a>
                    .
                </LongText>
            </>
        );
    };
}

export default Screen;