//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';

class Spacer extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.height) {
            style.height = this.props.height;
        }

        if (this.props.width) {
            style.width = this.props.width;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <span
                className={styles.spacer}
                style={style}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Spacer;

Component.propTypes = {
    height: PropTypes.number,
    width:  PropTypes.number,
};

Component.defaultProps = {
    height: 0,
    width:  0,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;
