import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class CheckboxInput extends React.Component {
    render () {
        return (
            <label
                className={classNames(
                    styles.checkboxContainer,
                    {
                        [styles.hidden]: this.props.hidden,
                    },
                )}
            >
                <input
                    type={'checkbox'}
                    onChange={this.props.onChange}
                />
                <span className={styles.checkbox} />
            </label>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CheckboxInput;

Component.propTypes = {
    hidden:   PropTypes.bool,
    onChange: PropTypes.func,
};

Component.defaultProps = {
    hidden:   false,
    onChange: _.noop,
};

Component.renderAffectingProps = [
    'hidden',
];

Component.renderAffectingStates = [];

export default CheckboxInput;