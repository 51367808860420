import update                     from 'immutability-helper';
import { CustomCalculationTypes } from '../actions/customCalculation';
import _                          from 'lodash';
import { BoosterOptions }         from '../../constants/Breach';

const initialState = {
    explosives:           [],
    inside:               false,
    internalPSI:          0,
    netExplosiveWeight:   0,
    tntEquivalentInGram:  0,
    primingCount:         1,
    room:                 {
        heightInMeter: null,
        lengthInMeter: null,
        widthInMeter:  null,
    },
    safeStackingDistance: 0,
    boosters:             [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case CustomCalculationTypes.SET_ROOM_LENGTH:
            return update(state, {
                room: {
                    lengthInMeter: {
                        $set: action.roomLength,
                    },
                },
            });

        case CustomCalculationTypes.SET_ROOM_WIDTH:
            return update(state, {
                room: {
                    widthInMeter: {
                        $set: action.roomWidth,
                    },
                },
            });

        case CustomCalculationTypes.SET_ROOM_HEIGHT:
            return update(state, {
                room: {
                    heightInMeter: {
                        $set: action.roomHeight,
                    },
                },
            });

        case CustomCalculationTypes.SET_EXPLOSIVES:
            return update(state, {
                explosives: {
                    $set: action.explosives,
                },
            });

        case CustomCalculationTypes.SET_NET_EXPLOSIVE_WEIGHT:
            return update(state, {
                netExplosiveWeight:  {
                    $set: action.netExplosiveWeight,
                },
                tntEquivalentInGram: {
                    $set: action.tntEquivalentInGram,
                },
            });

        case CustomCalculationTypes.SET_INTERNAL_PSI:
            return update(state, {
                internalPSI: {
                    $set: action.internalPSI,
                },
            });

        case CustomCalculationTypes.SET_PRIMING_COUNT:
            return update(state, {
                primingCount: {
                    $set: action.primingCount,
                },
            });

        case CustomCalculationTypes.SET_SAFE_STACKING_DISTANCE:
            return update(state, {
                safeStackingDistance: {
                    $set: action.safeStackingDistance,
                },
            });

        case CustomCalculationTypes.SET_INSIDE:
            return update(state, {
                inside: {
                    $set: action.inside,
                },
            });

        case CustomCalculationTypes.SET_BOOSTERS:
            return update(state, {
                boosters: {
                    $set: action.boosters,
                },
            });

        default:
            return state;
    }
};