import React                     from 'react';
import PropTypes                 from '../../PropTypes';
import I18n                      from 'i18next';
import ComponentHelper           from '../../../helper/ComponentHelper';
import FormGroup                 from '../FormGroup';
import InputContainer            from '../InputContainer';
import InputContainerType        from '../InputContainer/InputContainerType';
import Value                     from '../Value';
import NotSafeToStackInRoomAlert from '../NotSafeToStackInRoomAlert';
import Units                     from '../../../constants/Units';
import convert                   from 'convert-units';

class InternalPressureVolumeResult extends React.Component {
    render () {
        const unitSystem      = this.props.unitSystem;
        const internalPSI     = this.props.internalPSI;
        const userUnit        = Units.InternalPressure[unitSystem];
        const valueInUserUnit = convert(internalPSI).from(Units.InternalPressureBase).to(userUnit);
        const value           = valueInUserUnit ? valueInUserUnit.toFixed(2) : '-';

        return (
            <div
                key={'internalPSIResultGroup'}
            >
                <FormGroup>
                    <InputContainer
                        theme={InputContainerType.bigWhiteLabel}
                        label={I18n.t('internalPressure')}
                    >
                        <Value
                            value={`${value}`}
                            unit={userUnit}
                        />
                    </InputContainer>
                </FormGroup>
                <NotSafeToStackInRoomAlert
                    internalPressureInPSI={internalPSI}
                />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = InternalPressureVolumeResult;

Component.propTypes = {
    unitSystem:  PropTypes.string,
    internalPSI: PropTypes.number,
};

Component.defaultProps = {
    unitSystem:  Units.Systems.metric.name,
    internalPSI: null,
};

Component.renderAffectingProps = [
    'internalPSI',
    'unitSystem',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;