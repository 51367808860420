import alertBoxReducer           from './alertBox';
import breacherInfoReducer       from './breacherInfo';
import customCalculationReducer  from './customCalculation';
import licenseReducer            from './license';
import loadingReducer            from './loading';
import breachReportsReducer      from './breachReports';
import activeBreachReportReducer from './activeBreachReport';
import settingsReducer           from './settings';
import { combineReducers }       from 'redux';
import { connectRouter }         from 'connected-react-router';

export default (history) => combineReducers({
    alertBox:           alertBoxReducer,
    breacherInfo:       breacherInfoReducer,
    customCalculation:  customCalculationReducer,
    license:            licenseReducer,
    loading:            loadingReducer,
    breachReports:      breachReportsReducer,
    activeBreachReport: activeBreachReportReducer,
    settings:           settingsReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router:             connectRouter(history),
})