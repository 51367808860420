import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class Value extends React.Component {
    render () {
        return (
            <div
                className={styles.container}
            >
                <span>
                    {this.props.value} {this.props.unit}
                </span>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Value;

Component.propTypes = {
    value: PropTypes.string,
    unit:  PropTypes.string,
};

Component.defaultProps = {
    value: '',
    unit:  '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;