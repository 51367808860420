import { makeActionCreator } from '../../helper/Store';

export const BreachReportsTypes = {
    DELETE_BREACH_REPORT_REQUEST: 'BreachReports/DELETE_BREACH_REPORT_REQUEST',
    DELETE_BREACH_REPORT_SUCCESS: 'BreachReports/DELETE_BREACH_REPORT_SUCCESS',
    INCREASE_SHOT_NUMBER:         'BreachReports/INCREASE_SHOT_NUMBER',
    OPEN_BREACH_REPORT:           'BreachReports/OPEN_BREACH_REPORT',
    SAVE_BREACH_REPORT:           'BreachReports/SAVE_BREACH_REPORT',
    SAVE_BREACH_REPORT_SUCCESS:   'BreachReports/SAVE_BREACH_REPORT_SUCCESS',
    SEARCH_BREACH_REPORT:         'BreachReports/SEARCH_BREACH_REPORT',
    SET_NEXT_SHOT_NUMBER:         'BreachReports/SET_NEXT_SHOT_NUMBER',
    SHARE_BREACH_REPORT:          'BreachReports/SHARE_BREACH_REPORT',
    UPLOAD_FILES_REQUEST:         'BreachReports/UPLOAD_FILES_REQUEST',
    UPLOAD_FILES_SUCCESS:         'BreachReports/UPLOAD_FILES_SUCCESS',
};

const deleteBreachReportRequest = makeActionCreator(
    BreachReportsTypes.DELETE_BREACH_REPORT_REQUEST,
    {
        breachReportId: null,
    },
);

const deleteBreachReportSuccess = makeActionCreator(
    BreachReportsTypes.DELETE_BREACH_REPORT_SUCCESS,
    {
        breachReportId: null,
    },
);

const openBreachReport = makeActionCreator(
    BreachReportsTypes.OPEN_BREACH_REPORT,
    {
        breachReportId: null,
    },
);

const saveBreachReport = makeActionCreator(
    BreachReportsTypes.SAVE_BREACH_REPORT,
    {
        breachReport: {},
    },
);

const saveBreachReportSuccess = makeActionCreator(
    BreachReportsTypes.SAVE_BREACH_REPORT_SUCCESS,
);

const searchBreachReport = makeActionCreator(
    BreachReportsTypes.SEARCH_BREACH_REPORT,
    {
        searchQuery: null,
    },
);

const setNextShotNumber = makeActionCreator(
    BreachReportsTypes.SET_NEXT_SHOT_NUMBER,
    {
        nextShotNumber: null,
    },
);

const shareReport = makeActionCreator(
    BreachReportsTypes.SHARE_BREACH_REPORT,
    {
        breachReportIds: null,
    },
);

const uploadFilesRequest = makeActionCreator(
    BreachReportsTypes.UPLOAD_FILES_REQUEST,
    {
        breachReport: {},
    },
);

const uploadFilesSuccess = makeActionCreator(
    BreachReportsTypes.UPLOAD_FILES_SUCCESS,
);


export const BreachReportsActions = {
    deleteBreachReportRequest,
    deleteBreachReportSuccess,
    openBreachReport,
    saveBreachReport,
    saveBreachReportSuccess,
    searchBreachReport,
    setNextShotNumber,
    shareReport,
    uploadFilesRequest,
    uploadFilesSuccess
};