import _                           from 'lodash';
import BreachReportFactory         from '../factories/breachReport';
import update                      from 'immutability-helper';
import { ActiveBreachReportTypes } from '../actions/activeBreachReport';
import { BreachResultOptions }     from '../../constants/Breach';

const initialState = BreachReportFactory.getInitialState();

const duplicateBreachReport = (breachReport, nextShotNumber, timestamp) => {
    const clonedBreachReport = _.cloneDeep(breachReport);

    clonedBreachReport.id           = timestamp;
    clonedBreachReport.creationDate = timestamp;
    clonedBreachReport.shotNumber   = nextShotNumber;
    clonedBreachReport.images       = [];
    clonedBreachReport.comments     = null;
    clonedBreachReport.dateUsed     = null;
    clonedBreachReport.breachResult = _.head(BreachResultOptions).value;

    return clonedBreachReport;
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActiveBreachReportTypes.DUPLICATE_BREACH_REPORT:
            const timestamp    = new Date().getTime();
            const breachReport = duplicateBreachReport(action.breachReport, action.nextShotNumber, timestamp);

            return update(state, {
                $set: breachReport,
            });

        case ActiveBreachReportTypes.SET_VALUES:
            return update(BreachReportFactory.getInitialState(), {
                $merge: action.values,
            });

        case ActiveBreachReportTypes.NEW_BREACH_REPORT:
            return BreachReportFactory.getInitialState();

        case ActiveBreachReportTypes.SET_BOOSTERS:
            return update(state, {
                boosters: {
                    $set: action.boosters,
                },
            });

        case ActiveBreachReportTypes.SET_BREACH_MODE:
            return update(state, {
                breachMode: {
                    $set: action.breachMode,
                },
            });

        case ActiveBreachReportTypes.SET_BREACH_RESULT:
            return update(state, {
                breachResult: {
                    $set: action.breachResult,
                },
            });

        case ActiveBreachReportTypes.SET_COMMENTS:
            return update(state, {
                comments: {
                    $set: action.comments,
                },
            });

        case ActiveBreachReportTypes.SET_COUNTRY:
            return update(state, {
                country: {
                    $set: action.country,
                },
            });

        case ActiveBreachReportTypes.SET_CREATION_DATE:
            return update(state, {
                creationDate: {
                    $set: action.creationDate,
                },
            });

        case ActiveBreachReportTypes.SET_DATE_USED:
            return update(state, {
                dateUsed: {
                    $set: action.dateUsed,
                },
            });

        case ActiveBreachReportTypes.SET_EXPLOSIVES:
            return update(state, {
                explosives: {
                    $set: action.explosives,
                },
            });

        case ActiveBreachReportTypes.SET_IMAGES:
            return update(state, {
                images: {
                    $set: action.images,
                },
            });

        case ActiveBreachReportTypes.SET_INSIDE:
            return update(state, {
                inside: {
                    $set: action.inside,
                },
            });

        case ActiveBreachReportTypes.SET_INTERNAL_PSI:
            return update(state, {
                internalPSI: {
                    $set: action.internalPSI,
                },
            });

        case ActiveBreachReportTypes.SET_LIGHT:
            return update(state, {
                light: {
                    $set: action.light,
                },
            });

        case ActiveBreachReportTypes.SET_LOCATION:
            return update(state, {
                location: {
                    $set: action.location,
                },
            });

        case ActiveBreachReportTypes.SET_METHOD_OF_ATTACHMENT_DESCRIPTION:
            return update(state, {
                methodOfAttachment: {
                    $set: action.methodOfAttachmentDescription,
                },
            });

        case ActiveBreachReportTypes.SET_METHOD_OF_ATTACHMENT_OPTION:
            return update(state, {
                methodOfAttachmentOption: {
                    $set: action.methodOfAttachmentOption,
                },
            });

        case ActiveBreachReportTypes.SET_NAME:
            return update(state, {
                name: {
                    $set: action.name,
                },
            });

        case ActiveBreachReportTypes.SET_NET_EXPLOSIVE_WEIGHT:
            return update(state, {
                netExplosiveWeight:  {
                    $set: action.netExplosiveWeight,
                },
                tntEquivalentInGram: {
                    $set: action.tntEquivalentInGram,
                },
            });

        case ActiveBreachReportTypes.SET_OPERATOR:
            return update(state, {
                operator: {
                    $set: action.operator,
                },
            });

        case ActiveBreachReportTypes.SET_PRIMING_COUNT:
            return update(state, {
                primingCount: {
                    $set: action.primingCount,
                },
            });

        case ActiveBreachReportTypes.SET_ROOM_HEIGHT:
            return update(state, {
                room: {
                    heightInMeter: {
                        $set: action.roomHeight,
                    },
                },
            });

        case ActiveBreachReportTypes.SET_ROOM_LENGTH:
            return update(state, {
                room: {
                    lengthInMeter: {
                        $set: action.roomLength,
                    },
                },
            });

        case ActiveBreachReportTypes.SET_ROOM_WIDTH:
            return update(state, {
                room: {
                    widthInMeter: {
                        $set: action.roomWidth,
                    },
                },
            });

        case ActiveBreachReportTypes.SET_SAFE_STACKING_DISTANCE:
            return update(state, {
                safeStackingDistance: {
                    $set: action.safeStackingDistance,
                },
            });

        case ActiveBreachReportTypes.SET_SHOT_NUMBER:
            return update(state, {
                shotNumber: {
                    $set: action.shotNumber,
                },
            });

        case ActiveBreachReportTypes.SET_TARGET_DESCRIPTION:
            return update(state, {
                targetDescription: {
                    $set: action.targetDescription,
                },
            });

        case ActiveBreachReportTypes.SET_TARGET_OPTION:
            return update(state, {
                targetOption: {
                    $set: action.targetOption,
                },
            });

        case ActiveBreachReportTypes.SET_TEAM:
            return update(state, {
                team: {
                    $set: action.team,
                },
            });

        case ActiveBreachReportTypes.SET_TYPE_OF_BREACH:
            return update(state, {
                typeOfBreach: {
                    $set: action.typeOfBreach,
                },
            });

        case ActiveBreachReportTypes.SET_TYPE_OF_CHARGE:
            return update(state, {
                typeOfCharge: {
                    $set: action.typeOfCharge,
                },
            });

        case ActiveBreachReportTypes.SET_ACTIVE_BREACH_REPORT:
        case ActiveBreachReportTypes.LOAD_BREACH_REPORT:
            return update(state, {
                $set: {
                    ...action.breachReport,
                    isNew: false,
                },
            });

        case ActiveBreachReportTypes.SET_TITLE:
            return update(state, {
                title: {
                    $set: action.title,
                },
            });

        default:
            return state;
    }
};