import ComponentHelper     from '../../../helper/ComponentHelper';
import React               from 'react';
import PropTypes           from '../../PropTypes';
import styles              from './styles.module.scss';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus }          from '@fortawesome/free-solid-svg-icons';
import { faTrash }         from '@fortawesome/free-solid-svg-icons';
import _                   from 'lodash';
import ImageStorage        from '../../../helper/ImageStore';
import Img                 from 'react-fix-image-orientation';

class ImageUpload extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            images:   [],
            imageIds: [],
        };
    }

    imagesChanged = (images) => {
        this.props.imagesChanged(_.cloneDeep(images));
    };

    onImageLoadEnd = (key, reader, images, uploadedFiles) => {
        ImageStorage.saveImage(reader.result).then(
            id => {
                images.push(id);
                this.imagesChanged(images);
            },
            error => {
                console.log('ImageUpload: saveImage ', error);
            },
        );
    };

    processUploadedFile = (key, uploadedFiles, images) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            this.onImageLoadEnd(key, reader, images, uploadedFiles);
        };

        reader.readAsDataURL(uploadedFiles[key]);
    };

    addImage = (event) => {
        const uploadedFiles = event.target.files;
        const images        = _.cloneDeep(this.state.imageIds);

        Object
            .keys(uploadedFiles)
            .forEach(
                (key) => {
                    this.processUploadedFile(key, uploadedFiles, images);
                },
            );
    };

    removeImage = (key) => {
        const images = _.cloneDeep(this.state.imageIds);
        const index  = _.indexOf(images, key);

        images.splice(index, 1);

        ImageStorage.deleteImage(key).then(
            result => {
                this.imagesChanged(images);
            },
            error => {
                console.log('ImageUpload: deleteImage error:', error);
            },
        );
    };

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.images !== prevState.imageIds) {
            return { imageIds: _.cloneDeep(nextProps.images) };
        }

        return null;
    }

    loadImagesFromStore = (imageIds) => {
        ImageStorage.loadImagesFromStoreByIds(imageIds, this.loadImagesComplete);
    };

    loadImagesComplete = (images) => {
        this.setState({
            images,
        });
    };

    componentDidMount () {
        this.loadImagesFromStore(this.state.imageIds);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (!_.isEqual(prevState.imageIds, this.state.imageIds)) {
            this.loadImagesFromStore(this.state.imageIds);
        }
    }

    renderImages () {
        return this.state.images.map(
            (imageData) => {
                return (
                    <div
                        key={'image_' + imageData.id}
                        className={classNames([styles.imageContainer])}
                    >
                        <Img
                            src={imageData.image}
                            alt={'image_' + imageData.id}
                        />
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                                this.removeImage(imageData.id);
                            }}
                        />
                    </div>
                );
            },
        );
    };

    render () {
        return (
            <div className={styles.uploadWrapper}>
                {
                    this.renderImages()
                }
                <div className={styles.fileInputContainer}>
                    <input
                        type={'file'}
                        onChange={this.addImage}
                        multiple
                        accept={'image/*'}
                    />
                    <div
                        className={
                            classNames([
                                styles.addImageContainer,
                                styles.imageContainer,
                            ])
                        }
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.clickThrough}
                        />
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ImageUpload;

Component.propTypes = {
    imagesChanged: PropTypes.func,
    images:        PropTypes.array,
};

Component.defaultProps = {
    imagesChanged: _.noop,
    images:        [],
};

Component.renderAffectingProps = [
    'images',
];

Component.renderAffectingStates = [
    'images',
    'imageIds',
];

export default Component;
