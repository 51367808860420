export const ShotNumberMode = {
    manual:        'manual',
    automatic:     'automatic',
    semiAutomatic: 'semiAutomatic',
};

export const ShotNumberBaseDate = {
    dateBuild: 'creationDate',
    dateUsed:  'dateUsed',
};
