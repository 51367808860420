import _ from 'lodash';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n from 'i18next';
import PropTypes from '../../PropTypes';
import React from 'react';
import styles from './styles.module.scss';
import TextInput from '../../stateless/TextInput';
import {bindActionCreators} from 'redux';
import {BreachReportsActions} from '../../../store/actions/breachReports';
import {connect} from 'react-redux';

class BreachReportSearch extends React.Component {
    searchForm  = null;
    searchQuery = null;

    componentDidUpdate () {
        this.searchQuery = this.props.searchQuery;
    }

    onSearchSubmit = (event) => {
        event.preventDefault();

        if (event.target.checkValidity()) {
            this.submitSearchForm();
        } else {
            event.target.reportValidity();
        }
    };

    onSearchSubmitButtonClicked = () => {
        this.searchForm.dispatchEvent(new Event('submit'));
    };

    submitSearchForm = () => {
        this.props.searchBreachReport({
            searchQuery: this.searchQuery,
        });
    };

    onSearchQueryChange = (event) => {
        this.searchQuery = event.target.value;

        this.onSearchSubmitButtonClicked();
    };

    render () {
        return (
            <div className={styles.container}>
                <form
                    onSubmit={this.onSearchSubmit}
                    ref={this.setSearchFormRef}
                >
                    <TextInput
                        debounceTimeout={200}
                        pattern={'.{3,}'}
                        title={I18n.t('searchPatternError')}
                        onChange={this.onSearchQueryChange}
                        placeholder={I18n.t('searchFieldPlaceholder')}
                        value={this.props.searchQuery}
                    />
                </form>
            </div>
        );
    }

    setSearchFormRef = (inputRef) => {
        this.searchForm = inputRef;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreachReportSearch;

Component.propTypes = {
    searchQuery: PropTypes.string,
};

Component.defaultProps = {
    searchQuery: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        searchQuery: state.breachReports.searchQuery,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    BreachReportsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);