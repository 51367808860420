//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import * as ApiUrls    from './Api';

export default class Api {
    static getBackendUrl () {
        const url                       = window.location.href;

        if (new RegExp('p3dtechnologies').test(url)) {
            return PRODUCTION_BACKEND_URL;
        }

        if (new RegExp('staging').test(url)) {
            return STAGING_BACKEND_URL;
        }

        if (new RegExp('dev').test(url)) {
            return DEV_BACKEND_URL;
        }

        return LOCAL_BACKEND_URL;
    };

    /**
     * @returns {boolean}
     */
    static isStaging () {
        return (
            BACKEND_URL === STAGING_BACKEND_URL
        );
    }

    /**
     * @param host
     */
    static checkBackendUrl (host) {
        if (host.endsWith('/')) {
            console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /');
        }

        if (host !== PRODUCTION_BACKEND_URL) {
            console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
        }

        if (host.indexOf('https://') === -1) {
            console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
        }
    }

    /**
     * @returns {[]}
     */
    static getAllUrls () {
        const apis = [];

        Object.keys(ApiUrls).forEach((key) => {
            const url = ApiUrls[key];

            if (typeof url !== 'function') {
                apis.push(url);
            }
        });

        return apis;
    }
}

console.log(`============================================================> ${process.env.HOST_URL}`)
const PRODUCTION_BACKEND_URL  = `https://api.p3dtechnologies.com`;
const STAGING_BACKEND_URL     = 'https://api.stage.breachapp.lanars.com';
const DEV_BACKEND_URL         = 'https://api.p3dtechnologies.com';
const LOCAL_BACKEND_URL       = 'https://breachapp.lanars-dev.com/';
const BACKEND_URL             = Api.getBackendUrl();
export const API_LICENSES_URL = 'api/licenses';
export const BUY_LICENSE_URL  = 'buy-license';
