import Routes                        from '../../constants/Routes';
import { push }                      from 'connected-react-router';
import { put }                       from 'redux-saga/effects';
import { ActiveBreachReportActions } from '../actions/activeBreachReport';

const saveAsBreachReport = function* ({ preCalculatedBreachpop }) {
    yield put(ActiveBreachReportActions.applyValues({ values: preCalculatedBreachpop }));
    yield put(push(Routes.newBreachReport));
    yield put(ActiveBreachReportActions.recalculateValues());
};

export default {
    saveAsBreachReport,
};
