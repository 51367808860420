import React           from 'react';
import { Component }   from 'react';
import Header          from '../components/stateless/Header';
import styles          from '../styles.module.scss';
import Footer          from '../components/stateless/Footer';
import I18n            from 'i18next';
import NEWtoMSDDiagram from '../components/stateless/NEWtoMSDDiagram';
import classNames      from 'classnames';
import { Helmet }      from 'react-helmet';
import PageTitleHelper from '../helper/PageTitle';

const maximumIncidentPressure = [
    [35, 5, 'Threshold for eardrum damage'],
    [102, 15, '50 % chance of eardrum damage'],
    [150, 22, 'Threshold for lung damage'],
    [167, 25, '1% chance of lung damage'],
    [320, 47, '50% chance of death'],
    [633, 93, 'Nearly 100% chance of death'],
];

/**
 * Renders the Screen containing the
 * net explosive weight **(NEW)** to minimum safe stacking distance **(MSD)** diagram
 */
class Screen extends Component {
    renderTableRow = (row) => {
        return (
            <tr>
                {
                    row.map(this.renderTableColumn)
                }
            </tr>
        );
    };

    renderTableColumn = (column) => {
        return (
            <td>
                {column}
            </td>
        );
    };

    renderMaximumIncidentPressureContent = () => {
        return (
            maximumIncidentPressure.map(
                (row, rowIndex) => {
                    const rowKey = 'maximum_pressure_row_' + rowIndex;

                    return (
                        <tr key={rowKey}>
                            {
                                row.map(
                                    (column, columnIndex) => {
                                        const columnKey = 'maximum_pressure_column_' + rowIndex + '_' + columnIndex;

                                        return (
                                            <td
                                                key={columnKey}
                                                className={styles.textAlignLeft}
                                            >
                                                {column}
                                            </td>
                                        );
                                    },
                                )
                            }
                        </tr>
                    );
                },
            )
        );
    };

    renderMaximumIncidentPressure = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>
                            {I18n.t('kiloPascalShort')}
                        </th>
                        <th>
                            {I18n.t('poundForcePerSquareInchShort')}
                        </th>
                        <th
                            className={
                                classNames(
                                    styles.textAlignLeft,
                                )
                            }
                        >
                            {I18n.t('effectTableHeader')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.renderMaximumIncidentPressureContent()
                    }
                </tbody>
            </table>
        );
    };

    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('netExplosiveWeightToMinimumSafeStackingDistanceDiagram'))}
                    </title>
                </Helmet>
                <Header
                    title={I18n.t('netExplosiveWeightToMinimumSafeStackingDistanceDiagram')}
                />
                <div className={styles.contentViewWithPadding}>
                    <div className={styles.tableContainer}>
                        <NEWtoMSDDiagram />
                    </div>
                    <div className={styles.tableContainer}>
                        <h4>
                            {I18n.t('maximumIncidentPressure')}
                        </h4>
                        {this.renderMaximumIncidentPressure()}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

Screen.renderAffectingStates = [];

export default Screen;