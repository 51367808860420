import _ from 'lodash';
import AlertBoxManager from '../components/connected/AlertBoxManager';
import BoostersList from '../components/stateless/BoostersList';
import ColorButton from '../components/stateless/ColorButton';
import ColorButtonTheme from '../components/stateless/ColorButton/ColorButtonTheme';
import Colors from '../styles/colors.scss';
import CountrySelectionInput from '../components/stateless/CountrySelectionInput';
import DateInput from '../components/stateless/DateInput';
import ExplosiveCalculator from '../helper/ExplosiveCalculator';
import ExplosivesList from '../components/stateless/ExplosivesList';
import Footer from '../components/stateless/Footer';
import FormGroup from '../components/stateless/FormGroup';
import FormGroupTheme from '../components/stateless/FormGroup/FormGroupTheme';
import Header from '../components/stateless/Header';
import I18n from 'i18next';
import ImageBackground from '../components/stateless/ImageBackground';
import ImageUpload from '../components/stateless/ImageUpload';
import InputContainer from '../components/stateless/InputContainer';
import InternalPressureVolumeResult from '../components/stateless/InternalPressureVolumeResult';
import MinimumSafeToStackDistance from '../components/stateless/MinimumSafeToStackDistance';
import OptionSelect from '../components/stateless/OptionSelect';
import PageTitleHelper from '../helper/PageTitle';
import PrimingSelectionInput from '../components/stateless/PrimingSelectionInput';
import PurchaseLicenseHint from '../components/connected/PurchaseLicenseHint';
import React, {Component} from 'react';
import RoomSizeInput from '../components/stateless/RoomSizeInput';
import RoomVolumeResult from '../components/stateless/RoomVolumeResult';
import SelectInput from '../components/stateless/SelectInput';
import styles from '../styles.module.scss';
import TextInput from '../components/stateless/TextInput';
import TextInputType from '../components/stateless/TextInput/TextInputType';
import TotalNetExplosiveWeight from '../components/stateless/TotalNetExplosiveWeight';
import {ActiveBreachReportActions} from '../store/actions/activeBreachReport';
import {AlertBoxActions} from '../store/actions/alertBox';
import {bindActionCreators} from 'redux';
import {
    BreachModeOptions,
    BreachResultMethodOfAttachmentOptions,
    BreachResultOptions,
    BreachResultTargetOptions,
    InsideOptions,
    LightOptions,
    OperatorOptions,
    TypeOfBreachOptions
} from '../constants/Breach';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {ShotNumberMode} from '../constants/ShotNumberGenerator';

/**
 * @name NewBreachReportScreen
 */
class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            showPurchaseLicenseHint: false,
        };
    }

    saveButtonPressed = () => {
        if (
            this.props.isNew &&
            !this.props.isUnlocked
        ) {
            this.props.clearAlerts();
            this.props.showErrorAlert({
                text: I18n.t('licenseRequiredError1'),
            });
            this.setState({
                showPurchaseLicenseHint: true,
            });
            window.scrollTo(0, 0);
        } else {
            this.props.clearAlerts();
            this.props.saveActiveBreachReport();
        }
    };

    /**
     * Renders the Group for the resulting MSD **(Minimum Safe Stacking Distance)**
     */
    renderMSDResultGroup = () => {

        return (
            <MinimumSafeToStackDistance
                unitSystem={this.props.unit}
                safeStackingDistanceInMeter={this.props.safeStackingDistance}
            />
        );
    };

    /**
     * Renders the resulting internal pressure
     */
    renderInternalPressureVolumeResultGroup = () => {
        return (
            <InternalPressureVolumeResult
                unitSystem={this.props.unit}
                key={'internalPressureVolumeResult'}
                internalPSI={this.props.internalPSI}
            />
        );
    };

    imagesChanged = (images) => {
        this.props.setImages(
            {
                images,
            },
        );
    };

    onBreachModeChanged = (breachMode) => {
        this.props.setBreachMode({ breachMode });
    };

    onCountryChanged = (country) => {
        this.props.setCountry({
            country: country.value,
        });
    };

    onCreationDateChanged = (creationDate) => {
        this.props.setCreationDate({ creationDate });
    };

    onDateUsedChanged = (dateUsed) => {
        this.props.setDateUsed({ dateUsed });
    };

    onExplosivesChanged = (explosives) => {
        this.props.setExplosives({ explosives });
    };

    onInsideChanged = (value) => {
        this.props.setInside({ inside: value });
    };

    onLightChanged = (light) => {
        this.props.setLight({ light });
    };

    onLocationChanged = (event) => {
        const location = _.get(event, 'target.value');

        this.props.setLocation({ location });
    };

    onMethodOfAttachmentDescriptionChanged = (event) => {
        const value = _.get(event, 'target.value');

        this.props.setMethodOfAttachmentDescription({ methodOfAttachmentDescription: value });
    };

    onMethodOfAttachmentOptionChanged = (event) => {
        const value = _.get(event, 'value');

        this.props.setMethodOfAttachmentOption({ methodOfAttachmentOption: value });
    };

    onNameCallSignChanged = (event) => {
        const name = _.get(event, 'target.value');

        this.props.setName({ name });
    };

    onOperatorChanged = (operator) => {
        this.props.setOperator({ operator });
    };

    onPrimingChanged = (priming) => {
        this.props.setPrimingCount({
            primingCount: priming.value,
        });
    };

    onBreachResultChanged = (breachResult) => {
        this.props.setBreachResult({ breachResult });
    };

    onRoomHeightChanged = (event) => {
        const roomHeight = event.target.value;

        this.props.updateRoomHeight({ roomHeight });
    };

    onRoomLengthChanged = (event) => {
        const roomLength = event.target.value;

        this.props.updateRoomLength({ roomLength });
    };

    onRoomWidthChanged = (event) => {
        const roomWidth = event.target.value;

        this.props.updateRoomWidth({ roomWidth });
    };

    onTitleChanged = (event) => {
        const title = _.get(event, 'target.value');

        this.props.setTitle({ title });
    };

    onShotNumberChanged = (event) => {
        const shotNumber = _.get(event, 'target.value');

        this.props.setShotNumber({ shotNumber });
    };

    onTargetDescriptionChanged = (event) => {
        const value = _.get(event, 'target.value');

        this.props.setTargetDescription({ targetDescription: value });
    };

    onTargetOptionChanged = (event) => {
        const value = _.get(event, 'value');

        this.props.setTargetOption({ targetOption: value });
    };

    onTeamSquadronChanged = (event) => {
        const team = _.get(event, 'target.value');

        this.props.setTeam({ team });
    };

    onTypeOfBreachChanged = (typeOfBreach) => {
        this.props.setTypeOfBreach({ typeOfBreach });
    };

    onTypeOfChargeChanged = (event) => {
        const typeOfCharge = _.get(event, 'target.value');

        this.props.setTypeOfCharge({ typeOfCharge });
    };

    onCommentsChanged = (event) => {
        const value = _.get(event, 'target.value');

        this.props.setComments({ comments: value });
    };

    renderCommentsGroup = () => {
        return (
            <FormGroup
                label={I18n.t('comments')}
            >
                <InputContainer
                    label={I18n.t('comments')}
                >
                    <TextInput
                        type={TextInputType.textarea}
                        onChange={this.onCommentsChanged}
                        value={this.props.comments}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    onBoostersChanged = (boosters) => {
        this.props.setBoosters({ boosters });
    };

    renderExplosivesGroup = () => {
        return (
            <div>

                <FormGroup
                    label={I18n.t('explosives')}
                >
                    <ExplosivesList
                        explosives={this.props.explosives}
                        onChange={this.onExplosivesChanged}
                        unit={this.props.unit}
                    />
                </FormGroup>
                <FormGroup
                    label={I18n.t('boosters')}
                >
                    <BoostersList
                        boosters={this.props.boosters}
                        onChange={this.onBoostersChanged}
                        unit={this.props.unit}
                    />
                </FormGroup>
            </div>
        );
    };

    renderInsideBreachRelevantContent = () => {
        if (this.props.inside) {
            return [
                this.renderRoomGroup(),
                this.renderRoomVolumeResultGroup(),
                this.renderInternalPressureVolumeResultGroup(),
            ];
        }

        return null;
    };

    renderInsideGroup = () => {
        return (
            <FormGroup
                label={I18n.t('inside')}
            >
                <InputContainer
                    label={I18n.t('inside')}
                >
                    <OptionSelect
                        label={I18n.t('inside')}
                        value={this.props.inside}
                        onValueChanged={this.onInsideChanged}
                        options={InsideOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderLocationGroup = () => {
        return (
            <FormGroup
                label={I18n.t('location')}
            >
                <InputContainer
                    label={I18n.t('location')}
                >
                    <TextInput
                        onChange={this.onLocationChanged}
                        value={this.props.location}
                    />
                </InputContainer>
                <InputContainer
                    label={I18n.t('light')}
                >
                    <OptionSelect
                        value={this.props.light}
                        onValueChanged={this.onLightChanged}
                        options={LightOptions}
                    />
                </InputContainer>
                <InputContainer
                    label={I18n.t('type')}
                >
                    <OptionSelect
                        value={this.props.typeOfBreach}
                        onValueChanged={this.onTypeOfBreachChanged}
                        options={TypeOfBreachOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderMethodOfAttachmentGroup = () => {
        return (
            <FormGroup
                label={I18n.t('methodOfAttachment')}
            >
                <InputContainer
                    label={I18n.t('option')}
                >
                    <SelectInput
                        value={this.props.methodOfAttachmentOption}
                        onChange={this.onMethodOfAttachmentOptionChanged}
                        options={BreachResultMethodOfAttachmentOptions}
                    />
                </InputContainer>
                <InputContainer
                    label={I18n.t('description')}
                >
                    <TextInput
                        type={TextInputType.textarea}
                        onChange={this.onMethodOfAttachmentDescriptionChanged}
                        value={this.props.methodOfAttachmentDescription}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderOperatorGroup = () => {
        const shotNumberReadOnly = this.props.shotNumberMode === ShotNumberMode.automatic;

        return (
            <div>
                <FormGroup
                    label={I18n.t('operator')}
                >
                    <InputContainer
                        label={I18n.t('operator')}
                    >
                        <OptionSelect
                            value={this.props.operator}
                            onValueChanged={this.onOperatorChanged}
                            options={OperatorOptions}
                        />
                    </InputContainer>
                </FormGroup>
                <FormGroup>
                    <CountrySelectionInput
                        onValueChanged={this.onCountryChanged}
                        value={this.props.country}
                    />
                    <InputContainer
                        label={I18n.t('nameCallSign')}
                    >
                        <TextInput
                            onChange={this.onNameCallSignChanged}
                            value={this.props.name}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('teamSquadron')}
                    >
                        <TextInput
                            onChange={this.onTeamSquadronChanged}
                            value={this.props.team}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('title')}
                    >
                        <TextInput
                            onChange={this.onTitleChanged}
                            value={this.props.title}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('shotNumber')}
                    >
                        <TextInput
                            readOnly={shotNumberReadOnly}
                            onChange={this.onShotNumberChanged}
                            value={this.props.shotNumber}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('dateBuild')}
                    >
                        <DateInput
                            date={this.props.creationDate}
                            onChange={this.onCreationDateChanged}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('dateUsed')}
                    >
                        <DateInput
                            date={this.props.dateUsed}
                            onChange={this.onDateUsedChanged}
                        />
                    </InputContainer>
                    <InputContainer
                        label={I18n.t('typeOfCharge')}
                    >
                        <TextInput
                            onChange={this.onTypeOfChargeChanged}
                            value={this.props.typeOfCharge}
                        />
                    </InputContainer>
                </FormGroup>
            </div>
        );
    };

    renderPhotosGroup = () => {
        return (
            <FormGroup
                label={I18n.t('photos')}
            >
                <InputContainer>
                    <ImageUpload
                        onLoadingImagesStarted={() => {
                            this.props.loadingImages();
                        }}
                        onLoadingImagesComplete={() => {
                            this.props.loadingImagesDone();
                        }}
                        images={this.props.images}
                        imagesChanged={this.imagesChanged}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderPrimingGroup = () => {
        return (
            <PrimingSelectionInput
                onValueChanged={this.onPrimingChanged}
                value={this.props.primingCount}
            />
        );
    };

    renderResultGroup = () => {
        return (
            <FormGroup
                label={I18n.t('result')}
            >
                <InputContainer
                    label={I18n.t('result')}
                >
                    <OptionSelect
                        value={this.props.breachResult}
                        onValueChanged={this.onBreachResultChanged}
                        options={BreachResultOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderRoomGroup = () => {
        return (
            <RoomSizeInput
                key={'roomSizeInput'}
                onRoomLengthChanged={this.onRoomLengthChanged}
                onRoomWidthChanged={this.onRoomWidthChanged}
                onRoomHeightChanged={this.onRoomHeightChanged}
                room={this.props.room}
                unitSystem={this.props.unit}
            />
        );
    };

    renderRoomVolumeResultGroup = () => {
        return (
            <RoomVolumeResult
                key={'roomVolumeResult'}
                room={this.props.room}
                unitSystem={this.props.unit}
            />
        );
    };

    renderTargetGroup = () => {
        return (
            <FormGroup
                label={I18n.t('target')}
            >
                <InputContainer
                    label={I18n.t('option')}
                >
                    <SelectInput
                        value={this.props.targetOption}
                        onChange={this.onTargetOptionChanged}
                        options={BreachResultTargetOptions}
                    />
                </InputContainer>
                <InputContainer
                    label={I18n.t('description')}
                >
                    <TextInput
                        type={TextInputType.textarea}
                        onChange={this.onTargetDescriptionChanged}
                        value={this.props.targetDescription}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderTotalNetExplosiveWeightGroup = () => {
        let tntEquivalentInGram = this.props.tntEquivalentInGram;

        if (!tntEquivalentInGram) {
            tntEquivalentInGram = ExplosiveCalculator.calculateTotalTNTEquivalentInGram(
                this.props.explosives,
                this.props.primingCount,
                this.props.boosters,
            );
        }

        return (
            <TotalNetExplosiveWeight
                unitSystem={this.props.unit}
                netExplosiveWeight={this.props.netExplosiveWeight}
                tntEquivalentInGram={tntEquivalentInGram}
            />
        );
    };

    renderTypeGroup = () => {
        return (
            <FormGroup
                label={I18n.t('type')}
            >
                <InputContainer
                    label={I18n.t('type')}
                >
                    <OptionSelect
                        value={this.props.breachMode}
                        onValueChanged={this.onBreachModeChanged}
                        options={BreachModeOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    renderPurchaseLicenseHint = () => {
        if (this.state.showPurchaseLicenseHint) {
            return (
                <PurchaseLicenseHint />
            );
        }

        return null;
    };

    renderSaveButtonGroup = () => {
        if (!this.state.showPurchaseLicenseHint) {
            return (
                <FormGroup
                    theme={FormGroupTheme.submit}
                >
                    <ColorButton
                        onClick={this.saveButtonPressed}
                        text={I18n.t('save')}
                        theme={ColorButtonTheme.yellow}
                    />
                </FormGroup>
            );
        }
    };

    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('newBreachReport'))}
                    </title>
                </Helmet>
                <Header
                    title={I18n.t('newBreachReport')}
                />
                <ImageBackground
                    image={require('../assets/images/breacherInfoBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'60%'}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <AlertBoxManager />
                            {this.renderPurchaseLicenseHint()}

                            {this.renderTypeGroup()}
                            {this.renderOperatorGroup()}
                            {this.renderLocationGroup()}
                            {this.renderExplosivesGroup()}
                            {this.renderPrimingGroup()}
                            {this.renderTotalNetExplosiveWeightGroup()}
                            {this.renderMSDResultGroup()}
                            {this.renderInsideGroup()}
                            {this.renderInsideBreachRelevantContent()}
                            {this.renderMethodOfAttachmentGroup()}
                            {this.renderTargetGroup()}
                            {this.renderResultGroup()}
                            {this.renderCommentsGroup()}
                            {this.renderPhotosGroup()}
                            {this.renderSaveButtonGroup()}
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }
}

Screen.renderAffectingStates = [
    'type',
];

const mapStateToProps = state => (
    {
        boosters:                      state.activeBreachReport.boosters,
        breachMode:                    state.activeBreachReport.breachMode,
        breachReports:                 state.breachReports.breachReports,
        breachResult:                  state.activeBreachReport.breachResult,
        comments:                      state.activeBreachReport.comments,
        country:                       state.activeBreachReport.country,
        creationDate:                  state.activeBreachReport.creationDate,
        dateUsed:                      state.activeBreachReport.dateUsed,
        explosives:                    state.activeBreachReport.explosives,
        images:                        state.activeBreachReport.images,
        inside:                        state.activeBreachReport.inside,
        internalPSI:                   state.activeBreachReport.internalPSI,
        isNew:                         state.activeBreachReport.isNew,
        isUnlocked:                    state.license.isUnlocked,
        light:                         state.activeBreachReport.light,
        location:                      state.activeBreachReport.location,
        methodOfAttachmentDescription: state.activeBreachReport.methodOfAttachment,
        methodOfAttachmentOption:      state.activeBreachReport.methodOfAttachmentOption,
        name:                          state.activeBreachReport.name,
        netExplosiveWeight:            state.activeBreachReport.netExplosiveWeight,
        operator:                      state.activeBreachReport.operator,
        primingCount:                  state.activeBreachReport.primingCount,
        room:                          state.activeBreachReport.room,
        safeStackingDistance:          state.activeBreachReport.safeStackingDistance,
        shotNumber:                    state.activeBreachReport.shotNumber,
        shotNumberMode:                state.settings.shotNumberMode,
        targetDescription:             state.activeBreachReport.targetDescription,
        targetOption:                  state.activeBreachReport.targetOption,
        team:                          state.activeBreachReport.team,
        title:                         state.activeBreachReport.title,
        typeOfBreach:                  state.activeBreachReport.typeOfBreach,
        typeOfCharge:                  state.activeBreachReport.typeOfCharge,
        unit:                          state.settings.unit,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    ActiveBreachReportActions,
    AlertBoxActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);