//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import moment                      from 'moment';
import ShotNumberGenerator         from '../../helper/ShotNumberGenerator';
import { ActiveBreachReportTypes } from '../actions/activeBreachReport';
import { BreachReportsTypes }      from '../actions/breachReports';

const duplicateBreachReport = store => next => action => {
    if (action.type === ActiveBreachReportTypes.DUPLICATE_BREACH_REPORT) {
        const state          = store.getState();
        const nextShotNumber = ShotNumberGenerator.getNextShotNumberByStats(state);

        const increaseShotNumberAction = {
            type:           BreachReportsTypes.SET_NEXT_SHOT_NUMBER,
            nextShotNumber: nextShotNumber,
        };

        next(increaseShotNumberAction);

        action.nextShotNumber = nextShotNumber;
    }

    return next(action);
};

export default duplicateBreachReport;
