import ComponentHelper from '../../../helper/ComponentHelper';
import React from 'react';
import PropTypes from '../../PropTypes';
import styles from './styles.module.scss';

class ImagePreviewItem extends React.Component {
    render () {
        const image               = this.props.image;
        const imageContainerStyle = {
            backgroundImage: 'url(' + image + ')',
        };

        return (
            <img
                className={styles.printImage}
                src={image}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ImagePreviewItem;

Component.propTypes = {
    image: PropTypes.object,
};

Component.defaultProps = {
    image: {},
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;