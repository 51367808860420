import ComponentHelper    from '../../../helper/ComponentHelper';
import React              from 'react';
import styles             from './styles.module.scss';
import PropTypes          from '../../PropTypes';
import classNames         from 'classnames';
import Link               from '../Link';
import StartMenuItemBadge from '../StartMenuItemBadge';

class StartMenuItem extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getButtonBackgroundStyle () {
        return {
            backgroundImage: 'url(' + this.props.backgroundImage + ')',
        };
    }

    render () {
        return (
            <Link
                to={this.props.to}
                className={styles.container}
            >
                <StartMenuItemBadge
                    value={this.props.badgeCount}
                />
                <div
                    className={styles.innerContainer}
                    style={this.getButtonBackgroundStyle()}
                >
                    <span
                        className={
                            classNames(styles.title)
                        }
                    >
                        {this.props.title}
                    </span>
                    <span
                        className={
                            classNames(styles.title)
                        }
                    >
                        {this.props.subTitle}
                    </span>
                    <span
                        className={
                            classNames(
                                styles.title,
                                styles.highlightedSubTitle,
                            )}
                    >
                        {this.props.highlightedSubTitle}
                    </span>
                </div>
            </Link>
        );
    }
}

const Component = StartMenuItem;

Component.propTypes = {
    title:               PropTypes.string,
    subTitle:            PropTypes.string,
    highlightedSubTitle: PropTypes.string,
    to:                  PropTypes.string,
    badgeCount:          PropTypes.number,
};

Component.defaultProps = {
    title:               '',
    subTitle:            '',
    highlightedSubTitle: '',
    to:                  '',
    badgeCount:          null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;