import * as ApiUrls           from '../constants/Api';
import _                      from 'lodash';
import AlertBoxManager        from '../components/connected/AlertBoxManager';
import ColorButton            from '../components/stateless/ColorButton';
import ColorButtonTheme       from '../components/stateless/ColorButton/ColorButtonTheme';
import Colors                 from '../styles/colors.scss';
import Footer                 from '../components/stateless/Footer';
import FormGroup              from '../components/stateless/FormGroup';
import Header                 from '../components/stateless/Header';
import HeadlineWithButton     from '../components/stateless/HeadlineWithButton';
import I18n                   from 'i18next';
import ImageBackground        from '../components/stateless/ImageBackground';
import InputContainer         from '../components/stateless/InputContainer';
import React                  from 'react';
import SelectInput            from '../components/stateless/SelectInput';
import styles                 from '../styles.module.scss';
import TextInput              from '../components/stateless/TextInput';
import { Api }                from '../api';
import { bindActionCreators } from 'redux';
import { Component }          from 'react';
import { connect }            from 'react-redux';
import { LicenseActions }     from '../store/actions/license';
import { SettingsActions }    from '../store/actions/settings';
import { ShotNumberBaseDate } from '../constants/ShotNumberGenerator';
import { ShotNumberMode }     from '../constants/ShotNumberGenerator';

const shotNumberModeOptions = _.map(
    ShotNumberMode, (shotNumberMode, key) => {
        return {
            value: shotNumberMode,
            label: I18n.t(key),
        };
    },
);

const shotBaseDateOptions = _.map(
    ShotNumberBaseDate, (shotBaseDate, key) => {
        return {
            value: shotBaseDate,
            label: I18n.t(key),
        };
    },
);

class Screen extends Component {
    buyLicenseButtonPressed = () => {
        const buyLicenseUrl = Api.getUrl(ApiUrls.BUY_LICENSE_URL);

        window.open(buyLicenseUrl);
    };

    checkLicenseKeyButtonPressed = () => {
        this.props.checkLicenseKey();
    };

    constructor (props) {
        super(props);
    }

    onTypeChanged = (breacherType) => {
        this.props.setBreacherType({ breacherType });
    };

    onFormSubmit = (event) => {
        event.preventDefault();
    };

    onLicenseKeyChanged = (event) => {
        this.props.setLicenseKey({
            licenseKey: event.target.value,
        });
    };

    onShotNumberModeChanged = (selection) => {
        const shotNumberMode = selection.value;

        this.props.setShotNumberMode({ shotNumberMode });
    };

    onShotNumberBaseDateChanged = (selection) => {
        const shotNumberBaseDate = selection.value;

        this.props.setShotNumberBaseDate({ shotNumberBaseDate });
    };

    renderShotNumberBaseDate () {
        if (
            this.props.shotNumberMode === ShotNumberMode.automatic ||
            this.props.shotNumberMode === ShotNumberMode.semiAutomatic
        ) {
            return (
                <InputContainer
                    label={I18n.t('shotNumberBaseDate')}
                >
                    <SelectInput
                        label={I18n.t('shotNumberBaseDate')}
                        onChange={this.onShotNumberBaseDateChanged}
                        options={shotBaseDateOptions}
                        placeholder={I18n.t('selectCountry')}
                        value={this.props.shotNumberBaseDate}
                    />
                </InputContainer>
            );
        }

        return null;
    }

    releaseLicenseButtonPressed = () => {
        this.props.releaseLicenseKey();
    };

    render () {
        return (
            <div>
                <Header
                    title={I18n.t('settings')}
                />
                <ImageBackground
                    image={require('../assets/images/breacherInfoBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'60%'}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <form onSubmit={this.onFormSubmit}>
                                <input
                                    type={'submit'}
                                    name={'submit'}
                                />
                                <FormGroup
                                    label={I18n.t('shotNumberAutomation')}
                                >
                                    <InputContainer
                                        label={I18n.t('shotNumberMode')}
                                    >
                                        <SelectInput
                                            label={I18n.t('shotNumberMode')}
                                            onChange={this.onShotNumberModeChanged}
                                            options={shotNumberModeOptions}
                                            placeholder={I18n.t('selectShotNumberMode')}
                                            value={this.props.shotNumberMode}
                                        />
                                    </InputContainer>
                                    {this.renderShotNumberBaseDate()}
                                    {this.renderLicenseSection()}
                                </FormGroup>
                            </form>
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }

    renderLicenseSection = () => {
        if (this.props.isUnlocked) {
            return this.renderLicenseSectionUnlocked();
        }

        return this.renderLicenseSectionLocked();
    };

    renderLicenseSectionLocked = () => {
        return (
            <>
                <FormGroup>
                    <HeadlineWithButton
                        buttonPressed={this.buyLicenseButtonPressed}
                        buttonText={I18n.t('buyLicenseButtonText')}
                        hasMarginBottom={false}
                        headlineText={I18n.t('buyLicenseText')}
                    />
                </FormGroup>
                <FormGroup
                    label={I18n.t('license')}
                >
                    <InputContainer
                        label={I18n.t('licenseKey')}
                    >
                        <TextInput
                            onChange={this.onLicenseKeyChanged}
                            value={this.props.licenseKey}
                        />
                    </InputContainer>
                </FormGroup>
                <AlertBoxManager />
                <FormGroup
                    theme={'submit'}
                >
                    <ColorButton
                        onClick={this.checkLicenseKeyButtonPressed}
                        text={I18n.t('checkLicenseKey')}
                        theme={ColorButtonTheme.graySmallFullWidth}
                    />
                </FormGroup>
            </>
        );
    };

    renderLicenseSectionUnlocked = () => {
        return (
            <>
                <FormGroup
                    label={I18n.t('license')}
                >
                    <InputContainer
                        label={I18n.t('licenseKey')}
                    >
                        <TextInput
                            onChange={this.onLicenseKeyChanged}
                            readOnly={true}
                            value={this.props.licenseKey}
                        />
                    </InputContainer>
                </FormGroup>
                <AlertBoxManager />
                <FormGroup
                    theme={'submit'}
                >
                    <ColorButton
                        onClick={this.releaseLicenseButtonPressed}
                        text={I18n.t('releaseLicense')}
                        theme={ColorButtonTheme.graySmallFullWidth}
                    />
                </FormGroup>
            </>
        );
    };

    renderShotNumberBaseDate () {
        if (
            this.props.shotNumberMode === ShotNumberMode.automatic ||
            this.props.shotNumberMode === ShotNumberMode.semiAutomatic
        ) {
            return (
                <InputContainer
                    label={I18n.t('shotNumberBaseDate')}
                >
                    <SelectInput
                        label={I18n.t('shotNumberBaseDate')}
                        onChange={this.onShotNumberBaseDateChanged}
                        options={shotBaseDateOptions}
                        placeholder={I18n.t('selectShotNumberBaseDate')}
                        value={this.props.shotNumberBaseDate}
                    />
                </InputContainer>
            );
        }

        return null;
    }
}

const mapStateToProps = state => (
    {
        eMailAddress:       state.license.eMailAddress,
        isUnlocked:         state.license.isUnlocked,
        licenseKey:         state.license.licenseKey,
        shotNumberMode:     state.settings.shotNumberMode,
        shotNumberBaseDate: state.settings.shotNumberBaseDate,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LicenseActions,
    SettingsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);