import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import classNames      from 'classnames';
import SelectionHelper from '../../../helper/SelectionHelper';
import HeadlineTheme   from './HeadlineTheme';

class Headline extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.headline,
            SelectionHelper.get(
                this.props.theme,
                {
                    [HeadlineTheme.whiteBigCentered]: styles.whiteBigCentered,
                    [HeadlineTheme.whiteSmall]:       styles.whiteSmall,
                },
            ),
        );

        return className;
    };

    render () {
        const finalClassName = this.getClassName();

        return (
            <div className={finalClassName}>
                {this.props.title}
            </div>
        );
    }
}

const Component = Headline;

Component.propTypes = {
    title: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(HeadlineTheme)),
};

Component.defaultProps = {
    title: '',
    theme: HeadlineTheme.whiteSmall,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;