import _                   from 'lodash';
import React               from 'react';
import styles              from './styles.module.scss';
import PropTypes           from '../../PropTypes';
import I18n                from 'i18next';
import ComponentHelper     from '../../../helper/ComponentHelper';
import Units               from '../../../constants/Units';
import FormGroup           from '../FormGroup';
import InputContainer      from '../InputContainer';
import InputContainerType  from '../InputContainer/InputContainerType';
import Value               from '../Value';
import convert             from 'convert-units';
import ExplosiveCalculator from '../../../helper/ExplosiveCalculator';

class MinimumSafeToStackDistance extends React.Component {
    render () {
        const baseUnit                    = Units.BaseDistanceUnit;
        const unit                        = Units.Distance[this.props.unitSystem];
        const safeStackingDistanceInMeter = !isNaN(this.props.safeStackingDistanceInMeter) ? this.props.safeStackingDistanceInMeter : 0;
        const value                       = convert(safeStackingDistanceInMeter).from(baseUnit).to(unit);
        const roundedValue                = ExplosiveCalculator.roundUpToOneAfterDecimal(value);
        const label                       = _.get(this.props, 'label', I18n.t('minimumSafeStackingDistanceShort'));

        return (
            <FormGroup>
                <InputContainer
                    theme={InputContainerType.bigWhiteLabel}
                    label={label}
                >
                    <Value
                        value={`${roundedValue} ${unit}`}
                    />
                </InputContainer>
            </FormGroup>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = MinimumSafeToStackDistance;

Component.propTypes = {
    label:                       PropTypes.string,
    safeStackingDistanceInMeter: PropTypes.number,
    unitSystem:                  PropTypes.string,
};

Component.defaultProps = {
    label:                       null,
    safeStackingDistanceInMeter: null,
    unitSystem:                  null,
};

Component.renderAffectingProps = [
    'safeStackingDistanceInMeter',
    'unitSystem',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;