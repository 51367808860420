import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class OptionSelect extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={styles.container}
            >
                {this.renderOptions()}
            </div>
        );
    }

    renderOptions = () => {
        const options = this.props.options;

        return (
            <div
                className={styles.optionsWrapper}
            >
                {
                    options.map(
                        this.renderOption,
                    )
                }
            </div>
        );
    };

    onOptionClick = (option) => {
        this.props.onValueChanged(option.value);
    };

    renderOption = (option) => {
        const isSelected = _.isEqual(option.value, this.props.value);
        const key        = this.props.label + option.value;

        return (
            <div
                key={key}
                onClick={
                    () => {
                        this.onOptionClick(option);
                    }
                }
                className={
                    classNames(
                        [
                            styles.option,
                        ],
                        {
                            [styles.optionSelected]: isSelected,
                        },
                    )
                }
            >
                {option.label}
            </div>
        );
    };
}

const Component = OptionSelect;

Component.propTypes = {
    options:        PropTypes.array,
    onValueChanged: PropTypes.func,
    value:          PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};

Component.defaultProps = {
    options:        [],
    onValueChanged: _.noop,
    value:          '',
};

Component.renderAffectingProps = [
    'value',
];

Component.renderAffectingStates = [];

export default Component;