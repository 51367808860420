import ComponentHelper from '../../../helper/ComponentHelper';
import React from 'react';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import HeaderButton from '../HeaderButton';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import Routes from '../../../constants/Routes';

class Header extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerLeftContainer}>
                    <HeaderButton
                        icon={faHome}
                        route={Routes.home}
                    />
                    <span className={styles.headerTitle}>
                        {this.props.title}
                    </span>
                </div>
                <div
                    className={styles.headerRightContainer}
                >
                    <img
                        className={styles.headerLogo}
                        src={require('../../../assets/images/headerLogo.png')}
                    />
                </div>
            </div>
        );
    }
}

const Component = Header;

Component.propTypes = {
    title: PropTypes.string,
};

Component.defaultProps = {
    title: '',
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = Object.keys(Component.defaultProps);

export default connect()(Component);