import AlertBox        from '../../../components/stateless/AlertBox';
import ComponentHelper from '../../../helper/ComponentHelper';
import convert         from 'convert-units';
import FormGroup       from '../FormGroup';
import I18n            from 'i18next';
import InputContainer  from '../InputContainer';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import Units           from '../../../constants/Units';
import Value           from '../Value';
import AlertBoxType    from '../AlertBox/AlertBoxType';

class TotalNetExplosiveWeight extends React.Component {
    render () {
        return (
            <FormGroup
                label={I18n.t('totalNetExplosiveWeight')}
            >
                {this.renderContent()}
            </FormGroup>
        );
    }

    renderContent = () => {
        const errorText = this.props.errorText;

        if (errorText) {
            return (
                <InputContainer
                    label={I18n.t('license')}
                >
                    <AlertBox
                        text={errorText}
                        type={AlertBoxType.error}
                    />
                </InputContainer>
            );
        }

        const baseUnit                              = Units.Explosives[Units.Systems.metric.name].weightInGramPerCubicCentimeter;
        const unit                                  = Units.Explosives[this.props.unitSystem].weightInGramPerCubicCentimeter;
        const netExplosiveWeight                    = !isNaN(this.props.netExplosiveWeight) ? this.props.netExplosiveWeight : 0;
        const netExplosiveWeightInCustomUnit        = convert(netExplosiveWeight).from(baseUnit).to(unit);
        const roundedNetExplosiveWeightInCustomUnit = (
            !isNaN(netExplosiveWeightInCustomUnit) ?
                netExplosiveWeightInCustomUnit.toFixed(2) :
                null
        );
        const netExplosiveWeightInOunces            = convert(netExplosiveWeight).from(baseUnit).to('oz');
        const roundedNetExplosiveWeightInOunces     = (
            !isNaN(netExplosiveWeightInOunces) ?
                netExplosiveWeightInOunces.toFixed(2) :
                null
        );
        const tntEquivalentInGram                   = this.props.tntEquivalentInGram;
        const convertedTntEquivalentInPounds        = convert(tntEquivalentInGram).from('g').to(Units.TntEquivalent.metric);
        const roundedConvertedTntEquivalentInPounds = convertedTntEquivalentInPounds.toFixed(3);

        return (
            <>
                <InputContainer
                    label={I18n.t('totalNetExplosiveWeight')}
                >
                    <Value
                        value={roundedNetExplosiveWeightInCustomUnit}
                        unit={unit}
                    />
                </InputContainer>
                <InputContainer
                >
                    <Value
                        value={roundedNetExplosiveWeightInOunces}
                        unit={Units.NetExplosiveWeight.alternative}
                    />
                </InputContainer>
                <InputContainer
                    label={I18n.t('tntEquivalent')}
                >
                    <Value
                        value={roundedConvertedTntEquivalentInPounds}
                        unit={Units.TntEquivalent.metric}
                    />
                </InputContainer>
            </>
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TotalNetExplosiveWeight;

Component.propTypes = {
    errorText:           PropTypes.string,
    netExplosiveWeight:  PropTypes.number,
    tntEquivalentInGram: PropTypes.number,
    unitSystem:          PropTypes.string,
};

Component.defaultProps = {
    errorText:           null,
    netExplosiveWeight:  null,
    tntEquivalentInGram: null,
    unitSystem:          null,
};

Component.renderAffectingProps = [
    'errorText',
    'netExplosiveWeight',
    'unitSystem',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;