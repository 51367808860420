import classNames         from 'classnames';
import ComponentHelper    from '../../../helper/ComponentHelper';
import InputContainerType from './InputContainerType';
import PropTypes          from '../../PropTypes';
import React              from 'react';
import SelectionHelper    from '../../../helper/SelectionHelper';
import styles             from './styles.module.scss';

class InputContainer extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={styles.container}
            >
                <div
                    className={
                        classNames(
                            styles.labelContainer,
                            SelectionHelper.get(
                                this.props.theme,
                                {
                                    [InputContainerType.bigWhiteLabel]: styles.bigWhiteLabel,
                                },
                            ),
                            {
                                [styles.empty]: !this.props.label
                            }
                        )
                    }
                >
                    <span>
                        {this.props.label}
                    </span>
                </div>
                <div
                    className={styles.inputContainer}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const Component = InputContainer;

Component.propTypes = {
    label:    PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    theme:    PropTypes.oneOf([
        ...Object.keys(InputContainerType),
        '',
    ]),

};

Component.defaultProps = {
    label:    '',
    children: [],
    theme:    '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;