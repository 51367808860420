import ComponentHelper                from '../../../helper/ComponentHelper';
import PropTypes                      from '../../PropTypes';
import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import styles                         from './styles.module.scss';

class Link extends React.Component {
    render () {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                to={this.props.to}
                params={this.props.params}
                target={this.props.target}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Link;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    params:    PropTypes.object,
    target:    PropTypes.string,
    to:        PropTypes.string,
};

Component.defaultProps = {
    children:  [],
    className: styles.link,
    params:    {},
    target:    null,
    to:        '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;