import { initDB }       from 'react-indexed-db';
import { useIndexedDB } from 'react-indexed-db';
import packageJson      from '../../package.json';
import _                from 'lodash';

const StoreName = 'image';

const DBConfig = {
    name:             packageJson.name,
    version:          1,
    objectStoresMeta: [
        {
            store:       StoreName,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                {
                    name:    'image',
                    keypath: 'id',
                    options: { unique: false },
                },
            ],
        },
    ],
};

class ImageStorage {
    static useIndexedDB = {};

    static saveImage (image) {
        return this.useIndexedDB.add({ image });
    }

    static loadImage (id) {
        return this.useIndexedDB.getByID(id);
    }

    static deleteImage (id) {
        return this.useIndexedDB.deleteRecord(id);
    }

    static initializeStore () {
        initDB(DBConfig);

        this.useIndexedDB = useIndexedDB(StoreName);

        console.log('ImageStorage: this.useIndexedDB', this.useIndexedDB);
    }

    static clear () {
        return this.useIndexedDB.clear();
    }

    static dumpStore () {
        return this.useIndexedDB.getAll();
    }

    static loadImagesFromStoreByIds (imageIds, callback = _.noop, ...parameters) {
        const images = [];

        if (imageIds.length === 0) {
            callback(images);
        }

        imageIds.forEach(
            (
                imageId,
            ) => {
                ImageStorage.loadImage(imageId).then(
                    (imageData) => {
                        if (imageData) {
                            images.push(imageData);
                        }

                        if (images.length === imageIds.length) {
                            callback(images, ...parameters);
                        }
                    },
                ).catch(
                    (error) => {
                        console.log('ImageUpload: loadImage ', error);
                    },
                );
            },
        );
    }
}

export default ImageStorage;