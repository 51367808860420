import ComponentHelper         from '../../../helper/ComponentHelper';
import PropTypes               from '../../PropTypes';
import React                   from 'react';
import StatelessLoadingOverlay from '../../stateless/LoadingOverlay';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import { LoadingActions }      from '../../../store/actions/loading';

class LoadingOverlay extends React.Component {
    loadingOverlayClicked = () => {
        this.props.overlayClicked();
    };

    render () {
        return (
            <StatelessLoadingOverlay
                isLoading={this.props.isLoading}
                loadingOverlayClicked={this.loadingOverlayClicked}
                text={this.props.text}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = LoadingOverlay;

Component.propTypes = {
    isLoading: PropTypes.bool,
    text:      PropTypes.string,
};

Component.defaultProps = {
    isLoading: false,
    text:      '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        isLoading: state.loading.isLoading,
        text:      state.loading.text,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(LoadingActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);