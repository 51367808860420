import React from 'react';
import ComponentHelper from '../../../helper/ComponentHelper';
import styles from './styles.module.scss';
import I18n from 'i18next';

// @formatter:off
const values = [
//  | NEW g | NEW gr | MSD m | MSD ft  | Lung m | Lung ft | Eardrum m | Eardrum ft | Glass m | Glass ft |
    ['5',     '75',   '1.5',   '5.0',    '0.5',    '2.0',    '1.25',      '5.0',     '6.75',   '23.0',  ],
    ['10',    '150',  '2.0',   '6.0',    '0.6',    '2.0',    '1.5',       '5.0',     '7.25',   '24.0',  ],
    ['15',    '230',  '2.25',  '7.0',    '0.7',    '3.0',    '1.75',      '6.0',     '8.5',    '28.0',  ],
    ['20',    '300',  '2.5',   '8.0',    '0.7',    '3.0',    '1.75',      '6.0',     '9.25',   '31.0',  ],
    ['25',    '385',  '2.5',   '8.0',    '0.8',    '3.0',    '2.0',       '7.0',     '10.0',   '33.0',  ],
    ['30',    '450',  '2.75',  '9.0',    '0.8',    '3.0',    '2.0',       '7.0',     '10.5',   '35.0',  ],
    ['35',    '550',  '3.0',   '9.0',    '0.9',    '3.0',    '2.25',      '8.0',     '11.0',   '37.0',  ],
    ['40',    '600',  '3.0',   '10.0',   '0.9',    '3.0',    '2.25',      '8.0',     '11.5',   '38.0',  ],
    ['45',    '700',  '3.0',   '10.0',   '1.0',    '4.0',    '2.5',       '9.0',     '12.0',   '40.0',  ],
    ['50',    '800',  '3.25',  '11.0',   '1.0',    '4.0',    '2.5',       '9.0',     '12.5',   '42.0',  ],
    ['55',    '850',  '3.25',  '11.0',   '1.0',    '4.0',    '2.5',       '9.0',     '12.75',  '42.0',  ],
    ['60',    '900',  '3.5',   '11.0',   '1.1',    '4.0',    '2.5',       '9.0',     '13.25',  '44.0',  ],
    ['65',    '1000', '3.5',   '11.0',   '1.1',    '4.0',    '2.75',      '10.0',    '13.5',   '45.0',  ],
    ['75',    '1150', '3.75',  '12.0',   '1.1',    '4.0',    '2.75',      '10.0',    '14.25',  '47.0',  ],
    ['85',    '1300', '3.75',  '13.0',   '1.2',    '4.0',    '3.0',       '10.0',    '14.75',  '49.0',  ],
    ['100',   '1500', '4.0',   '13.0',   '1.3',    '5.0',    '3.0',       '10.0',    '15.5',   '51.0',  ],
    ['125',   '2000', '4.25',  '14.0',   '1.3',    '5.0',    '3.25',      '11.0',    '16.75',  '55.0',  ],
    ['150',   '2300', '4.5',   '15.0',   '1.4',    '5.0',    '3.5',       '12.0',    '18.0',   '60.0',  ],
    ['175',   '2700', '4.75',  '16.0',   '1.5',    '5.0',    '4.5',       '15.0',    '18.75',  '62.0',  ],
    ['200',   '3000', '5.0',   '16.0',   '1.6',    '6.0',    '5.0',       '17.0',    '19.75',  '65.0',  ],
    ['250',   '3800', '5.5',   '18.0',   '1.7',    '6.0',    '5.5',       '19.0',    '21.25',  '70.0',  ],
    ['300',   '4600', '5.75',  '19.0',   '1.8',    '6.0',    '6.0',       '20.0',    '22.5',   '74.0',  ],
    ['350',   '5400', '6.0',   '20.0',   '1.9',    '7.0',    '6.25',      '21.0',    '23.75',  '78.0',  ],
    ['400',   '6000', '6.25',  '21.0',   '2.0',    '7.0',    '6.5',       '22.0',    '24.75',  '82.0',  ],
    ['500',   '7700', '6.75',  '22.0',   '2.1',    '7.0',    '7.0',       '23.0',    '26.75',  '88.0',  ],
];


// @formatter:on
/**
 * Renders the net explosive weight **(NEW)** to minimum safe stacking distance **(MSD)** diagram
 */
class NEWtoMSDDiagram extends React.Component {
    renderDescriptionTableHeader = () => {
        return (
            <tr className={styles.descriptionHeader}>
                <th colSpan={2}>
                    {I18n.t('netExplosiveWeightShort')}
                </th>
                <th colSpan={2}>
                    {I18n.t('minimumSafeStackingDistanceShort')}
                    <br />
                    <span className={styles.highlightedTitle}>
                        {I18n.t('minimumSafeStackingDistanceColumnTitle')}
                    </span>
                </th>
                <th colSpan={2}>
                    {I18n.t('lungDamage')}
                    <br />
                    <span className={styles.highlightedTitle}>
                        {I18n.t('lungDamageColumnTitle')}
                    </span>
                </th>
                <th colSpan={2}>
                    {I18n.t('eardrumRupture')}
                    <br />
                    <span className={styles.highlightedTitle}>
                        {I18n.t('eardrumRuptureColumnTitle')}
                    </span>
                </th>
                <th colSpan={2}>
                    {I18n.t('glassBreakage')}
                    <br />
                    <span className={styles.highlightedTitle}>
                        {I18n.t('glassBreakageColumnTitle')}
                    </span>
                </th>
            </tr>
        );
    };

    renderTableHeader = () => {
        return (
            <tr>
                <th>
                    {I18n.t('gramShort')}
                </th>
                <th>
                    {I18n.t('grainShort')}
                </th>
                <th>
                    {I18n.t('meterShort')}
                </th>
                <th>
                    {I18n.t('feetShort')}
                </th>
                <th>
                    {I18n.t('meterShort')}
                </th>
                <th>
                    {I18n.t('feetShort')}
                </th>
                <th>
                    {I18n.t('meterShort')}
                </th>
                <th>
                    {I18n.t('feetShort')}
                </th>
                <th>
                    {I18n.t('meterShort')}
                </th>
                <th>
                    {I18n.t('feetShort')}
                </th>
            </tr>
        );
    };

    renderTableContent = () => {
        return (
            values.map(this.renderTableRow)
        );
    };

    renderTableRow = (row, rowIndex) => {
        const rowKey = 'new_to_msd_row_' + rowIndex;

        return (
            <tr
                key={rowKey}
            >
                {
                    row.map(
                        (column, columnIndex) => {
                            return this.renderTableColumn(column, columnIndex, rowIndex);
                        },
                    )
                }
            </tr>
        );
    };

    renderTableColumn = (column, columnIndex, rowIndex) => {
        const columnKey = 'new_to_msd_' + rowIndex + '_' + columnIndex;

        return (
            <td
                key={columnKey}
            >
                {column}
            </td>
        );
    };

    render () {
        return (
            <table>
                <thead>
                    {this.renderDescriptionTableHeader()}
                    {this.renderTableHeader()}
                </thead>
                <tbody className={styles.colored}>
                    {this.renderTableContent()}
                </tbody>
            </table>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = NEWtoMSDDiagram;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;