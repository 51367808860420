import _                      from 'lodash';
import ComponentHelper        from '../../../helper/ComponentHelper';
import FormGroup              from '../../stateless/FormGroup';
import I18n                   from 'i18next';
import InputContainer         from '../../stateless/InputContainer';
import OptionSelect           from '../../stateless/OptionSelect';
import PropTypes              from '../../PropTypes';
import React                  from 'react';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { SettingsActions }    from '../../../store/actions/settings';
import Units                  from '../../../constants/Units';

class ActiveUnitFormGroup extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    constructor () {
        super();

        this.state = {
            unitOptions: this.getUnitOptions(),
        };
    }

    onUnitChanged = (unit) => {
        this.props.setUnit({ unit });
    };

    getUnitOptions = () => {
        return _.map(
            Units.Systems,
            (unit, key) => {
                return {
                    label: I18n.t(unit.name),
                    value: key,
                };
            },
        );
    };

    render () {
        return (
            <FormGroup
                label={I18n.t('activeUnit')}
            >
                <InputContainer
                    label={I18n.t('activeUnit')}
                >
                    <OptionSelect
                        value={this.props.unit}
                        onValueChanged={this.onUnitChanged}
                        options={this.state.unitOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    }
}

const Component = ActiveUnitFormGroup;

Component.propTypes = {
    unit: PropTypes.string,
};

Component.defaultProps = {
    unit: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        unit: state.settings.unit,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SettingsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);