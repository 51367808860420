import { put }                       from 'redux-saga/effects';
import { select }                    from 'redux-saga/effects';
import { BreachReportsActions }      from '../actions/breachReports';
import { push }                      from 'connected-react-router';
import Routes                        from '../../constants/Routes';
import { ActiveBreachReportActions } from '../actions/activeBreachReport';
import _                             from 'lodash';
import ImageStorage                  from '../../helper/ImageStore';
import { AlertBoxActions }           from '../actions/alertBox';

const deleteBreachReport = function* ({ breachReportId }) {
    const breachReports = yield select(state => state.breachReports.breachReports);
    const breachReport  = _.get(breachReports, [breachReportId], null);

    if (breachReport) {
        const breachReportImages = breachReport.images;

        breachReportImages.forEach((id) => {
                ImageStorage.deleteImage(id);
            },
        );

        yield put(BreachReportsActions.deleteBreachReportSuccess({ breachReportId }));
    }
};

const openBreachReport = function* ({ breachReportId }) {
    const breachReports = yield select(state => state.breachReports.breachReports);
    const breachReport  = _.get(breachReports, [breachReportId], null);

    if (breachReport) {
        yield put(AlertBoxActions.clearAlerts());
        yield put(ActiveBreachReportActions.loadBreachReport({ breachReport }));
        yield put(push(Routes.newBreachReport));
    }
};

const saveBreachReport = function* ({ breachReport }) {
    yield put(BreachReportsActions.saveBreachReportSuccess());
    yield put(push(Routes.breachReports));
};

const shareBreachReport = function* ({ breachReportIds }) {
    yield put(push(Routes.printPreview));
};

const uploadFiles = function* ({ breachReport }) {
    yield put(BreachReportsActions.uploadFilesSuccess());
};

export default {
    deleteBreachReport,
    openBreachReport,
    saveBreachReport,
    shareBreachReport,
    uploadFiles
};
