import _ from 'lodash';
import AlertBoxManager from '../components/connected/AlertBoxManager';
import BreachReportList from '../components/connected/BreachReportList';
import BreachReportListHeader from '../components/stateless/BreachReportListHeader';
import BreachReportSearch from '../components/connected/BreachReportSearch';
import Colors from '../styles/colors.scss';
import Footer from '../components/stateless/Footer';
import Header from '../components/stateless/Header';
import I18n from 'i18next';
import ImageBackground from '../components/stateless/ImageBackground';
import PurchaseLicenseHint from '../components/connected/PurchaseLicenseHint';
import React, {Component} from 'react';
import styles from '../styles.module.scss';
import {ActiveBreachReportActions} from '../store/actions/activeBreachReport';
import {AlertBoxActions} from '../store/actions/alertBox';
import {bindActionCreators} from 'redux';
import {BreachReportsActions} from '../store/actions/breachReports';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            showPurchaseLicenseHint: false,
        };
    }

    newReportButtonPressed = () => {
        const breachReports = this.props.breachReports;

        if (this.props.isUnlocked) {
            this.props.newBreachReport();
        } else {
            this.props.clearAlerts();
            this.props.showErrorAlert({
                text: I18n.t('licenseRequiredError1'),
            });
            this.setState({
                showPurchaseLicenseHint: true,
            });
        }
    };

    render () {
        return (
            <div>
                <Header
                    title={I18n.t('breachReports')}
                />
                <ImageBackground
                    image={require('../assets/images/breachReportBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <AlertBoxManager />
                            {this.renderPurchaseLicenseHint()}
                            {this.renderSearch()}
                            <BreachReportListHeader
                                newReportPressed={this.newReportButtonPressed}
                            />
                            <BreachReportList />
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }

    renderPurchaseLicenseHint = () => {
        if (this.state.showPurchaseLicenseHint) {
            return (
                <PurchaseLicenseHint />
            );
        }

        return null;
    };

    renderSearch = () => {
        if (Object.keys(this.props.breachReports).length > 1) {
            return (
                <BreachReportSearch />
            );
        }

        return null;
    };
}

Screen.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        breachReports:      state.breachReports.breachReports,
        isUnlocked:         state.license.isUnlocked,
        shotNumberMode:     state.settings.shotNumberMode,
        shotNumberBaseDate: state.settings.shotNumberBaseDate,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    ActiveBreachReportActions,
    AlertBoxActions,
    BreachReportsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Screen));