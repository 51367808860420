import PropTypes                    from '../../PropTypes';
import React                        from 'react';
import { connect }                  from 'react-redux';
import ComponentHelper              from '../../../helper/ComponentHelper';
import StatelessPurchaseLicenseHint from '../../stateless/PurchaseLicenseHint';
import { bindActionCreators }       from 'redux';
import _                            from 'lodash';
import { LicenseActions }           from '../../../store/actions/license';

class PurchaseLicenseHint extends React.Component {
    buyLicenseButtonPressed = () => {
        this.props.buyLicenseKey();
    };

    render () {
        return (
            <StatelessPurchaseLicenseHint
                buyLicenseButtonPressed={this.buyLicenseButtonPressed}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = PurchaseLicenseHint;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    LicenseActions,
), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);