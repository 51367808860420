import _                       from 'lodash';
import React                   from 'react';
import { Component }           from 'react';
import ImageBackground         from '../components/stateless/ImageBackground';
import Header                  from '../components/stateless/Header';
import styles                  from '../styles.module.scss';
import Footer                  from '../components/stateless/Footer';
import I18n                    from 'i18next';
import HeadlineTheme           from '../components/stateless/Headline/HeadlineTheme';
import Headline                from '../components/stateless/Headline';
import OptionSelect            from '../components/stateless/OptionSelect';
import FormGroup               from '../components/stateless/FormGroup';
import TextInput               from '../components/stateless/TextInput';
import ColorButton             from '../components/stateless/ColorButton';
import Colors                  from '../styles/colors.scss';
import ColorButtonTheme        from '../components/stateless/ColorButton/ColorButtonTheme';
import InputContainer          from '../components/stateless/InputContainer';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import { SettingsActions }     from '../store/actions/settings';
import { BreacherInfoActions } from '../store/actions/breacherInfo';
import ActiveUnitFormGroup     from '../components/connected/ActiveUnitFormGroup';
import CountrySelectionInput   from '../components/stateless/CountrySelectionInput';
import { OperatorOptions }     from '../constants/Breach';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            name:     '',
            squadron: '',
        };
    }

    onTypeChanged = (breacherType) => {
        this.props.setBreacherType({ breacherType });
    };

    onFormSubmit = (event) => {
        event.preventDefault();
    };

    saveButtonPressed = () => {
        this.props.save();
    };

    onNameChanged = (event) => {
        const name = event.target.value;

        this.props.setName({ name });
    };

    onSquadronChanged = (event) => {
        const squadron = event.target.value;

        this.props.setSquadron({ squadron });
    };

    onCountryChanged = (selection) => {
        const country = selection.value;

        this.props.setCountry({ country });
    };

    render () {
        return (
            <div>
                <Header
                    title={I18n.t('breacherInfo')}
                />
                <ImageBackground
                    image={require('../assets/images/breacherInfoBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'60%'}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <Headline
                                theme={HeadlineTheme.whiteSmall}
                                title={I18n.t('breacherInfoFormTitle')}
                            />
                            <form onSubmit={this.onFormSubmit}>
                                <input
                                    type={'submit'}
                                    name={'submit'}
                                />
                                <FormGroup
                                    label={I18n.t('type')}
                                >
                                    <InputContainer
                                        label={I18n.t('type')}
                                    >
                                        <OptionSelect
                                            value={this.props.breacherType}
                                            onValueChanged={this.onTypeChanged}
                                            options={OperatorOptions}
                                        />
                                    </InputContainer>
                                </FormGroup>
                                <FormGroup
                                    label={I18n.t('country')}
                                >
                                    <CountrySelectionInput
                                        onValueChanged={this.onCountryChanged}
                                        value={this.props.country}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={I18n.t('name')}
                                >
                                    <InputContainer
                                        label={I18n.t('nameCallSign')}
                                    >
                                        <TextInput
                                            onChange={this.onNameChanged}
                                            value={this.props.name}
                                        />
                                    </InputContainer>
                                </FormGroup>
                                <FormGroup
                                    label={I18n.t('team')}
                                >
                                    <InputContainer
                                        label={I18n.t('squadron')}
                                    >
                                        <TextInput
                                            onChange={this.onSquadronChanged}
                                            value={this.props.squadron}
                                        />
                                    </InputContainer>
                                </FormGroup>
                                <ActiveUnitFormGroup />
                                <FormGroup
                                    theme={'submit'}
                                >
                                    <ColorButton
                                        onClick={this.saveButtonPressed}
                                        text={I18n.t('save')}
                                        theme={ColorButtonTheme.yellow}
                                    />
                                </FormGroup>
                            </form>
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }
}

Screen.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        unit:         state.settings.unit,
        breacherType: state.breacherInfo.breacherType,
        country:      state.breacherInfo.country,
        name:         state.breacherInfo.name,
        squadron:     state.breacherInfo.squadron,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    BreacherInfoActions,
    SettingsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);