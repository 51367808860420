import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import Icon            from '../Icon';
import ComponentHelper from '../../../helper/ComponentHelper';

class StartMenuItemBadge extends React.Component {
    render () {
        if (this.props.value) {
            return (
                <div className={styles.badgeContainer}>
                    <span className={styles.badgeCount}>
                        {this.props.value}
                    </span>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = StartMenuItemBadge;

Component.propTypes = {
    value: PropTypes.number,
};

Component.defaultProps = {
    value: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;