//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                      from 'immutability-helper';
import { ActiveBreachReportTypes } from '../actions/activeBreachReport';
import { BreachReportsTypes }      from '../actions/breachReports';
import { LicenseTypes }            from '../actions/license';
import { LoadingTypes }            from '../actions/loading';
import { LOCATION_CHANGE }         from 'connected-react-router';

const initialState = {
    clickCount: 0,
    isLoading:  false,
    level:      0,
};

const increaseClickCount = (state, action) => {
    return update(state, {
        clickCount: {
            $set: state.clickCount + 1,
        },
    });
};

const resetClickCount = (state, action) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
    });
};

const resetOverlay = (state, action) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: initialState.isLoading,
        },
        level:      {
            $set: initialState.level,
        },
    });
};

const decrease = (state, action) => {
    const clickCount = initialState.clickCount;
    const level      = Math.max(state.level - 1, 0);
    const isLoading  = !(
        level === 0
    );

    return update(state, {
        clickCount: {
            $set: clickCount,
        },
        isLoading:  {
            $set: isLoading,
        },
        level:      {
            $set: level,
        },
    });
};

const increase = (state, action) => {
    const levelIncrease = 1;

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: true,
        },
        level:      {
            $set: state.level + levelIncrease,
        },
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        // @formatter:off
        case LoadingTypes.RESET_OVERLAY:                                                   return resetOverlay(state, action);
        case LoadingTypes.OVERLAY_CLICKED:                                                 return increaseClickCount(state, action);

        case LOCATION_CHANGE:                                                              return resetClickCount(state, action);

        case BreachReportsTypes.SAVE_BREACH_REPORT_SUCCESS:
        case LicenseTypes.CHECK_LICENSE_KEY_FAILED:
        case LicenseTypes.CHECK_LICENSE_KEY_SUCCEEDED:
        case LicenseTypes.CLAIM_LICENSE_KEY_FAILED:
        case LicenseTypes.CLAIM_LICENSE_KEY_SUCCEEDED:
        case LicenseTypes.RELEASE_LICENSE_KEY_FAILED:
        case LicenseTypes.RELEASE_LICENSE_KEY_SUCCEEDED:    return decrease(state, action);

        case ActiveBreachReportTypes.SAVE_BREACH_REPORT:
        case LicenseTypes.CHECK_LICENSE_KEY:
        case LicenseTypes.CLAIM_LICENSE_KEY:
        case LicenseTypes.RELEASE_LICENSE_KEY:              return increase(state, action);
    }

    return state;
};