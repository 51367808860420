import _, {cloneDeep} from 'lodash';
import CheckboxInput from '../CheckboxInput';
import ColorButton from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n from 'i18next';
import moment from 'moment';
import PropTypes from '../../PropTypes';
import React from 'react';
import { isIOS, isChrome, isSafari } from 'react-device-detect';
import styles from './styles.module.scss';
import ImageStorage from '../../../helper/ImageStore';

class BreachReportList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            printMultiple: false,
        };
    }

    deletePressed = (breachReportId) => {
        this.props.deleteReportPressed(breachReportId);
    };

    duplicatePressed = (breachReport) => {
        this.props.duplicateReportPressed(breachReport);
    };

    showReportPressed = (breachReportId) => {
        this.props.showReportPressed(breachReportId);
    };

    sharePressed = (breachReportIds, title) => {
        if (breachReportIds.length) {
            this.props.shareReportPressed(breachReportIds, title);
        }
    };

    renderCheckbox = (report) => {
        return (
            <CheckboxInput
                onChange={(event) => this.props.printCheckboxChanged(event, report.id)}
                hidden={!this.state.printMultiple}
            />
        );
    };

    renderCreationDate = (creationDate) => {
        if (creationDate) {
            return moment(creationDate).format(I18n.t('reportListDateFormat'));
        }

        return '-';
    };

    renderReportTitle = (report) => {
        let reportDate = null;
        const dateUsed = report.dateUsed;
        const titleElements = [];
        const reportTitle = report.title;

        if (reportTitle) {
            titleElements.push(reportTitle);
        } else {
            const titleWithShotNumber = I18n.t('breachReportTitleWithShotNumber', {shotNumber: report.shotNumber});

            titleElements.push(titleWithShotNumber);
        }

        if (dateUsed) {
            reportDate = moment(dateUsed).format(I18n.t('reportListDateFormat'));

            titleElements.push(reportDate);
        }

        return titleElements.join(' - ');
    };

    downloadMobileFile = (blobFile) => {
        if (isIOS && isChrome) {
            const reader = new FileReader();
            reader.onload = () => {
                window.location.href = reader.result;
            }
            reader.readAsDataURL(blobFile);
        }
    }

    download = async (myData) => {
        let newData = cloneDeep(myData);
        if (newData.images.length) {
            newData.images = (
                await Promise.all(
                    newData.images.map((imageId) => {
                        return ImageStorage.loadImage(imageId);
                    })
                )
            ).map((image) => image.image);
        }
        const json = JSON.stringify(newData);
        const blob = new Blob([json], {type: 'application/json'});
        console.log(newData)
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = newData.title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloadMobileFile(blob)
    }

    onDownloadFile = (id) => {
        const {reports} = this.props;
        const myData = reports.find(report => report.id === id);
        this.download(myData);
    }


    downloadSelectedFiles = () => {
        const selectedFiles = this.props.reports.filter(item => this.props.breachReportsToPrint.includes(item.id));
        if (isSafari) {
            let i = 0;

            // timeout is needed for safari to download multiple files
            const interval = setInterval(() => {
                this.download(selectedFiles[i]);
                i++;

                if (i >= selectedFiles.length) {
                    clearInterval(interval);
                }
            }, 200);
        } else {
            selectedFiles.map(file => {
                this.download(file);
            });
        }

    }

    renderReport = (report) => {
        return (
            <div
                key={report.id}
                className={styles.reportContainer}
            >
                <div className={styles.reportContainerCheckbox}>
                    {this.renderCheckbox(report)}
                </div>
                <div className={styles.reportContent}>
                    <span className={styles.reportDate}>
                        {
                            this.renderCreationDate(report.creationDate)
                        }
                    </span>
                    <h2>
                        {this.renderReportTitle(report)}
                    </h2>
                </div>
                <div className={styles.reportOptions}>
                    <ColorButton
                        theme={ColorButtonTheme.graySmallFullWidth}
                        text={I18n.t('edit')}
                        onClick={() => {
                            this.showReportPressed(report.id);
                        }}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.graySmallFullWidth}
                        text={I18n.t('print')}
                        onClick={() => {
                            this.sharePressed([report.id], report.title);
                        }}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.graySmallFullWidth}
                        text={I18n.t('delete')}
                        onClick={() => {
                            this.deletePressed(report.id);
                        }}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.graySmallFullWidth}
                        text={I18n.t('duplicate')}
                        onClick={() => {
                            this.duplicatePressed(report);
                        }}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.graySmallFullWidth}
                        text={'Download'}
                        onClick={() => {
                            this.onDownloadFile(report.id)
                        }}
                    />
                </div>
            </div>
        );
    };

    printMultipleDisabledClicked = () => {
        this.setPrintMultiple(false);
    };

    printMultipleEnableClicked = () => {
        this.setPrintMultiple(true);
    };

    render() {
        return (
            <div>
                <div className={styles.printAllContainer}>
                    {this.renderPrintMultipleButton()}
                </div>
                {this.props.reports.map(this.renderReport)}
            </div>
        );
    }

    renderPrintMultipleButton = () => {
        if (this.props.reports.length > 1) {
            if (!this.state.printMultiple) {
                return (
                    <ColorButton
                        theme={ColorButtonTheme.yellow}
                        text={I18n.t('selectMultiple')}
                        onClick={this.printMultipleEnableClicked}
                    />
                );
            }

            if (!this.props.breachReportsToPrint.length) {
                return (
                    <div>
                        <ColorButton
                            theme={ColorButtonTheme.yellow}
                            text={I18n.t('cancelPrint')}
                            onClick={this.printMultipleDisabledClicked}
                        />
                        <ColorButton
                            theme={ColorButtonTheme.yellow}
                            text={'Cancel download'}
                            onClick={this.printMultipleDisabledClicked}
                        />
                    </div>
                );
            }

            return (
                <div>
                    <ColorButton
                        theme={ColorButtonTheme.yellow}
                        text={I18n.t('printSelected')}
                        onClick={() => this.sharePressed(this.props.breachReportsToPrint)}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.yellow}
                        text={'Download selected'}
                        onClick={() => this.downloadSelectedFiles()}
                    />
                </div>
            );
        }

        return null;
    };

    setPrintMultiple = (state) => {
        this.setState({printMultiple: state});
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreachReportList;

Component.propTypes = {
    breachReportsToPrint: PropTypes.array,
    deleteReportPressed: PropTypes.func,
    duplicateReportPressed: PropTypes.func,
    printCheckboxChanged: PropTypes.func,
    reports: PropTypes.array,
    shareReportPressed: PropTypes.func,
    showReportPressed: PropTypes.func,
};

Component.defaultProps = {
    breachReportsToPrint: [],
    deleteReportPressed: _.noop,
    duplicateReportPressed: _.noop,
    printCheckboxChanged: _.noop,
    reports: [],
    shareReportPressed: _.noop,
    showReportPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'printMultiple',
];

export default Component;
