import './language/Language';
import '@babel/polyfill';
import * as serviceWorker   from './serviceWorker';
import CacheInvalidator     from './components/stateless/CacheInvalidator';
import LoadingOverlay       from './components/connected/LoadingOverlay';
import React                from 'react';
import ReactDOM             from 'react-dom';
import Routes               from './constants/Routes';
import store                from './store';
import styles               from './styles.module.scss';
import { ConnectedRouter }  from 'connected-react-router';
import { history }          from './store/index';
import { Provider }         from 'react-redux';
import { Route }            from 'react-router';
import { Switch }           from 'react-router';
import { registerLocale }   from 'react-datepicker';
import { setDefaultLocale } from 'react-datepicker';
import de                   from 'date-fns/locale/de';
import ReactGA              from 'react-ga';
import ImageStorage         from './helper/ImageStore';
import update               from 'immutability-helper';
import InstallWebAppBanner  from './components/connected/InstallWebAppBanner';
import I18n                 from 'i18next';
import Environment          from './helper/Environment';

// Screens
import BreacherInfo            from './screens/BreacherInfo';
import BreachReports           from './screens/BreachReports';
import CustomCalculation       from './screens/CustomCalculation';
import DataProtection          from './screens/DataProtection';
import Home                    from './screens/Home';
import Imprint                 from './screens/Imprint';
import NewBreachReport         from './screens/NewBreachReport';
import NEWtoMSDDiagram         from './screens/NEWtoMSDDiagram';
import PrintPreview            from './screens/PrintPreview';
import Settings                from './screens/Settings';
import NotFound                from './screens/NotFound';
import PreCalculatedBreachpops from './screens/PreCalculatedBreachpops';

registerLocale('de', de);
setDefaultLocale('de');

if (Environment.isProduction()) {
    ReactGA.initialize('UA-168546311-1');
}

ImageStorage.initializeStore();

update.extend('$increaseBy', (amount, value) => {
    if (!value) {
        value = 1;
    }

    return value + amount;
});

ReactDOM.render(
    <Provider store={store()}>
        <CacheInvalidator />
        {/*
            If you don't want to automatically clear the browsers cache and update the
            application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
            to ask the user if he wants to update.
        */}
        <LoadingOverlay />
        <ConnectedRouter history={history}>
            <InstallWebAppBanner
                installableText={I18n.t('installableBannerText')}
                bookmarkText={I18n.t('bookmarkBannerText')}
            />
            <div className={styles.appContent}>
                <Switch>
                    <Route
                        exact
                        path={Routes.home}
                        component={Home}
                    />
                    <Route
                        exact
                        path={Routes.imprint}
                        component={Imprint}
                    />
                    <Route
                        exact
                        path={Routes.dataProtection}
                        component={DataProtection}
                    />
                    <Route
                        exact
                        path={Routes.breacherInfo}
                        component={BreacherInfo}
                    />
                    <Route
                        exact
                        path={Routes.newBreachReport}
                        component={NewBreachReport}
                    />
                    <Route
                        exact
                        path={Routes.customCalculation}
                        component={CustomCalculation}
                    />
                    <Route
                        exact
                        path={Routes.preCalculatedBreachPops}
                        component={PreCalculatedBreachpops}
                    />
                    <Route
                        exact
                        path={Routes.breachReports}
                        component={BreachReports}
                    />
                    <Route
                        exact
                        path={Routes.newToMSDDiagram}
                        component={NEWtoMSDDiagram}
                    />
                    <Route
                        exact
                        path={Routes.printPreview}
                        component={PrintPreview}
                    />
                    <Route
                        exact
                        path={Routes.settings}
                        component={Settings}
                    />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'),
);

serviceWorker.register();