import { makeActionCreator } from '../../helper/Store';

export const CustomCalculationTypes = {
    RECALCULATE_VALUES:         'CustomCalculation/RECALCULATE_VALUES',
    SAVE_AS_BREACH_REPORT:      'CustomCalculation/SAVE_AS_BREACH_REPORT',
    SET_BOOSTERS:               'CustomCalculation/SET_BOOSTERS',
    SET_EXPLOSIVES:             'CustomCalculation/SET_EXPLOSIVES',
    SET_INSIDE:                 'CustomCalculation/SET_INSIDE',
    SET_INTERNAL_PSI:           'CustomCalculation/SET_INTERNAL_PSI',
    SET_NET_EXPLOSIVE_WEIGHT:   'CustomCalculation/SET_NET_EXPLOSIVE_WEIGHT',
    SET_PRIMING_COUNT:          'CustomCalculation/SET_PRIMING_COUNT',
    SET_ROOM_HEIGHT:            'CustomCalculation/SET_ROOM_HEIGHT',
    SET_ROOM_LENGTH:            'CustomCalculation/SET_ROOM_LENGTH',
    SET_ROOM_VOLUME:            'CustomCalculation/SET_ROOM_VOLUME',
    SET_ROOM_WIDTH:             'CustomCalculation/SET_ROOM_WIDTH',
    SET_SAFE_STACKING_DISTANCE: 'CustomCalculation/SET_SAFE_STACKING_DISTANCE',
    SET_UNIT_SYSTEM:            'CustomCalculation/SET_UNIT_SYSTEM',
    UPDATE_ROOM_HEIGHT:         'CustomCalculation/UPDATE_ROOM_HEIGHT',
    UPDATE_ROOM_LENGTH:         'CustomCalculation/UPDATE_ROOM_LENGTH',
    UPDATE_ROOM_WIDTH:          'CustomCalculation/UPDATE_ROOM_WIDTH',
};

const recalculateValues = makeActionCreator(
    CustomCalculationTypes.RECALCULATE_VALUES,
);

const saveAsBreachReport = makeActionCreator(
    CustomCalculationTypes.SAVE_AS_BREACH_REPORT,
);

const setBoosters = makeActionCreator(
    CustomCalculationTypes.SET_BOOSTERS,
    {
        boosters: null,
    },
);

const setExplosives = makeActionCreator(
    CustomCalculationTypes.SET_EXPLOSIVES,
    {
        explosives: null,
    },
);

const setInside = makeActionCreator(
    CustomCalculationTypes.SET_INSIDE,
    {
        inside: null,
    },
);

const setInternalPSI = makeActionCreator(
    CustomCalculationTypes.SET_INTERNAL_PSI,
    {
        internalPSI: null,
    },
);

const setNetExplosiveWeight = makeActionCreator(
    CustomCalculationTypes.SET_NET_EXPLOSIVE_WEIGHT,
    {
        netExplosiveWeight:  null,
        tntEquivalentInGram: null,
    },
);

const setPrimingCount = makeActionCreator(
    CustomCalculationTypes.SET_PRIMING_COUNT,
    {
        primingCount: null,
    },
);

const setRoomHeight = makeActionCreator(
    CustomCalculationTypes.SET_ROOM_HEIGHT,
    {
        roomHeight: null,
    },
);

const setRoomLength = makeActionCreator(
    CustomCalculationTypes.SET_ROOM_LENGTH,
    {
        roomLength: null,
    },
);

const setRoomVolume = makeActionCreator(
    CustomCalculationTypes.SET_ROOM_VOLUME,
    {
        setRoomVolume: null,
    },
);

const setRoomWidth = makeActionCreator(
    CustomCalculationTypes.SET_ROOM_WIDTH,
    {
        roomWidth: null,
    },
);

const setSafeStackingDistance = makeActionCreator(
    CustomCalculationTypes.SET_SAFE_STACKING_DISTANCE,
    {
        safeStackingDistance: null,
    },
);

const setUnitSystem = makeActionCreator(
    CustomCalculationTypes.SET_UNIT_SYSTEM,
    {
        unitSystem: null,
    },
);

const updateRoomHeight = makeActionCreator(
    CustomCalculationTypes.UPDATE_ROOM_HEIGHT,
    {
        roomHeight: null,
    },
);

const updateRoomLength = makeActionCreator(
    CustomCalculationTypes.UPDATE_ROOM_LENGTH,
    {
        roomLength: null,
    },
);

const updateRoomWidth = makeActionCreator(
    CustomCalculationTypes.UPDATE_ROOM_WIDTH,
    {
        roomWidth: null,
    },
);

export const CustomCalculationActions = {
    recalculateValues,
    saveAsBreachReport,
    setBoosters,
    setExplosives,
    setInside,
    setInternalPSI,
    setNetExplosiveWeight,
    setPrimingCount,
    setRoomHeight,
    setRoomLength,
    setRoomVolume,
    setRoomWidth,
    setSafeStackingDistance,
    setUnitSystem,
    updateRoomHeight,
    updateRoomLength,
    updateRoomWidth,
};