import _ from 'lodash';
import convert from 'convert-units/lib';
import ExplosiveCalculator from '../helper/ExplosiveCalculator';
import I18n from 'i18next';
import ImagePreviewItem from '../components/stateless/ImagePreviewItem';
import ImageStorage from '../helper/ImageStore';
import moment from 'moment';
import NotSafeToStackInRoomAlert from '../components/stateless/NotSafeToStackInRoomAlert';
import PageTitleHelper from '../helper/PageTitle';
import PrintHeadline from '../components/stateless/PrintHeadline';
import PrintValue from '../components/stateless/PrintValue';
import PrintValueType from '../components/stateless/PrintValue/PrintValueType';
import React, {Component} from 'react';
import styles from '../styles.module.scss';
import UnitConverter from '../helper/UnitConverter';
import Units from '../constants/Units';
import {bindActionCreators} from 'redux';
import {Boosters, Explosives, UiValue} from '../constants/Explosives';
import {BreacherInfoActions} from '../store/actions/breacherInfo';
import {
    BreachResultMethodOfAttachmentOptions,
    BreachResultOptions,
    BreachResultTargetOptions,
    InsideOptions,
    LightOptions,
    OperatorOptions,
    PrimingOptions,
    TypeOfBreachOptions
} from '../constants/Breach';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            images:       {},
            imageIds:     {},
            loadComplete: false,
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        const images = _.compact(nextProps.breachReportIds.map((breachReportId) => {
            const images = nextProps.breachReports[breachReportId].images;

            if (images.length) {
                return {
                    [breachReportId]: images,
                };
            }

            return false;
        }));

        if (images !== prevState.imageIds) {
            return {
                imageIds: images,
            };
        }

        return null;
    }

    loadImagesFromStore = () => {
        const imageIds = this.state.imageIds;

        if (imageIds.length > 0) {
            imageIds.map((object, index) => {
                const reportId = Object.keys(object)[0];

                ImageStorage.loadImagesFromStoreByIds(
                    object[reportId],
                    this.loadImagesComplete,
                    reportId,
                    index === imageIds.length - 1,
                );
            });
        } else {
            this.loadImagesComplete([], null, true);
        }
    };

    loadImagesComplete = (loadedImages, reportId, loadComplete) => {
        const images = this.state.images;

        if (reportId) {
            images[reportId] = loadedImages;
        }

        this.setState({
            images,
            loadComplete,
        });
    };

    componentDidMount () {
        this.loadImagesFromStore();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (!_.isEqual(prevState.imageIds, this.state.imageIds)) {
            this.loadImagesFromStore();
        }

        if (this.state.loadComplete) {
            window.print();
        }
    }

    getFormattedDateTime (dateTime) {
        if (dateTime) {
            return moment(dateTime).format(I18n.t('printDateFormat'));
        }

        return '-';
    }

    renderOperatorGroup (breachReport) {
        const dateBuild = this.getFormattedDateTime(breachReport.creationDate);
        const dateUsed  = this.getFormattedDateTime(breachReport.dateUsed);

        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('operator')}
                        </h3>
                    </div>
                </div>

                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            label={I18n.t('operator')}
                            value={this.getLabelOfOptionSelect(OperatorOptions, breachReport.operator)}
                        />
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('country')}
                            value={breachReport.country}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('nameCallSign')}
                            value={breachReport.name}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('team')}
                            value={breachReport.team}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('title')}
                            value={breachReport.title}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('shotNumber')}
                            value={breachReport.shotNumber}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('dateBuild')}
                            value={dateBuild}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('dateUsed')}
                            value={dateUsed}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('typeOfCharge')}
                            value={breachReport.typeOfCharge}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getValueOrPlaceholder (value) {
        if (value) {
            return value;
        }

        return '-';
    }

    getLabelOfOptionSelect (options, value) {
        const matchedOption = _.find(options, { value });

        if (matchedOption) {
            return matchedOption.label;
        }

        return '-';
    }

    renderLocationGroup (breachReport) {
        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('location')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('location')}
                            value={this.getValueOrPlaceholder(breachReport.location)}
                        />
                        <PrintValue
                            label={I18n.t('type')}
                            value={this.getLabelOfOptionSelect(TypeOfBreachOptions, breachReport.typeOfBreach)}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            label={I18n.t('light')}
                            value={this.getLabelOfOptionSelect(LightOptions, breachReport.light)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderExplosive = (explosive, index) => {
        const explosiveName = _.get(
            Explosives,
            [
                explosive.explosiveType,
                'name',
            ],
        );

        return (
            <div className={styles.printValueContainer}>
                <PrintValue
                    key={index}
                    theme={PrintValueType.grayBackground}
                    label={I18n.t('explosive')}
                    value={explosiveName}
                />
                {this.renderExplosiveValues(explosive)}
            </div>
        );
    };

    renderBooster = (booster) => {
        const boosterName = _.get(
            Boosters,
            [
                booster.boosterType,
                'name',
            ],
        );

        return (
            <div>
                <PrintValue
                    theme={PrintValueType.grayBackground}
                    label={I18n.t('booster')}
                    value={boosterName}
                />
                {this.renderExplosiveValues(booster)}
            </div>
        );
    };

    renderExplosivesGroup (breachReport) {
        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('explosives')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        {
                            _.map(
                                breachReport.explosives,
                                this.renderExplosive,
                            )
                        }
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <div className={styles.printValueContainer}>
                            {
                                _.map(
                                    breachReport.boosters,
                                    this.renderBooster,
                                )
                            }
                        </div>
                        <PrintValue
                            label={I18n.t('priming')}
                            value={this.getLabelOfOptionSelect(PrimingOptions, breachReport.primingCount)}
                        />
                    </div>

                </div>
            </div>
        );
    }

    renderExplosiveValues = (explosive) => {
        const explosiveType          = _.get(explosive, 'explosiveType', null);
        const explosiveConfiguration = Explosives[explosiveType];

        if (!explosiveConfiguration) {
            return null;
        }

        const explosiveValues = explosiveConfiguration.values;
        const valueKeys       = _.filter(_.keys(explosiveValues), (key) => {
            return explosiveValues[key] === UiValue;
        });

        return _.map(
            valueKeys,
            (valueKey) => {
                return this.renderExplosiveValue(valueKey, explosive);
            },
        );
    };

    renderExplosiveValue = (valueKey, explosive) => {
        const unit                = Units.Explosives[this.props.unitSystem][valueKey];
        const baseUnit            = Units.Explosives[Units.Systems.metric.name][valueKey];
        const value               = explosive[valueKey];
        const valueTranslationKey = 'explosiveValue' + _.upperFirst(valueKey);
        let convertedValue        = 0;

        if (!isNaN(value)) {
            convertedValue = convert(value).from(baseUnit).to(unit).toFixed(2);
        }

        return (
            <PrintValue
                theme={PrintValueType.grayBackground}
                label={I18n.t(valueTranslationKey)}
                value={convertedValue}
                unit={unit}
            />
        );
    };

    renderNetExplosiveWeightGroup = (breachReport) => {
        const imperialNetExplosiveWeight    = convert(breachReport.netExplosiveWeight)
            .from(Units.NetExplosiveWeight.metric)
            .to(Units.NetExplosiveWeight.imperial)
        ;
        const alternativeNetExplosiveWeight = convert(breachReport.netExplosiveWeight)
            .from(Units.NetExplosiveWeight.metric)
            .to(Units.NetExplosiveWeight.alternative)
        ;
        const metricNetExplosiveWeight      = breachReport.netExplosiveWeight;
        let tntEquivalentInGram             = breachReport.tntEquivalentInGram;

        if (!tntEquivalentInGram) {
            tntEquivalentInGram = ExplosiveCalculator.calculateTotalTNTEquivalentInGram(
                breachReport.explosives,
                breachReport.primingCount,
                breachReport.boosters,
            );
        }

        const convertedTntEquivalentInPounds        = convert(tntEquivalentInGram).from('g').to('lb');
        const roundedConvertedTntEquivalentInPounds = convertedTntEquivalentInPounds.toFixed(3);

        const imperialSafeDistance        = convert(breachReport.safeStackingDistance)
            .from(Units.Distance.metric)
            .to(Units.Distance.imperial)
        ;
        const metricSafeDistance          = breachReport.safeStackingDistance;
        const roundedMetricSafeDistance   = ExplosiveCalculator.roundUpToOneAfterDecimal(imperialSafeDistance);
        const roundedImperialSafeDistance = ExplosiveCalculator.roundUpToOneAfterDecimal(metricSafeDistance);

        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('netExplosiveWeightShort')}
                        </h3>
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('minimumSafeStackingDistance')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('imperial')}
                            value={imperialNetExplosiveWeight.toFixed(2)}
                            unit={Units.NetExplosiveWeight.imperial}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            value={alternativeNetExplosiveWeight.toFixed(2)}
                            unit={Units.NetExplosiveWeight.alternative}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('metric')}
                            value={metricNetExplosiveWeight.toFixed(2)}
                            unit={Units.NetExplosiveWeight.metric}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('tntEquivalent')}
                            value={roundedConvertedTntEquivalentInPounds}
                            unit={Units.TntEquivalent.metric}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('imperial')}
                            value={roundedMetricSafeDistance.toFixed(2)}
                            unit={Units.Distance.imperial}
                        />
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('metric')}
                            value={roundedImperialSafeDistance.toFixed(2)}
                            unit={Units.Distance.metric}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderRoomVolume = (breachReport) => {
        if (breachReport.inside) {
            const unitSystem = this.props.unitSystem;
            const unit       = Units.Room[unitSystem];
            let roomVolume   = UnitConverter.calculateRoomVolumeInCurrentUnit(breachReport.room, unitSystem);
            const value      = roomVolume ? roomVolume.toFixed(2) : '-';

            return (
                <PrintValue
                    label={I18n.t('roomVolumeLabel', { unit })}
                    value={value}
                />
            );
        }

        return null;
    };

    renderInteriorGroup = (breachReport) => {
        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('interior')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('inside')}
                            value={this.getLabelOfOptionSelect(InsideOptions, breachReport.inside)}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        {
                            this.renderRoomVolume(breachReport)
                        }
                    </div>
                </div>
                {
                    this.renderInternalPressureGroup(breachReport)
                }
            </div>
        );
    };

    renderInternalPressureGroup = (breachReport) => {
        const internalPSI     = breachReport.internalPSI;
        const unitSystem      = this.props.unitSystem;
        const userUnit        = Units.InternalPressure[unitSystem];
        const valueInUserUnit = convert(internalPSI).from(Units.InternalPressureBase).to(userUnit);
        const value           = valueInUserUnit ? valueInUserUnit.toFixed(2) : '-';

        if (breachReport.inside) {
            return [
                (
                    <div className={styles.printPreviewValuesRow}>
                        <div
                            className={styles.printPreviewColumn}
                        >
                            <PrintValue
                                theme={PrintValueType.grayBackground}
                                label={I18n.t('internalPressure')}
                                value={value}
                                unit={userUnit}
                            />
                        </div>
                        <div
                            className={styles.printPreviewColumn}
                        >
                        </div>
                    </div>
                ),
                (
                    <div className={styles.printPreviewValuesRow}>
                        <NotSafeToStackInRoomAlert
                            internalPressureInPSI={internalPSI}
                        />
                    </div>
                ),
            ];
        }

        return null;
    };

    renderMethodOfAttachmentGroup = (breachReport) => {
        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('methodOfAttachment')}
                        </h3>
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('target')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            multiline={true}
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('option')}
                            value={this.getLabelOfOptionSelect(
                                BreachResultMethodOfAttachmentOptions,
                                breachReport.methodOfAttachmentOption,
                            )}
                        />
                        <PrintValue
                            multiline={true}
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('description')}
                            value={breachReport.methodOfAttachment}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            multiline={true}
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('option')}
                            value={this.getLabelOfOptionSelect(
                                BreachResultTargetOptions,
                                breachReport.targetOption,
                            )}
                        />
                        <PrintValue
                            multiline={true}
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('description')}
                            value={breachReport.targetDescription}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderResultAndCommentGroup = (breachReport) => {
        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('result')}
                        </h3>
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('comments')}
                        </h3>
                    </div>
                </div>
                <div className={styles.printPreviewValuesRow}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            label={I18n.t('result')}
                            value={this.getLabelOfOptionSelect(BreachResultOptions, breachReport.breachResult)}
                        />
                    </div>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <PrintValue
                            multiline={true}
                            theme={PrintValueType.grayBackground}
                            label={I18n.t('comments')}
                            value={breachReport.comments}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderImagesGroup = (breachReport) => {
        const images = this.state.images[breachReport.id];

        if (!images) {
            return;
        }

        return (
            <div className={styles.printValueContainer}>
                <div className={styles.printPreviewValuesRowPageBreak}>
                    <div
                        className={styles.printPreviewColumn}
                    >
                        <h3>
                            {I18n.t('photos')}
                        </h3>
                        {this.renderImages(images)}
                    </div>
                </div>
            </div>
        );
    };

    renderImages (images) {
        return images.map(
            (image) => {
                return this.renderImage(image);
            },
        );
    };

    renderImage (imageData) {
        return (
            <ImagePreviewItem
                image={imageData.image}
            />
        );
    }

    render () {
        const reportsToPrint = [];
        const breachReports  = this.props.breachReports;

        this.props.breachReportIds.forEach((breachReportId) => {
            const breachReport = breachReports[breachReportId];

            reportsToPrint.push(this.renderBreachReport(breachReport, this.props.breachReportIds.length));
        });

        return reportsToPrint;
    }

    renderBreachReport = (breachReport, breachReportCount) => {
        const breacherInfo = this.props.breacherInfo;
        const title        = breachReportCount > 1 ? 'breachReportPrintMultipleTitle' : 'breachReportPrintTitle';

        return (
            <div className={styles.printPreview}>
                <Helmet>
                    <title>
                        {
                            PageTitleHelper.getPageTitle(I18n.t(
                                title,
                                {
                                    shotNumber: breachReport.shotNumber,
                                },
                            ))
                        }
                    </title>
                </Helmet>
                <PrintHeadline breacherInfo={breacherInfo} />
                {this.renderOperatorGroup(breachReport)}
                {this.renderLocationGroup(breachReport)}
                {this.renderExplosivesGroup(breachReport)}
                {this.renderNetExplosiveWeightGroup(breachReport)}
                {this.renderInteriorGroup(breachReport)}
                {this.renderMethodOfAttachmentGroup(breachReport)}
                {this.renderResultAndCommentGroup(breachReport)}
                {this.renderImagesGroup(breachReport)}
            </div>
        );
    };
}

const mapStateToProps = state => (
    {
        breachReportIds: state.breachReports.breachReportsToPrint,
        breachReports:   state.breachReports.breachReports,
        breacherInfo:    state.breacherInfo,
        unitSystem:      state.settings.unit,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    BreacherInfoActions,
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);