import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import classNames      from 'classnames';
import SelectionHelper from '../../../helper/SelectionHelper';
import LongTextTheme   from './LongTextTheme';

class LongText extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.text,
            SelectionHelper.get(
                this.props.theme,
                {
                    [LongTextTheme.white]: styles.white,
                },
            ),
            {
                [styles.noPaddingBottom]: this.props.noPaddingBottom,
            },
        );

        return className;
    };

    render () {
        const finalClassName = this.getClassName();

        return (
            <div className={finalClassName}>
                {this.props.children}
            </div>
        );
    }
}

const Component = LongText;

Component.propTypes = {
    noPaddingBottom: PropTypes.bool,
    theme:           PropTypes.oneOf(Object.values(LongTextTheme)),
};

Component.defaultProps = {
    noPaddingBottom: false,
    theme:           LongTextTheme.white,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;