import _               from 'lodash';
import Colors          from '../../../styles/colors.scss';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import Select          from 'react-select';
import SelectTheme     from './SelectTheme';
import styles          from './styles.module.scss';

class SelectInternal extends React.Component {
    getCustomComponents = () => {
        const components = {};

        components.DropdownIndicator = () => {
            return (
                <div
                    className={styles.dropDownIndicator}
                />
            );
        };

        return components;
    };

    getCustomStyles = () => {
        const customStyles = {
            container:          (provided, state) => {
                return {
                    ...provided,
                    flex:         1,
                    height:       40,
                    marginTop:    6,
                    marginRight:  5,
                    marginBottom: 6,
                    marginLeft:   5,
                    userSelect:   'none',
                };
            },
            control:            (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: Colors.colorBlueDarkGray,
                    border:          0,
                    height:          40,
                    borderRadius:    6,
                    color:           Colors.colorLightGray,
                };
            },
            indicatorSeparator: (provided, state) => {
                return {
                    ...provided,
                    display: 'none',
                };
            },
            menu:               (provided, state) => {
                return {
                    ...provided,
                    padding:         0,
                    borderWidth:     1,
                    backgroundColor: Colors.colorDarkGray,
                    borderStyle:     'solid',
                    borderRadius:    6,
                    borderColor:     Colors.colorLightGray,
                    overflow:        'hidden',
                };
            },
            menuList:           (provided, state) => {
                return {
                    ...provided,
                    padding:         0,
                    borderWidth:     0,
                    backgroundColor: Colors.colorDarkGray,
                    overflow:        'auto',
                };
            },
            option:             (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: (
                                         state.isSelected || state.isFocused ?
                                             Colors.colorBlack :
                                             Colors.colorDarkGray
                                     ) + ' !important',

                    color:    Colors.colorLightGray,
                    fontSize: 14,
                };
            },
            singleValue:        (provided, state) => {
                return {
                    ...provided,
                    color: Colors.colorLightGray2,
                };
            },
            input:              (provided, state) => {
                return {
                    ...provided,
                    color: Colors.colorLightGray2,
                };
            },
        };

        return customStyles;
    };

    getNoOptionsMessage = () => {
        return I18n.t('selectNoSearchResults');
    };

    render () {
        const customComponents = this.getCustomComponents();
        const customStyles     = this.getCustomStyles();

        return (
            <Select
                components={customComponents}
                noOptionsMessage={this.getNoOptionsMessage}
                onChange={this.props.onChange}
                options={this.props.options}
                placeholder={this.props.placeholder}
                ref={this.props.innerRef}
                styles={customStyles}
                className={styles.container}
                value={this.props.value}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = SelectInternal;

Component.propTypes = {
    innerRef:    PropTypes.func,
    onChange:    PropTypes.func,
    options:     PropTypes.array,
    placeholder: PropTypes.string,
    theme:       PropTypes.oneOf(Object.values(SelectTheme)),
    value:       PropTypes.any,
};

Component.defaultProps = {
    innerRef:    _.noop,
    onChange:    _.noop,
    options:     [],
    placeholder: null,
    theme:       SelectTheme.default,
    value:       null,
};

Component.renderAffectingProps = [
    'hideArrow',
    'iconType',
    'placeholder',
    'options',
    'value',
];

Component.renderAffectingStates = [];

export default SelectInternal;