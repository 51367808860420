import _                            from 'lodash';
import ActiveUnitFormGroup          from '../components/connected/ActiveUnitFormGroup';
import AlertBoxManager              from '../components/connected/AlertBoxManager';
import BoostersList                 from '../components/stateless/BoostersList';
import ColorButton                  from '../components/stateless/ColorButton';
import ColorButtonTheme             from '../components/stateless/ColorButton/ColorButtonTheme';
import Colors                       from '../styles/colors.scss';
import ExplosiveCalculator          from '../helper/ExplosiveCalculator';
import ExplosivesList               from '../components/stateless/ExplosivesList';
import Footer                       from '../components/stateless/Footer';
import FormGroup                    from '../components/stateless/FormGroup';
import FormGroupTheme               from '../components/stateless/FormGroup/FormGroupTheme';
import Header                       from '../components/stateless/Header';
import I18n                         from 'i18next';
import ImageBackground              from '../components/stateless/ImageBackground';
import InputContainer               from '../components/stateless/InputContainer';
import InternalPressureVolumeResult from '../components/stateless/InternalPressureVolumeResult';
import MinimumSafeToStackDistance   from '../components/stateless/MinimumSafeToStackDistance';
import OptionSelect                 from '../components/stateless/OptionSelect';
import PrimingSelectionInput        from '../components/stateless/PrimingSelectionInput';
import PurchaseLicenseHint          from '../components/connected/PurchaseLicenseHint';
import React                        from 'react';
import RoomSizeInput                from '../components/stateless/RoomSizeInput';
import RoomVolumeResult             from '../components/stateless/RoomVolumeResult';
import styles                       from '../styles.module.scss';
import TotalNetExplosiveWeight      from '../components/stateless/TotalNetExplosiveWeight';
import { AlertBoxActions }          from '../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { Component }                from 'react';
import { connect }                  from 'react-redux';
import { CustomCalculationActions } from '../store/actions/customCalculation';
import { SettingsActions }          from '../store/actions/settings';

const insideOptions = [
    {
        label: I18n.t('yes'),
        value: true,
    },
    {
        label: I18n.t('no'),
        value: false,
    },
];

const nonLicencedExplosiveTypes = [
    'sheetC1_5',
    'DetCord3Gram',
];

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            roomLength: null,
            roomWidth:  null,
            roomHeight: null,
            volume:     null,
            inside:     false,
            explosives: [],
        };
    }

    renderUnitGroup = () => {
        return (
            <ActiveUnitFormGroup />
        );
    };

    onExplosivesChanged = (explosives) => {
        this.props.setExplosives({ explosives });
    };

    onBoostersChanged = (boosters) => {
        this.props.setBoosters({ boosters });
    };

    renderExplosivesGroup = () => {
        return (
            <div>
                <FormGroup
                    label={I18n.t('explosives')}
                >
                    <ExplosivesList
                        explosives={this.props.explosives}
                        onChange={this.onExplosivesChanged}
                        unit={this.props.unit}
                    />

                </FormGroup>
                <FormGroup
                    label={I18n.t('boosters')}
                >
                    <BoostersList
                        boosters={this.props.boosters}
                        onChange={this.onBoostersChanged}
                        unit={this.props.unit}
                    />
                </FormGroup>
            </div>
        );
    };

    renderPrimingGroup = () => {
        return (
            <PrimingSelectionInput
                onValueChanged={this.onPrimingChanged}
                value={this.props.primingCount}
            />
        );
    };

    onPrimingChanged = (priming) => {
        this.props.setPrimingCount({
            primingCount: priming.value,
        });
    };

    onBoosterChanged = (booster) => {
        const value = booster.value;

        this.props.setBooster({ booster: value });
    };

    renderTotalNetExplosiveWeightGroup = () => {
        let showCalculation = this.props.isUnlocked;

        if (!showCalculation) {
            const allUsedExplosiveTypes      = this.getAllUsedExplosiveTypes();
            const usedLicencedExplosiveTypes = allUsedExplosiveTypes.filter(n => !nonLicencedExplosiveTypes.includes(n)).length;
            showCalculation                  = !usedLicencedExplosiveTypes;
        }

        const errorText         = !showCalculation ? I18n.t('licenseRequiredError2') : null;
        let tntEquivalentInGram = this.props.tntEquivalentInGram;

        if (!tntEquivalentInGram) {
            tntEquivalentInGram = ExplosiveCalculator.calculateTotalTNTEquivalentInGram(
                this.props.explosives,
                this.props.primingCount,
                this.props.boosters,
            );
        }

        return (
            <TotalNetExplosiveWeight
                unitSystem={this.props.unit}
                netExplosiveWeight={this.props.netExplosiveWeight}
                tntEquivalentInGram={tntEquivalentInGram}
                errorText={errorText}
            />
        );
    };

    onInsideChanged = (value) => {
        this.props.setInside({ inside: value });
    };

    renderInsideGroup = () => {
        return (
            <FormGroup
                label={I18n.t('inside')}
            >
                <InputContainer
                    label={I18n.t('inside')}
                >
                    <OptionSelect
                        label={I18n.t('inside')}
                        value={this.props.inside}
                        onValueChanged={this.onInsideChanged}
                        options={insideOptions}
                    />
                </InputContainer>
            </FormGroup>
        );
    };

    onRoomLengthChanged = (event) => {
        const roomLength = event.target.value;

        this.props.updateRoomLength({ roomLength });
    };

    onRoomWidthChanged = (event) => {
        const roomWidth = event.target.value;

        this.props.updateRoomWidth({ roomWidth });
    };

    onRoomHeightChanged = (event) => {
        const roomHeight = event.target.value;

        this.props.updateRoomHeight({ roomHeight });
    };

    renderRoomGroup = () => {
        return (
            <RoomSizeInput
                key={'roomSizeInput'}
                onRoomLengthChanged={this.onRoomLengthChanged}
                onRoomWidthChanged={this.onRoomWidthChanged}
                onRoomHeightChanged={this.onRoomHeightChanged}
                room={this.props.room}
                unitSystem={this.props.unit}
            />
        );
    };

    renderRoomVolumeResultGroup = () => {
        return (
            <RoomVolumeResult
                key={'roomVolumeResult'}
                room={this.props.room}
                unitSystem={this.props.unit}
            />
        );
    };

    /**
     * Renders the resulting internal pressure
     */
    renderInternalPressureVolumeResultGroup = () => {
        return (
            <InternalPressureVolumeResult
                unitSystem={this.props.unit}
                key={'internalPressureVolumeResult'}
                internalPSI={this.props.internalPSI}
            />
        );
    };

    /**
     * Renders the Group for the resulting MSD **(Minimum Safe Stacking Distance)**
     */
    renderMSDResultGroup = () => {
        return (
            <MinimumSafeToStackDistance
                unitSystem={this.props.unit}
                safeStackingDistanceInMeter={this.props.safeStackingDistance}
                label={I18n.t('minimumSafeStackingDistance')}
            />
        );
    };

    saveButtonPressed = () => {
        if (this.props.isUnlocked) {
            this.props.clearAlerts();
            this.props.saveAsBreachReport();
        } else {
            this.props.clearAlerts();
            this.props.showErrorAlert({
                text: I18n.t('licenseRequiredError1'),
            });
            this.setState({
                showPurchaseLicenseHint: true,
            });
            window.scrollTo(0, 0);
        }
    };

    getAllUsedExplosiveTypes = () => {
        const explosiveTypes = this.props.explosives.map((explosive) => explosive.explosiveType);

        // Make the array unique
        return [...new Set(explosiveTypes)];
    };

    renderInsideBreachRelevantContent = () => {
        if (this.props.inside) {
            return [
                this.renderRoomGroup(),
                this.renderRoomVolumeResultGroup(),
                this.renderInternalPressureVolumeResultGroup(),
            ];
        }

        return null;
    };

    render () {
        return (
            <div>
                <Header
                    title={I18n.t('customCalculation')}
                />
                <ImageBackground
                    image={require('../assets/images/breacherInfoBackground.jpg')}
                    backgroundColor={Colors.colorDarkGray}
                    backgroundSize={'100%'}
                >
                    <div className={styles.contentView}>
                        <div className={styles.formContentView}>
                            <AlertBoxManager />
                            {this.renderPurchaseLicenseHint()}
                            {this.renderUnitGroup()}
                            {this.renderExplosivesGroup()}
                            {this.renderPrimingGroup()}
                            {this.renderTotalNetExplosiveWeightGroup()}
                            {this.renderInsideGroup()}
                            {this.renderInsideBreachRelevantContent()}
                            {this.renderMSDResultGroup()}
                            <FormGroup
                                theme={FormGroupTheme.submit}
                            >
                                <ColorButton
                                    onClick={this.saveButtonPressed}
                                    text={I18n.t('saveAsBreachReport')}
                                    theme={ColorButtonTheme.yellow}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </ImageBackground>
                <Footer />
            </div>
        );
    }

    renderPurchaseLicenseHint = () => {
        if (this.state.showPurchaseLicenseHint) {
            return (
                <PurchaseLicenseHint />
            );
        }

        return null;
    };
}

Screen.renderAffectingStates = [
    'explosives',
];

const mapStateToProps = state => (
    {
        boosters:             state.customCalculation.boosters,
        breachReports:        state.breachReports.breachReports,
        explosives:           state.customCalculation.explosives,
        inside:               state.customCalculation.inside,
        internalPSI:          state.customCalculation.internalPSI,
        isUnlocked:           state.license.isUnlocked,
        netExplosiveWeight:   state.customCalculation.netExplosiveWeight,
        primingCount:         state.customCalculation.primingCount,
        room:                 state.customCalculation.room,
        safeStackingDistance: state.customCalculation.safeStackingDistance,
        tntEquivalentInGram:  state.customCalculation.tntEquivalentInGram,
        unit:                 state.settings.unit,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SettingsActions,
    CustomCalculationActions,
    AlertBoxActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
