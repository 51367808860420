import Routes                        from '../../constants/Routes';
import { push }                      from 'connected-react-router';
import { put }                       from 'redux-saga/effects';
import { select }                    from 'redux-saga/effects';
import { CustomCalculationActions }  from '../actions/customCalculation';
import ExplosiveCalculator           from '../../helper/ExplosiveCalculator';
import UnitConverter                 from '../../helper/UnitConverter';
import { ActiveBreachReportActions } from '../actions/activeBreachReport';

const roomChanged = function* () {
    yield put(push(Routes.home));
};

const updateRoomLength = function* ({ roomLength }) {
    const convertedRoomLength = yield convertRoomSizeToBaseUnit({
        value: roomLength,
    });

    if (!isNaN(convertedRoomLength)) {
        yield put(CustomCalculationActions.setRoomLength({
            roomLength: convertedRoomLength,
        }));
    }
};

const updateRoomWidth = function* ({ roomWidth }) {
    const convertedRoomWidth = yield convertRoomSizeToBaseUnit({
        value: roomWidth,
    });

    if (!isNaN(convertedRoomWidth)) {
        yield put(CustomCalculationActions.setRoomWidth({
            roomWidth: convertedRoomWidth,
        }));
    }
};

const updateRoomHeight = function* ({ roomHeight }) {
    const convertedRoomHeight = yield convertRoomSizeToBaseUnit({
        value: roomHeight,
    });

    if (!isNaN(convertedRoomHeight)) {
        yield put(CustomCalculationActions.setRoomHeight({
            roomHeight: convertedRoomHeight,
        }));
    }
};

const convertRoomSizeToBaseUnit = function* ({ value }) {
    const currentUnitSystem = yield select(state => state.settings.unit);

    return UnitConverter.convertRoomSizeFromSystemToBaseUnitSystem(currentUnitSystem, value);
};

const calculateValues = function* () {
    const customCalculationStore = yield select(state => state.customCalculation);
    const {
              internalPSI,
              netExplosiveWeight,
              safeStackingDistance,
              tntEquivalentInGram,
          }                      = ExplosiveCalculator.calculateBreachValues(
        {
            ...customCalculationStore,
        },
    );

    yield put(CustomCalculationActions.setInternalPSI({ internalPSI }));
    yield put(CustomCalculationActions.setNetExplosiveWeight({ netExplosiveWeight, tntEquivalentInGram }));
    yield put(CustomCalculationActions.setSafeStackingDistance({ safeStackingDistance }));

    return null;
};

const saveAsBreachReport = function* () {
    const customCalculation = yield select(state => state.customCalculation);

    yield put(ActiveBreachReportActions.applyValues({ values: customCalculation }));
    yield put(push(Routes.newBreachReport));
};

export default {
    calculateValues,
    roomChanged,
    updateRoomLength,
    updateRoomWidth,
    updateRoomHeight,
    saveAsBreachReport,
};
