import update                from 'immutability-helper';
import { BreacherInfoTypes } from '../actions/breacherInfo';

const initialState = {
    breacherType: 'breacher',
    country:      null,
    name:         null,
    squadron:     null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BreacherInfoTypes.SET_BREACHER_TYPE:
            return update(state, {
                breacherType: {
                    $set: action.breacherType,
                },
            });

        case BreacherInfoTypes.SET_COUNTRY:
            return update(state, {
                country: {
                    $set: action.country,
                },
            });

        case BreacherInfoTypes.SET_NAME:
            return update(state, {
                name: {
                    $set: action.name,
                },
            });

        case BreacherInfoTypes.SET_SQUADRON:
            return update(state, {
                squadron: {
                    $set: action.squadron,
                },
            });

        default:
            return state;
    }
}
