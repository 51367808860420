import _ from 'lodash';
import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes from '../../PropTypes';
import React from 'react';
import StatelessBreachReportList from '../../stateless/BreachReportList';
import { ActiveBreachReportActions } from '../../../store/actions/activeBreachReport';
import { bindActionCreators } from 'redux';
import { BreachReportsActions } from '../../../store/actions/breachReports';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import I18n from 'i18next';

class BreachReportList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            breachReports: [],
            breachReportsToPrint: [],
            title: '',
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.breachReports !== prevState.breachReports) {
            const breachReports       = nextProps.searchQuery ? nextProps.foundBreachReports : nextProps.breachReports;
            const sortedBreachReports = _.orderBy(breachReports, ['creationDate'], ['desc']);

            return { breachReports: sortedBreachReports };
        }

        return null;
    }

    showReportPressed = (breachReportId) => {
        this.props.openBreachReport({ breachReportId });
    };

    deleteReportPressed = (breachReportId) => {
        this.props.deleteBreachReportRequest({ breachReportId });
        if (this.state.breachReportsToPrint && this.state.breachReportsToPrint.length) {
            this.printCheckboxChanged(null, breachReportId);
        }
    };

    duplicateReportPressed = (breachReport) => {
        this.props.duplicateBreachReport({ breachReport });
    };

    printCheckboxChanged = (event, breachReportId) => {
        const breachReportsToPrint = [...this.state.breachReportsToPrint];

        if (event && event.target.checked) {
            breachReportsToPrint.push(breachReportId);
        } else {
            const index = breachReportsToPrint.indexOf(breachReportId);

            if (index > -1) {
                breachReportsToPrint.splice(index, 1);
            }
        }

        this.setState({ breachReportsToPrint });
    };

    shareReportPressed = (breachReportIds, title) => {
        this.setState({ title });
        setTimeout(() => {
            this.props.shareReport({ breachReportIds });

        }, 500);
    };


    render() {
        const { title } = this.state;
        return (
            <div>
                <Helmet>
                    <title>
                        {title ? `${title} - ${I18n.t('pageTitle')}` : I18n.t('breachReports')}
                    </title>
                </Helmet>
                <StatelessBreachReportList
                    deleteReportPressed={this.deleteReportPressed}
                    duplicateReportPressed={this.duplicateReportPressed}
                    showReportPressed={this.showReportPressed}
                    shareReportPressed={this.shareReportPressed}
                    reports={this.state.breachReports}
                    breachReportsToPrint={this.state.breachReportsToPrint}
                    printCheckboxChanged={this.printCheckboxChanged}
                />
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = BreachReportList;

Component.propTypes = {
    breachReports:      PropTypes.object,
    foundBreachReports: PropTypes.object,
    searchQuery:        PropTypes.string,
};

Component.defaultProps = {
    breachReports:      {},
    foundBreachReports: {},
    searchQuery:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'breachReports',
];

const mapStateToProps = state => (
    {
        breachReports: state.breachReports.breachReports,
        foundBreachReports: state.breachReports.foundBreachReports,
        searchQuery: state.breachReports.searchQuery,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    ActiveBreachReportActions,
    BreachReportsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
