import I18n         from 'i18next';
import _            from 'lodash';
import { Boosters } from './Explosives';

const BreachMode = {
    training: 'training',
    mission:  'mission',
};

export const PrimingValues = {
    none:   0,
    single: 1,
    dual:   2,
};

export const BreachModeOptions = [
    {
        label: I18n.t(BreachMode.training),
        value: BreachMode.training,
    },
    {
        label: I18n.t(BreachMode.mission),
        value: BreachMode.mission,
    },
];

export const TypeOfBreachOptions = [
    {
        label: I18n.t('blast'),
        value: 'blast',
    },
    {
        label: I18n.t('push'),
        value: 'push',
    },
    {
        label: I18n.t('cut'),
        value: 'cut',
    },
];

export const LightOptions = [
    {
        label: I18n.t('day'),
        value: 'day',
    },
    {
        label: I18n.t('night'),
        value: 'night',
    },
];

export const BreachResultOptions = [
    {
        label: I18n.t('positive'),
        value: 'positive',
    },
    {
        label: I18n.t('negative'),
        value: 'negative',
    },
    {
        label: I18n.t('partial'),
        value: 'partial',
    },
];

export const BreachResultTargetOptions = [
    {
        label: I18n.t('hollowCoreWood'),
        value: 'hollow_core_wood',
    },
    {
        label: I18n.t('solidWood'),
        value: 'solid_wood',
    },
    {
        label: I18n.t('class1Metal'),
        value: 'class_1_metal',
    },
    {
        label: I18n.t('class2Metal'),
        value: 'class_2_metal',
    },
    {
        label: I18n.t('fiberglass'),
        value: 'fiberglass',
    },
    {
        label: I18n.t('brickWall'),
        value: 'brick_wall',
    },
    {
        label: I18n.t('blockWall'),
        value: 'block_wall',
    },
    {
        label: I18n.t('sheetrockWall'),
        value: 'sheetrock_wall',
    },
    {
        label: I18n.t('window'),
        value: 'window',
    },
    {
        label: I18n.t('securityDoor'),
        value: 'security_door',
    },
    {
        label: I18n.t('other'),
        value: 'other',
    },
];

export const BreachResultMethodOfAttachmentOptions = [
    {
        label: I18n.t('hydrogel'),
        value: 'hydrogel',
    },
    {
        label: I18n.t('breacherTape'),
        value: 'breacher_tape',
    },
    {
        label: I18n.t('carpetTape'),
        value: 'carpet_tape',
    },
    {
        label: I18n.t('doubleSidedTape'),
        value: 'double_sided_tape',
    },
    {
        label: I18n.t('other'),
        value: 'other',
    },
];

export const BoosterOptions = _.map(
    Boosters,
    (booster, key) => {
        return {
            label: booster.name,
            value: key,
        };
    },
);

export const OperatorOptions = [
    {
        label: I18n.t('breacher'),
        value: 'breacher',
    },
    {
        label: I18n.t('masterBreacher'),
        value: 'breachMaster',
    },
];

export const PrimingOptions = [
    {
        label: I18n.t('none'),
        value: PrimingValues.none,
    },
    {
        label: I18n.t('single'),
        value: PrimingValues.single,
    },
    {
        label: I18n.t('dual'),
        value: PrimingValues.dual,
    },
];

export const InsideOptions = [
    {
        label: I18n.t('yes'),
        value: true,
    },
    {
        label: I18n.t('no'),
        value: false,
    },
];