//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as ApiUrls      from '../constants/Api';
import ApiChecks         from '../constants/Api';
import Cast              from '../helper/Cast';
import Hydra             from '../helper/Hydra';
import ProgressiveWebApp from '../helper/ProgressiveWebApp';
import { create }        from 'apisauce';

const contentTypes = {
    applicationJSON:   'application/json',
    applicationLDJSON: 'application/ld+json',
};

const createInstance = (host, apiPath) => {
    ApiChecks.checkBackendUrl(host);

    let api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30000,
        headers: {
            'Content-Type': contentTypes.applicationJSON,
            'accept':       contentTypes.applicationLDJSON,
        },
    });

    api.host   = host;
    api.getUrl = getUrl;

    api.addResponseTransform((response) => {
        if (response.ok) {
            response.data = Hydra.cleanupObject(response.data);
        }

        return response;
    });

    return api;
};

const getUrl = (url = '') => {
    return Api.getBaseURL() + url;
};

const Api = createInstance(ApiChecks.getBackendUrl(), '/');

const addProgressiveWebAppFlag = (clientKey, flagValue) => {
    const payload = {};

    if (ProgressiveWebApp.isProgressiveWebApp()) {
        payload.clientKeyProgressiveWebApp = clientKey;
    } else {
        payload.clientKeyBrowser = clientKey;
    }

    return payload;
};

const checkForValidLicense = (licenseKey) => {
    return Api.get(
        ApiUrls.API_LICENSES_URL,
        {
            isProgressiveWebApp: Cast.string(ProgressiveWebApp.isProgressiveWebApp()),
            licenseKey,
        },
    );
};

const claimLicense = (
    licenseKey,
    licenseKeyId,
    clientKey,
) => {
    return Api.put(
        [
            licenseKeyId,
            '?licenseKey=',
            licenseKey,
            '&isProgressiveWebApp=',
            Cast.string(ProgressiveWebApp.isProgressiveWebApp()),
        ].join(''),
        addProgressiveWebAppFlag(
            clientKey,
        ),
    );
};

const releaseLicense = (
    licenseKey,
    licenseKeyId,
    clientKey,
) => {
    return Api.put(
        [
            licenseKeyId,
            '?licenseKey=',
            licenseKey,
            '&clientKey=',
            clientKey,
            '&isProgressiveWebApp=',
            Cast.string(ProgressiveWebApp.isProgressiveWebApp()),
        ].join(''),
        addProgressiveWebAppFlag(
            null,
        ),
    );
};

export {
    Api,
    checkForValidLicense,
    claimLicense,
    releaseLicense,
};