import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import Logo            from '../components/stateless/Logo';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import Headline        from '../components/stateless/Headline';
import LongText        from '../components/stateless/LongText';
import StartMenu       from '../components/connected/StartMenu';
import CenterContainer from '../components/stateless/CenterContainer';
import styles          from '../styles.module.scss';
import Footer          from '../components/stateless/Footer';
import ImageBackground from '../components/stateless/ImageBackground';
import HeadlineTheme   from '../components/stateless/Headline/HeadlineTheme';
import LogoType        from '../components/stateless/Logo/LogoType';

class Screen extends Component {
    render () {
        return (
            <ImageBackground
                image={require('../assets/images/landingPageBackground.jpg')}
            >
                <div
                    className={styles.contentViewWithPadding}
                >
                    <Helmet>
                        <title>
                            {PageTitleHelper.getPageTitle(I18n.t('home'))}
                        </title>
                    </Helmet>
                    <CenterContainer
                        centerContent={true}
                    >
                        <Logo logoType={LogoType.big} />
                        <Headline
                            theme={HeadlineTheme.whiteBigCentered}
                            title={I18n.t('landingPageOptionSelectTitle')}
                        />
                        <LongText noPaddingBottom={true}>
                            {I18n.t('landingPageOptionSelectSubTitle')}
                        </LongText>
                    </CenterContainer>
                    <StartMenu />
                    <Footer />
                </div>
            </ImageBackground>
        );
    }
}

export default Screen;