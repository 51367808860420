import { makeActionCreator } from '../../helper/Store';

export const ActiveBreachReportTypes = {
    APPLY_VALUES:                         'ActiveBreachReport/APPLY_VALUES',
    DUPLICATE_BREACH_REPORT:              'ActiveBreachReport/DUPLICATE_BREACH_REPORT',
    LOAD_BREACH_REPORT:                   'ActiveBreachReport/LOAD_BREACH_REPORT',
    LOADING_IMAGES:                       'ActiveBreachReport/LOADING_IMAGES',
    LOADING_IMAGES_DONE:                  'ActiveBreachReport/LOADING_IMAGES_DONE',
    NEW_BREACH_REPORT:                    'ActiveBreachReport/NEW_BREACH_REPORT',
    RECALCULATE_VALUES:                   'ActiveBreachReport/RECALCULATE_VALUES',
    SAVE_ACTIVE_BREACH_REPORT:            'ActiveBreachReport/SAVE_ACTIVE_BREACH_REPORT',
    SET_ACTIVE_BREACH_REPORT:             'ActiveBreachReport/SET_ACTIVE_BREACH_REPORT',
    SET_BOOSTERS:                         'ActiveBreachReport/SET_BOOSTERS',
    SET_BREACH_MODE:                      'ActiveBreachReport/SET_BREACH_MODE',
    SET_BREACH_RESULT:                    'ActiveBreachReport/SET_BREACH_RESULT',
    SET_COMMENTS:                         'ActiveBreachReport/SET_COMMENTS',
    SET_COUNTRY:                          'ActiveBreachReport/SET_COUNTRY',
    SET_CREATION_DATE:                    'ActiveBreachReport/SET_CREATION_DATE',
    SET_DATE_USED:                        'ActiveBreachReport/SET_DATE_USED',
    SET_EXPLOSIVES:                       'ActiveBreachReport/SET_EXPLOSIVES',
    SET_IMAGES:                           'ActiveBreachReport/SET_IMAGES',
    SET_INSIDE:                           'ActiveBreachReport/SET_INSIDE',
    SET_INTERNAL_PSI:                     'ActiveBreachReport/SET_INTERNAL_PSI',
    SET_LIGHT:                            'ActiveBreachReport/SET_LIGHT',
    SET_LOCATION:                         'ActiveBreachReport/SET_LOCATION',
    SET_METHOD_OF_ATTACHMENT_DESCRIPTION: 'ActiveBreachReport/SET_METHOD_OF_ATTACHMENT_DESCRIPTION',
    SET_METHOD_OF_ATTACHMENT_OPTION:      'ActiveBreachReport/SET_METHOD_OF_ATTACHMENT_OPTION',
    SET_NAME:                             'ActiveBreachReport/SET_NAME',
    SET_NET_EXPLOSIVE_WEIGHT:             'ActiveBreachReport/SET_NET_EXPLOSIVE_WEIGHT',
    SET_OPERATOR:                         'ActiveBreachReport/SET_OPERATOR',
    SET_PRIMING_COUNT:                    'ActiveBreachReport/SET_PRIMING_COUNT',
    SET_ROOM_HEIGHT:                      'ActiveBreachReport/SET_ROOM_HEIGHT',
    SET_ROOM_LENGTH:                      'ActiveBreachReport/SET_ROOM_LENGTH',
    SET_ROOM_VOLUME:                      'ActiveBreachReport/SET_ROOM_VOLUME',
    SET_ROOM_WIDTH:                       'ActiveBreachReport/SET_ROOM_WIDTH',
    SET_SAFE_STACKING_DISTANCE:           'ActiveBreachReport/SET_SAFE_STACKING_DISTANCE',
    SET_SHOT_NUMBER:                      'ActiveBreachReport/SET_SHOT_NUMBER',
    SET_TARGET_DESCRIPTION:               'ActiveBreachReport/SET_TARGET_DESCRIPTION',
    SET_TARGET_OPTION:                    'ActiveBreachReport/SET_TARGET_OPTION',
    SET_TEAM:                             'ActiveBreachReport/SET_TEAM',
    SET_TITLE:                            'ActiveBreachReport/SET_TITLE',
    SET_TYPE_OF_BREACH:                   'ActiveBreachReport/SET_TYPE_OF_BREACH',
    SET_TYPE_OF_CHARGE:                   'ActiveBreachReport/SET_TYPE_OF_CHARGE',
    SET_UNIT_SYSTEM:                      'ActiveBreachReport/SET_UNIT_SYSTEM',
    SET_VALUES:                           'ActiveBreachReport/SET_VALUES',
    UPDATE_ROOM_HEIGHT:                   'ActiveBreachReport/UPDATE_ROOM_HEIGHT',
    UPDATE_ROOM_LENGTH:                   'ActiveBreachReport/UPDATE_ROOM_LENGTH',
    UPDATE_ROOM_WIDTH:                    'ActiveBreachReport/UPDATE_ROOM_WIDTH',
};

const applyValues = makeActionCreator(
    ActiveBreachReportTypes.APPLY_VALUES,
    {
        values: null,
    },
);

const duplicateBreachReport = makeActionCreator(
    ActiveBreachReportTypes.DUPLICATE_BREACH_REPORT,
    {
        breachReport:   null,
        nextShotNumber: null,
    },
);

const loadBreachReport = makeActionCreator(
    ActiveBreachReportTypes.LOAD_BREACH_REPORT,
    {
        breachReport: null,
    },
);

const loadingImages = makeActionCreator(
    ActiveBreachReportTypes.LOADING_IMAGES,
);

const loadingImagesDone = makeActionCreator(
    ActiveBreachReportTypes.LOADING_IMAGES_DONE,
);

const newBreachReport = makeActionCreator(
    ActiveBreachReportTypes.NEW_BREACH_REPORT,
);

const recalculateValues = makeActionCreator(
    ActiveBreachReportTypes.RECALCULATE_VALUES,
);

const saveActiveBreachReport = makeActionCreator(
    ActiveBreachReportTypes.SAVE_ACTIVE_BREACH_REPORT,
);

const setActiveBreachReport = makeActionCreator(
    ActiveBreachReportTypes.SET_ACTIVE_BREACH_REPORT,
    {
        breachReport: null,
    },
);

const setBoosters = makeActionCreator(
    ActiveBreachReportTypes.SET_BOOSTERS,
    {
        boosters: null,
    },
);

const setBreachMode = makeActionCreator(
    ActiveBreachReportTypes.SET_BREACH_MODE,
    {
        breachMode: null,
    },
);

const setBreachResult = makeActionCreator(
    ActiveBreachReportTypes.SET_BREACH_RESULT,
    {
        breachResult: null,
    },
);

const setComments = makeActionCreator(
    ActiveBreachReportTypes.SET_COMMENTS,
    {
        comments: null,
    },
);

const setCountry = makeActionCreator(
    ActiveBreachReportTypes.SET_COUNTRY,
    {
        country: null,
    },
);

const setCreationDate = makeActionCreator(
    ActiveBreachReportTypes.SET_CREATION_DATE,
    {
        creationDate: null,
    },
);

const setDateUsed = makeActionCreator(
    ActiveBreachReportTypes.SET_DATE_USED,
    {
        dateUsed: null,
    },
);

const setExplosives = makeActionCreator(
    ActiveBreachReportTypes.SET_EXPLOSIVES,
    {
        explosives: null,
    },
);

const setImages = makeActionCreator(
    ActiveBreachReportTypes.SET_IMAGES,
    {
        images: null,
    },
);

const setInside = makeActionCreator(
    ActiveBreachReportTypes.SET_INSIDE,
    {
        inside: null,
    },
);

const setInternalPSI = makeActionCreator(
    ActiveBreachReportTypes.SET_INTERNAL_PSI,
    {
        internalPSI: null,
    },
);

const setLight = makeActionCreator(
    ActiveBreachReportTypes.SET_LIGHT,
    {
        light: null,
    },
);

const setLocation = makeActionCreator(
    ActiveBreachReportTypes.SET_LOCATION,
    {
        location: null,
    },
);

const setMethodOfAttachmentDescription = makeActionCreator(
    ActiveBreachReportTypes.SET_METHOD_OF_ATTACHMENT_DESCRIPTION,
    {
        methodOfAttachmentDescription: null,
    },
);

const setMethodOfAttachmentOption = makeActionCreator(
    ActiveBreachReportTypes.SET_METHOD_OF_ATTACHMENT_OPTION,
    {
        methodOfAttachmentOption: null,
    },
);

const setName = makeActionCreator(
    ActiveBreachReportTypes.SET_NAME,
    {
        name: null,
    },
);

const setNetExplosiveWeight = makeActionCreator(
    ActiveBreachReportTypes.SET_NET_EXPLOSIVE_WEIGHT,
    {
        netExplosiveWeight:  null,
        tntEquivalentInGram: null,
    },
);

const setOperator = makeActionCreator(
    ActiveBreachReportTypes.SET_OPERATOR,
    {
        operator: null,
    },
);

const setPrimingCount = makeActionCreator(
    ActiveBreachReportTypes.SET_PRIMING_COUNT,
    {
        primingCount: null,
    },
);

const setRoomHeight = makeActionCreator(
    ActiveBreachReportTypes.SET_ROOM_HEIGHT,
    {
        roomHeight: null,
    },
);

const setRoomLength = makeActionCreator(
    ActiveBreachReportTypes.SET_ROOM_LENGTH,
    {
        roomLength: null,
    },
);

const setRoomVolume = makeActionCreator(
    ActiveBreachReportTypes.SET_ROOM_VOLUME,
    {
        setRoomVolume: null,
    },
);

const setRoomWidth = makeActionCreator(
    ActiveBreachReportTypes.SET_ROOM_WIDTH,
    {
        roomWidth: null,
    },
);

const setSafeStackingDistance = makeActionCreator(
    ActiveBreachReportTypes.SET_SAFE_STACKING_DISTANCE,
    {
        safeStackingDistance: null,
    },
);

const setShotNumber = makeActionCreator(
    ActiveBreachReportTypes.SET_SHOT_NUMBER,
    {
        shotNumber: null,
    },
);

const setTargetDescription = makeActionCreator(
    ActiveBreachReportTypes.SET_TARGET_DESCRIPTION,
    {
        targetDescription: null,
    },
);

const setTargetOption = makeActionCreator(
    ActiveBreachReportTypes.SET_TARGET_OPTION,
    {
        targetOption: null,
    },
);

const setTeam = makeActionCreator(
    ActiveBreachReportTypes.SET_TEAM,
    {
        team: null,
    },
);

const setTitle = makeActionCreator(
    ActiveBreachReportTypes.SET_TITLE,
    {
        title: null,
    },
);

const setTypeOfBreach = makeActionCreator(
    ActiveBreachReportTypes.SET_TYPE_OF_BREACH,
    {
        typeOfBreach: null,
    },
);

const setTypeOfCharge = makeActionCreator(
    ActiveBreachReportTypes.SET_TYPE_OF_CHARGE,
    {
        typeOfCharge: null,
    },
);

const setUnitSystem = makeActionCreator(
    ActiveBreachReportTypes.SET_UNIT_SYSTEM,
    {
        unitSystem: null,
    },
);

const setValues = makeActionCreator(
    ActiveBreachReportTypes.SET_VALUES,
    {
        values: null,
    },
);

const updateRoomHeight = makeActionCreator(
    ActiveBreachReportTypes.UPDATE_ROOM_HEIGHT,
    {
        roomHeight: null,
    },
);

const updateRoomLength = makeActionCreator(
    ActiveBreachReportTypes.UPDATE_ROOM_LENGTH,
    {
        roomLength: null,
    },
);

const updateRoomWidth = makeActionCreator(
    ActiveBreachReportTypes.UPDATE_ROOM_WIDTH,
    {
        roomWidth: null,
    },
);

export const ActiveBreachReportActions = {
    applyValues,
    duplicateBreachReport,
    loadBreachReport,
    loadingImages,
    loadingImagesDone,
    newBreachReport,
    recalculateValues,
    saveActiveBreachReport,
    setActiveBreachReport,
    setBoosters,
    setBreachMode,
    setBreachResult,
    setComments,
    setCountry,
    setCreationDate,
    setDateUsed,
    setExplosives,
    setImages,
    setInside,
    setInternalPSI,
    setLight,
    setLocation,
    setMethodOfAttachmentDescription,
    setMethodOfAttachmentOption,
    setName,
    setNetExplosiveWeight,
    setOperator,
    setPrimingCount,
    setRoomHeight,
    setRoomLength,
    setRoomVolume,
    setRoomWidth,
    setSafeStackingDistance,
    setShotNumber,
    setTargetDescription,
    setTargetOption,
    setTeam,
    setTitle,
    setTypeOfBreach,
    setTypeOfCharge,
    setUnitSystem,
    setValues,
    updateRoomHeight,
    updateRoomLength,
    updateRoomWidth,
};