import React              from 'react';
import PropTypes          from '../../PropTypes';
import I18n               from 'i18next';
import ComponentHelper    from '../../../helper/ComponentHelper';
import UnitConverter      from '../../../helper/UnitConverter';
import Units              from '../../../constants/Units';
import FormGroup          from '../FormGroup';
import InputContainer     from '../InputContainer';
import InputContainerType from '../InputContainer/InputContainerType';
import Value              from '../Value';

class RoomVolumeResult extends React.Component {
    render () {
        const unitSystem = this.props.unitSystem;
        const unit       = Units.Room[unitSystem];
        const roomVolume = UnitConverter.calculateRoomVolumeInCurrentUnit(this.props.room, unitSystem);
        const value      = roomVolume ? roomVolume.toFixed(2) : '-';

        return (
            <FormGroup>
                <InputContainer
                    theme={InputContainerType.bigWhiteLabel}
                    label={I18n.t('roomVolumeLabel', { unit })}
                >
                    <Value
                        value={`${value} ${unit}³`}
                    />
                </InputContainer>
            </FormGroup>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = RoomVolumeResult;

Component.propTypes = {
    unitSystem: PropTypes.string,
    room:       PropTypes.object,
};

Component.defaultProps = {
    unitSystem: Units.Systems.metric.name,
    room:       {},
};

Component.renderAffectingProps = [
    'unitSystem',
    'room',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;