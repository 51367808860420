import React       from 'react';
import PropTypes   from '../../PropTypes';
import Select      from '../Select';
import SelectTheme from '../Select/SelectTheme';
import IconType    from '../Icon/IconType';
import _           from 'lodash';
import styles      from './styles.module.scss';
import Icon        from '../Icon';

class SelectInput extends React.Component {
    selectReference = null;

    getSelectedValue = () => {
        const value         = this.props.value;
        const selectedValue = this.props.options.filter(option => option.value === value);

        return selectedValue;
    };

    render () {
        return (
            <div
                className={styles.selectWrapper}
            >
                {this.renderSelect()}
                {this.renderRemoveButton()}
                {this.renderAddButton()}
            </div>
        );
    }

    renderSelect = () => {
        return (
            <Select
                innerRef={this.setSelectReference}
                options={this.props.options}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                theme={SelectTheme.noBorderLeft}
                value={this.getSelectedValue()}
            />
        );
    };

    renderAddButton = () => {
        if (this.props.showAddButton) {
            return (
                <div
                    className={styles.addButtonContainer}
                    onClick={this.props.onAddButtonPressed}
                >
                    <Icon iconType={IconType.plusCircle} />
                </div>
            );
        }
    };

    renderRemoveButton = () => {
        if (this.props.showRemoveButton) {
            return (
                <div
                    className={styles.addButtonContainer}
                    onClick={this.props.onRemoveButtonPressed}
                >
                    <Icon iconType={IconType.minusCircle} />
                </div>
            );
        }
    };

    setSelectReference = (selectReference) => {
        this.selectReference = selectReference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return true;
    }
}

const Component = SelectInput;

Component.propTypes = {
    iconType:              PropTypes.oneOf(Object.values(IconType)),
    label:                 PropTypes.string,
    onAddButtonPressed:    PropTypes.func,
    onRemoveButtonPressed: PropTypes.func,
    onChange:              PropTypes.func,
    options:               PropTypes.array,
    placeholder:           PropTypes.string,
    showAddButton:         PropTypes.bool,
    value:                 PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Component.defaultProps = {
    iconType:              null,
    label:                 '',
    onAddButtonPressed:    _.noop,
    onRemoveButtonPressed: _.noop,
    onChange:              _.noop,
    options:               [],
    placeholder:           null,
    showAddButton:         false,
    value:                 null,
};

Component.renderAffectingProps = [
    'placeholder',
    'options',
    'value',
];

Component.renderAffectingStates = [];

export default SelectInput;