import PropTypes        from '../../PropTypes';
import React            from 'react';
import styles           from './styles.module.scss';
import ColorButton      from '../ColorButton';
import I18n             from 'i18next';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import _                from 'lodash';

class InstallWebAppBanner extends React.Component {
    onInstallPressed = () => {
        this.props.onInstallPressed();
    };

    onCancelPressed = () => {
        this.props.onCancelPressed();
    };

    renderButtonContainer = () => {
        if (this.props.installable) {
            return (
                <div
                    className={styles.buttonContainer}
                >
                    <ColorButton
                        onClick={this.onInstallPressed}
                        text={I18n.t('install')}
                        theme={ColorButtonTheme.graySlim}
                    />
                    <ColorButton
                        onClick={this.onCancelPressed}
                        text={I18n.t('cancel')}
                        theme={ColorButtonTheme.graySlim}
                    />
                </div>
            );
        }

        return (
            <div
                className={styles.buttonContainer}
            >
                <ColorButton
                    onClick={this.onCancelPressed}
                    text={I18n.t('ok')}
                    theme={ColorButtonTheme.graySlim}
                />
            </div>
        );
    };

    getBannerText () {
        if (this.props.installable) {
            return this.props.installableText;
        }

        return this.props.bookmarkText;
    }

    render () {
        if (this.props.visible) {
            return (
                <div
                    className={styles.container}
                >
                    <div
                        className={styles.textContainer}
                    >
                        {this.getBannerText()}
                    </div>
                    {this.renderButtonContainer()}
                </div>
            );
        }

        return null;
    }
}

const Component = InstallWebAppBanner;

Component.propTypes = {
    bookmarkText:     PropTypes.string,
    installableText:  PropTypes.string,
    onCancelPressed:  PropTypes.func,
    onInstallPressed: PropTypes.func,
    visible:          PropTypes.bool,
};

Component.defaultProps = {
    bookmarkText:     '',
    installableText:  '',
    onCancelPressed:  _.noop,
    onInstallPressed: _.noop,
    visible:          false,
};

Component.renderAffectingProps = [
    'bookmarkText',
    'installable',
    'installableText',
    'visible',
];

Component.renderAffectingStates = [];

export default Component;