import _                       from 'lodash';
import { BreachModeOptions }   from '../../constants/Breach';
import { BreachResultOptions } from '../../constants/Breach';
import { LightOptions }        from '../../constants/Breach';
import { OperatorOptions }     from '../../constants/Breach';
import { TypeOfBreachOptions } from '../../constants/Breach';
import { PrimingValues }       from '../../constants/Breach';

class BreachReportFactory {
    static getInitialState = () => {
        return {
            boosters:                 [],
            breachMode:               _.head(BreachModeOptions).value,
            breachResult:             _.head(BreachResultOptions).value,
            country:                  '',
            creationDate:             new Date().getTime(),
            dateUsed:                 null,
            explosives:               [],
            id:                       new Date().getTime(),
            images:                   [],
            inside:                   false,
            internalPSI:              0,
            isNew:                    true,
            light:                    _.head(LightOptions).value,
            methodOfAttachment:       '',
            methodOfAttachmentOption: null,
            name:                     '',
            netExplosiveWeight:       0,
            operator:                 _.head(OperatorOptions).value,
            primingCount:             PrimingValues.single,
            room:                     {
                heightInMeter: null,
                lengthInMeter: null,
                widthInMeter:  null,
            },
            safeStackingDistance:     0,
            targetOption:             null,
            team:                     '',
            title:                    '',
            typeOfBreach:             _.head(TypeOfBreachOptions).value,
        };
    };
}

export default BreachReportFactory;