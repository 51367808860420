import { makeActionCreator } from '../../helper/Store';

export const PreCalculatedTypes = {
    SAVE_AS_BREACH_REPORT: 'PreCalculated/SAVE_AS_BREACH_REPORT',
};

const saveAsBreachReport = makeActionCreator(
    PreCalculatedTypes.SAVE_AS_BREACH_REPORT,
    {
        preCalculatedBreachpop: {},
    },
);

export const PreCalculatedActions = {
    saveAsBreachReport,
};