import React           from 'react';
import PropTypes       from '../../PropTypes';
import I18n            from 'i18next';
import ComponentHelper from '../../../helper/ComponentHelper';
import UnitConverter   from '../../../helper/UnitConverter';
import Units           from '../../../constants/Units';
import FormGroup       from '../FormGroup';
import InputContainer  from '../InputContainer';
import TextInput       from '../TextInput';

class RoomSizeInput extends React.Component {
    render () {
        const unitSystem = this.props.unitSystem;
        const unitLabel  = Units.Room[unitSystem];
        const room       = UnitConverter.convertRoomValuesToCurrentUnit(unitSystem, this.props.room);
        const roomLength = room.lengthInMeter;
        const roomWidth  = room.widthInMeter;
        const roomHeight = room.heightInMeter;

        return (
            <FormGroup
                label={I18n.t('room')}
            >
                <InputContainer
                    key={'roomSizeLength'}
                    label={I18n.t('length')}
                >
                    <TextInput
                        onChange={this.props.onRoomLengthChanged}
                        value={roomLength}
                        unit={unitLabel}
                    />
                </InputContainer>
                <InputContainer
                    key={'roomSizeWidth'}
                    label={I18n.t('width')}
                >
                    <TextInput
                        onChange={this.props.onRoomWidthChanged}
                        value={roomWidth}
                        unit={unitLabel}
                    />
                </InputContainer>
                <InputContainer
                    key={'roomSizeHeight'}
                    label={I18n.t('height')}
                >
                    <TextInput
                        onChange={this.props.onRoomHeightChanged}
                        value={roomHeight}
                        unit={unitLabel}
                    />
                </InputContainer>
            </FormGroup>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = RoomSizeInput;

Component.propTypes = {
    unitSystem: PropTypes.string,
    room:       PropTypes.object,
};

Component.defaultProps = {
    unitSystem: Units.Systems.metric.name,
    room:       {},
};

Component.renderAffectingProps = [
    'unitSystem',
    'room',
];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;