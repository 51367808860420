import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';
import classNames      from 'classnames';
import SelectionHelper from '../../../helper/SelectionHelper';
import PrintValueType  from './PrintValueType';

class PrintValue extends React.Component {
    getFormattedValue = () => {
        const value        = this.props.value;
        let formattedValue = '';

        if (this.props.multiline && typeof value === 'string') {
            formattedValue = value
                .split('\n')
                .map(
                    (item, key) => {
                        return (
                            <span key={key}>{item}
                                <br />
                            </span>
                        );
                    });
        } else {
            formattedValue = value;
        }

        return formattedValue;
    };

    render () {
        const formattedValue = this.getFormattedValue();

        return (
            <div
                className={
                    classNames(
                        styles.container,
                        SelectionHelper.get(
                            this.props.theme,
                            {
                                [PrintValueType.small]: styles.small,
                            },
                        ),
                    )
                }
            >
                <div
                    className={styles.labelContainer}
                >
                    <span>
                        {this.props.label}
                    </span>
                </div>
                <div
                    className={
                        classNames(
                            styles.valueContainer,
                            SelectionHelper.get(
                                this.props.theme,
                                {
                                    [PrintValueType.grayBackground]: styles.grayBackground,
                                    [PrintValueType.small]:          styles.small,
                                },
                            ),
                        )
                    }
                >
                    <div className={styles.value}>
                        {formattedValue} {this.props.unit}
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = PrintValue;

Component.propTypes = {
    multiline: PropTypes.bool,
    theme:     PropTypes.string,
    unit:      PropTypes.string,
    value:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Component.defaultProps = {
    multiline: false,
    theme:     '',
    unit:      '',
    value:     '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;