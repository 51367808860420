import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import FormGroupTheme  from './FormGroupTheme';
import SelectionHelper from '../../../helper/SelectionHelper';
import classNames      from 'classnames';

class FormGroup extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    renderGroupLabel = () => {
        const label = this.props.label;

        if (label) {
            return (
                <span
                    className={styles.groupTitle}
                >
                    {this.props.label}
                </span>
            );
        }
    };

    render () {
        return (
            <div
                className={
                    classNames(
                        styles.container,
                        SelectionHelper.get(
                            this.props.theme,
                            {
                                [FormGroupTheme.submit]: styles.submit,
                            },
                        ),
                    )
                }
            >
                {this.renderGroupLabel()}
                {this.props.children}
            </div>
        );
    }
}

const Component = FormGroup;

Component.propTypes = {
    label:    PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    theme:    PropTypes.oneOf(Object.keys(FormGroupTheme)),
};

Component.defaultProps = {
    label:    '',
    children: [],
    theme:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;