const Systems = {
    imperial: {
        name: 'imperial',
    },
    metric:   {
        name: 'metric',
    },
};

const Room = {
    imperial: 'ft',
    metric:   'm',
};

const InternalPressureBase           = 'psi';
const InternalPressureKiloPascalUnit = 'kPa';

const InternalPressure = {
    imperial: 'psi',
    metric:   'kPa',
};

const Distance = {
    imperial: 'ft',
    metric:   'm',
};

const Explosives = {
    imperial: {
        lengthInCentimeter:             'in',
        widthInCentimeter:              'in',
        weightInGramPerCubicCentimeter: 'gr',
        heightInCentimeter:             'mm',
    },
    metric:   {
        lengthInCentimeter:             'cm',
        widthInCentimeter:              'cm',
        weightInGramPerCubicCentimeter: 'g',
        heightInCentimeter:             'mm',
    },
};

const NetExplosiveWeight = {
    imperial:    'gr',
    metric:      'g',
    alternative: 'oz',
};

const TntEquivalent = {
    imperial: 'lb',
    metric:   'lb',
};

const BaseDistanceUnit = Distance.metric;

export default {
    BaseDistanceUnit,
    Distance,
    Explosives,
    InternalPressure,
    InternalPressureBase,
    NetExplosiveWeight,
    InternalPressureKiloPascalUnit,
    Room,
    Systems,
    TntEquivalent,
};
