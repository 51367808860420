import PropTypes        from '../../PropTypes';
import React            from 'react';
import styles           from './styles.module.scss';
import classNames       from 'classnames';
import ColorButtonTheme from './ColorButtonTheme';
import SelectionHelper  from '../../../helper/SelectionHelper';
import Icon             from '../Icon';
import IconType         from '../Icon/IconType';
import Link             from '../Link';
import ComponentHelper  from '../../../helper/ComponentHelper';

class ColorButton extends React.Component {
    getClassName = () => {
        const className = classNames(
            styles.colorButton,
            SelectionHelper.get(
                this.props.theme,
                {
                    [ColorButtonTheme.gray]:               styles.colorButtonGray,
                    [ColorButtonTheme.graySlim]:           styles.colorButtonGraySlim,
                    [ColorButtonTheme.graySmall]:          styles.colorButtonGraySmall,
                    [ColorButtonTheme.graySmallFullWidth]: styles.colorButtonGraySmallFullWidth,
                    [ColorButtonTheme.yellow]:             styles.colorButtonYellow,
                    [ColorButtonTheme.yellowSmall]:        styles.colorButtonYellowSmall,
                },
            ),
            this.props.className,
        );

        return className;
    };

    render () {
        const finalClassName = this.getClassName();

        if (this.props.onClick) {
            return (
                <span
                    className={finalClassName}
                    onClick={this.props.onClick}
                >
                    {this.renderIcon()}
                    {this.props.text}
                </span>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={finalClassName}
                    to={this.props.to}
                >
                    {this.renderIcon()}
                    {this.props.text}
                </Link>
            );
        }

        return null;
    }

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <Icon
                    iconType={this.props.iconType}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = ColorButton;

Component.propTypes = {
    className: PropTypes.string,
    iconType:  PropTypes.oneOf(Object.values(IconType)),
    onClick:   PropTypes.func,
    text:      PropTypes.string,
    theme:     PropTypes.oneOf(Object.values(ColorButtonTheme)),
    to:        PropTypes.string,
};

Component.defaultProps = {
    className: null,
    iconType:  null,
    onClick:   null,
    text:      '',
    theme:     null,
    to:        null,
};

Component.renderAffectingProps = [
    'className',
    'iconType',
    'text',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;