import ComponentHelper     from '../../../helper/ComponentHelper';
import PropTypes           from '../../PropTypes';
import React               from 'react';
import styles              from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link                from '../Link';

class HeaderButton extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        if (this.props.route) {
            return (
                <div
                    className={styles.headerButton}
                >
                    <Link
                        to={this.props.route}
                        params={this.props.params}
                    >
                        {this.renderIcon()}
                        {this.props.label}
                    </Link>
                </div>
            );
        }

        return null;
    }

    renderIcon () {
        if (this.props.icon) {
            return (
                <FontAwesomeIcon icon={this.props.icon} />
            );
        }

        return null;
    }
}

const Component = HeaderButton;

Component.propTypes = {
    icon:   PropTypes.any,
    params: PropTypes.object,
    route:  PropTypes.string,
};

Component.defaultProps = {
    icon:   null,
    params: {},
    route:  '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;