import ComponentHelper   from '../../../helper/ComponentHelper';
import React             from 'react';
import PropTypes         from '../../PropTypes';
import styles            from './styles.module.scss';
import _                 from 'lodash';
import TextInputType     from './TextInputType';
import { DebounceInput } from 'react-debounce-input';

class TextInput extends React.Component {
    textInputReference = null;

    constructor (props) {
        super(props);

        this.state = {
            value:   props.value,
            focused: false,
        };
    }

    unitBoxClicked = () => {
        if (this.inputReference) {
            this.inputReference.focus();
        }
    };

    render () {
        return (
            <div className={styles.textInputWrapper}>
                {this.renderTextInput()}
                {this.renderUnitBox()}
            </div>
        );
    }

    renderUnitBox = () => {
        if (this.props.unit) {
            return (
                <div
                    className={styles.unitBox}
                    onClick={this.unitBoxClicked}
                >
                    <span>
                        {this.props.unit}
                    </span>
                </div>
            );
        }

        return null;
    };

    onChange = (event) => {
        const value = event.target.value;

        this.setState({ value });

        this.props.onChange(event);
    };

    onBlur = () => {
        this.setState({
            focused: false,
        });
        this.props.onBlur();
    };

    onFocus = () => {
        this.setState({
            focused: true,
        });
        this.props.onFocus();
    };

    renderTextInput = () => {
        let htmlTagName = 'input';

        if (this.props.type === TextInputType.textarea) {
            htmlTagName = 'textarea';
        }

        let value;

        if (
            this.props.value !== this.state.value &&
            this.state.focused
        ) {
            value = this.state.value;
        } else {
            value = this.props.value;
        }

        return (
            <DebounceInput
                readOnly={this.props.readOnly}
                ref={this.setInputReference}
                debounceTimeout={this.props.debounceTimeout}
                element={htmlTagName}
                onChange={this.onChange}
                type={this.props.type}
                value={value}
                placeholder={this.props.placeholder}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                pattern={this.props.pattern}
                title={this.props.title}
            />
        );
    };

    setInputReference = (inputReference) => {
        this.inputReference = inputReference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TextInput;

Component.propTypes = {
    debounceTimeout: PropTypes.number,
    onBlur:          PropTypes.func,
    onChange:        PropTypes.func,
    onFocus:         PropTypes.func,
    pattern:         PropTypes.string,
    placeholder:     PropTypes.string,
    readOnly:        PropTypes.bool,
    title:           PropTypes.string,
    type:            PropTypes.oneOf(Object.values(TextInputType)),
    unit:            PropTypes.string,
    value:           PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Component.defaultProps = {
    debounceTimeout: 800,
    onBlur:          _.noop,
    onChange:        _.noop,
    onFocus:         _.noop,
    pattern:         null,
    placeholder:     null,
    readOnly:        false,
    title:           null,
    type:            TextInputType.text,
    unit:            null,
    value:           null,
};

Component.renderAffectingProps = [
    'unit',
    'placeholder',
    'value',
    'readOnly',
];

Component.renderAffectingStates = [];

export default Component;