export const UiValue = '__UI__';

const SheetExplosiveDefaultValues = {
    lengthInCentimeter:             UiValue,
    relativeEffectivenessFactor:    1.19,
    weightInGramPerCubicCentimeter: 1.549923,
    widthInCentimeter:              UiValue,
};

const DetCordExplosiveDefaultValues = {
    heightInCentimeter:          1,
    lengthInCentimeter:          UiValue,
    relativeEffectivenessFactor: 1.66,
    widthInCentimeter:           1,
};

const ACEExplosiveDefaultValues = {
    heightInCentimeter:          1,
    lengthInCentimeter:          UiValue,
    relativeEffectivenessFactor: 1.27,
    widthInCentimeter:           1,
};

export const Explosives = {
    sheetC1:                    {
        key:    'sheetC1',
        name:   'Sheet C1',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.1,
        },
    },
    sheetC1_5:                  {
        key:    'sheetC1_5',
        name:   'Sheet C1.5',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.15,
        },
    },
    sheetC2:                    {
        key:    'sheetC2',
        name:   'Sheet C2',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.2,
        },
    },
    sheetC3:                    {
        key:    'sheetC3',
        name:   'Sheet C3',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.3,
        },
    },
    sheetC4:                    {
        key:    'sheetC4',
        name:   'Sheet C4',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.4,
        },
    },
    sheetC5:                    {
        key:    'sheetC5',
        name:   'Sheet C5',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.5,
        },
    },
    sheetC6:                    {
        key:    'sheetC6',
        name:   'Sheet C6',
        values: {
            ...SheetExplosiveDefaultValues,
            heightInCentimeter: 0.6,
        },
    },
    plasticC4:                  {
        key:    'plasticC4',
        name:   'Plastic C4',
        values: {
            heightInCentimeter:             1,
            lengthInCentimeter:             1,
            relativeEffectivenessFactor:    1.34,
            weightInGramPerCubicCentimeter: UiValue,
            widthInCentimeter:              1,
        },
    },
    // This key contains grain instead of gram, because 18 grain has been 3 grams earlier.
    // We cannot change these keys afterwards, because data would be lost in existing reports.
    // See: https://lulububu.atlassian.net/browse/BREACHPOP-209
    DetCord15Grain:             {
        key:    'DetCord15Grain',
        name:   'Det Cord 15 grain / 3 gram',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.03189166667,
        },
    },
    DetCord3Gram:               {
        key:    'DetCord3Gram',
        name:   'Det Cord 18 grain / 4 gram',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.03827,
        },
    },
    DetCord5Gram:               {
        key:    'DetCord5Gram',
        name:   'Det Cord 25 grain / 5 gram',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.053149608,
        },
    },
    DetCord10Gram:              {
        key:    'DetCord10Gram',
        name:   'Det Cord 54 grain',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.114802298,
        },
    },
    DetCord10GramNonUsMilitary: {
        key:    'DetCord10GramNonUsMilitary',
        name:   'Det Cord 50 grain / 10 gram (Law Enforcement & Non-US Military)',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.106299216,
        },
    },
    DetCord20gram:              {
        key:    'DetCord20gram',
        name:   'Det Cord 100 grain / 20 gram',
        values: {
            ...DetCordExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.2126,
        },
    },
    ACE300grain:                {
        key:    'ACE300grain',
        name:   'ACE 300gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.63779,
        },
    },
    ACE450grain:                {
        key:    'ACE450grain',
        name:   'ACE 450gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 0.956685,
        },
    },
    ACE600grain:                {
        key:    'ACE600grain',
        name:   'ACE 600gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 1.27557,
        },
    },
    ACE900grain:                {
        key:    'ACE900grain',
        name:   'ACE 900gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 1.913357405,
        },
    },
    ACE1200grain:               {
        key:    'ACE1200grain',
        name:   'ACE 1200gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 2.551145936,
        },
    },
    ACE2400grain:               {
        key:    'ACE2400grain',
        name:   'ACE 2400gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 5.102270615,
        },
    },
    ACE3600grain:               {
        key:    'ACE3600grain',
        name:   'ACE 3600gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 7.653416551,
        },
    },
    ACE5400grain:               {
        key:    'ACE5400grain',
        name:   'ACE 5400gr',
        values: {
            ...ACEExplosiveDefaultValues,
            weightInGramPerCubicCentimeter: 11.480114197,
        },
    },
};

const BoosterBaseWeightInGram = 20;

export const Boosters = {
    none:    {
        name:                        'none',
        weightInGram:                0,
        relativeEffectivenessFactor: 1.66,
    },
    one:     {
        name:                        '1 - 20 Gram Booster',
        weightInGram:                BoosterBaseWeightInGram,
        relativeEffectivenessFactor: 1.66,
    },
    half:    {
        name:                        '1/2 - 20 Gram Booster',
        weightInGram:                BoosterBaseWeightInGram / 2,
        relativeEffectivenessFactor: 1.66,
    },
    quarter: {
        name:                        '1/4 - 20 Gram Booster',
        weightInGram:                BoosterBaseWeightInGram / 4,
        relativeEffectivenessFactor: 1.66,
    },
};

export const InternalPressureInKiloPascalThreshold = 25;

export const Initiator = {
    weightInGram:                0.9072,
    relativeEffectivenessFactor: 1.66,
};
