import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import PrintValue      from '../PrintValue';
import PrintValueType  from '../PrintValue/PrintValueType';
import PropTypes       from 'prop-types';
import React           from 'react';
import styles          from './styles.module.scss';
import moment          from 'moment';

class PrintHeadline extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        const breacherInfo = this.props.breacherInfo;
        const dateTime     = moment();
        const time         = dateTime.format(I18n.t('printTimeFormat'));
        const date         = dateTime.format(I18n.t('printDateFormat'));

        return (
            <div className={styles.printHeaderContainer}>
                <div
                    className={styles.leftHeaderContainer}
                >
                    <h2>
                        {I18n.t('breachReport')}
                    </h2>
                    <PrintValue
                        theme={PrintValueType.small}
                        label={I18n.t('user')}
                        value={breacherInfo.name}
                    />
                    <PrintValue
                        theme={PrintValueType.small}
                        label={I18n.t('time')}
                        value={time}
                    />
                    <PrintValue
                        theme={PrintValueType.small}
                        label={I18n.t('date')}
                        value={date}
                    />
                </div>
                <div
                    className={styles.rightHeaderContainer}
                >
                    <img
                        alt={I18n.t('logoAlternativeText')}
                        className={styles.printHeaderLogo}
                        src={require('../../../assets/images/logo.png')}
                    />
                </div>
            </div>
        );
    }
}

const Component = PrintHeadline;

Component.propTypes = {
    breacherInfo: PropTypes.object,
};

Component.defaultProps = {
    breacherInfo: {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;