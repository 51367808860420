import I18n from 'i18next';

class SelectionHelper {
    /**
     * A method that helps mapping data. Examples:
     *
     * SelectionHelper.get(
     *     'yolo',
     *     {
     *        'test': 'foo',
     *        'yolo': 'bar',
     *     },
     *     1337
     * )
     * >>> 'bar'
     *
     * SelectionHelper.get(
     *     'test',
     *     {
     *        'test': 'foo',
     *        'yolo': 'bar',
     *     },
     *     1337
     * )
     * >>> 'foo'
     *
     * SelectionHelper.get(
     *     'yeck',
     *     {
     *        'test': 'foo',
     *        'yolo': 'bar',
     *     },
     *     1337
     * )
     * >>> 1337
     *
     * @param currentValue
     * @param mapping
     * @param fallback
     * @returns {*}
     */
    static get (currentValue, mapping, fallback) {
        const mappingValues = Object.keys(mapping);

        for (const mappingValue of mappingValues) {
            if (mappingValue === currentValue) {
                return mapping[currentValue];
            }
        }

        return fallback;
    }

    /**
     * Uses the same logic as get but tries to translate the result.
     *
     * @see get
     * @param currentValue
     * @param mapping
     * @param fallback
     * @param translationParameters
     * @returns {string|string | string}
     */
    static getTranslation (currentValue, mapping, fallback, translationParameters) {
        const languageIndex = this.get(currentValue, mapping, fallback);

        if (languageIndex) {
            return I18n.t(languageIndex, translationParameters);
        }

        return '';
    }
}

export default SelectionHelper;