import { makeActionCreator } from '../../helper/Store';

export const BreacherInfoTypes = {
    SAVE:              'BreacherInfo/SAVE',
    SET_BREACHER_TYPE: 'BreacherInfo/SET_BREACHER_TYPE',
    SET_COUNTRY:       'BreacherInfo/SET_COUNTRY',
    SET_NAME:          'BreacherInfo/SET_NAME',
    SET_SQUADRON:      'BreacherInfo/SET_SQUADRON',
};

const save = makeActionCreator(
    BreacherInfoTypes.SAVE,
);

const setBreacherType = makeActionCreator(
    BreacherInfoTypes.SET_BREACHER_TYPE,
    {
        breacherType: null,
    },
);

const setCountry = makeActionCreator(
    BreacherInfoTypes.SET_COUNTRY,
    {
        country: null,
    },
);

const setName = makeActionCreator(
    BreacherInfoTypes.SET_NAME,
    {
        name: null,
    },
);

const setSquadron = makeActionCreator(
    BreacherInfoTypes.SET_SQUADRON,
    {
        squadron: null,
    },
);

export const BreacherInfoActions = {
    save,
    setBreacherType,
    setCountry,
    setName,
    setSquadron,
};