import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';
import ImageBackground from '../components/stateless/ImageBackground';
import styles          from '../styles.module.scss';
import CenterContainer from '../components/stateless/CenterContainer';
import Headline        from '../components/stateless/Headline';
import HeadlineTheme   from '../components/stateless/Headline/HeadlineTheme';
import LongText        from '../components/stateless/LongText';
import Footer          from '../components/stateless/Footer';
import Header          from '../components/stateless/Header';

class Screen extends Component {
    render () {
        return (
            <div>
                <Header
                    title={I18n.t('dataProtection')}
                />
                <ImageBackground
                    image={require('../assets/images/landingPageBackground.jpg')}
                >
                    <div
                        className={styles.contentViewWithPadding}
                    >
                        <Helmet>
                            <title>
                                {PageTitleHelper.getPageTitle(I18n.t('dataProtection'))}
                            </title>
                        </Helmet>
                        <CenterContainer
                            centerContent={true}
                        >
                            <Headline
                                theme={HeadlineTheme.whiteSmall}
                                title={I18n.t('privacyAndSafety')}
                            />
                            <LongText>
                                <p>
                                    P3D Solutions, Inc and BreachPop, LLC take your privacy very seriously. We utilize
                                    Google's GAME encryption, developed by Zix for G-Suite Business specifically. This
                                    ensures credentials/letterhead are secured as necessary for our customers. Secure
                                    communications can also be made available via WhatsApp or Signal at your request.
                                </p>
                                <br />
                                <p>
                                    Further, your breaching data is safe. There is NO data mined by P3D Solutions,
                                    Inc or BreachPop, LLC through the use of the breaching app. Once downloaded to your
                                    device, all charges, calculations and breach reports are stored natively in your
                                    local storage and cannot be accessed by us.
                                </p>
                            </LongText>
                        </CenterContainer>
                        <Footer />
                    </div>
                </ImageBackground>
            </div>
        );
    }
}

export default Screen;