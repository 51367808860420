import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '../../../helper/ComponentHelper';
import convert         from 'convert-units';
import I18n            from 'i18next';
import InputContainer  from '../InputContainer';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import SelectInput     from '../SelectInput';
import styles          from './styles.module.scss';
import TextInput       from '../TextInput';
import Units           from '../../../constants/Units';
import { Explosives }  from '../../../constants/Explosives';
import { UiValue }     from '../../../constants/Explosives';
import Cast            from '../../../helper/Cast';

const explosivesOptions = _.map(
    Explosives, (explosive, key) => {
        return {
            value: key,
            label: explosive.name,
        };
    },
);

class ExplosivesList extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            explosives: [],
        };
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    onExplosiveValueChanged = (event, explosiveId, valueKey) => {
        const explosive                      = _.find(this.state.explosives, { id: explosiveId });
        const value                          = _.get(event, 'target.value');
        const valueWithNormalizedPunctuation = Cast.float(value);
        const currentSystem                  = this.props.unit;
        const valueUnit                      = _.get(
            Units,
            [
                'Explosives',
                currentSystem,
                valueKey,
            ],
        );
        const metricName                     = _.get(
            Units,
            [
                'Systems',
                'metric',
                'name',
            ],
        );
        const baseValueUnit                  = _.get(
            Units,
            [
                'Explosives',
                metricName,
                valueKey,
            ],
        );

        if (!isNaN(valueWithNormalizedPunctuation)) {
            explosive[valueKey] = convert(valueWithNormalizedPunctuation).from(valueUnit).to(baseValueUnit);
        }

        this.onChange();
    };

    onChange = () => {
        this.props.onChange(_.cloneDeep(this.state.explosives));
    };

    renderExplosiveValues = (explosive) => {
        const explosiveType          = _.get(explosive, 'explosiveType', null);
        const explosiveConfiguration = Explosives[explosiveType];

        if (!explosiveConfiguration) {
            return;
        }

        const explosiveValues = explosiveConfiguration.values;
        const valueKeys       = _.filter(_.keys(explosiveValues), (key) => {
            return explosiveValues[key] === UiValue;
        });

        return _.map(
            valueKeys,
            (valueKey) => {
                return this.renderExplosiveValue(valueKey, explosive);
            },
        );
    };

    renderExplosiveValue = (valueKey, explosive) => {
        const unit                = Units.Explosives[this.props.unit][valueKey];
        const baseUnit            = Units.Explosives[Units.Systems.metric.name][valueKey];
        const value               = explosive[valueKey];
        const explosiveId         = explosive.id;
        const valueTranslationKey = 'explosiveValue' + _.upperFirst(valueKey);
        let convertedValue        = 0;

        if (!isNaN(value)) {
            convertedValue = convert(value).from(baseUnit).to(unit).toFixed(2);
        }

        return (
            <InputContainer
                key={'explosive_value_' + valueKey}
                label={I18n.t(valueTranslationKey)}
            >
                <TextInput
                    onChange={(event) => {
                        this.onExplosiveValueChanged(event, explosiveId, valueKey);
                    }}
                    value={convertedValue}
                    unit={I18n.t(Units.Explosives[this.props.unit][valueKey])}
                />
            </InputContainer>
        );
    };

    onAddButtonPressed = () => {
        const explosives   = this.state.explosives;
        const newExplosive = this.createNewExplosive();

        if (Array.isArray(explosives)) {
            explosives.push(newExplosive);
        } else {
            this.setState({
                explosives: [newExplosive],
            });
        }

        this.onChange();
    };

    createNewExplosive = () => {
        return {
            id: new Date().getTime(),
        };
    };

    onRemoveButtonPressed = (explosiveId) => {
        const explosives     = this.state.explosives;
        const explosiveIndex = _.findIndex(explosives, { id: explosiveId });

        explosives.splice(explosiveIndex, 1);

        this.onChange();
    };

    onExplosiveTypeChanged = (explosive, explosiveType, key) => {
        const explosives        = this.state.explosives;
        let explosiveTypeValues = {};

        if (explosiveType) {
            explosiveTypeValues = Explosives[explosiveType.value].values;
        }

        _.forEach(explosive, (value, key) => {
            if (key !== 'id' && explosiveTypeValues[key] !== UiValue) {
                explosive[key] = undefined;
            }
        });

        explosive.explosiveType = explosiveType.value;
        explosives[key]         = explosive;

        this.setState({ explosives });
        this.onChange();
    };

    renderExplosiveItem = (explosive = null, isLast = true, key) => {
        const explosiveType    = _.get(explosive, 'explosiveType');
        const explosiveId      = _.get(explosive, 'id');
        const showRemoveButton = this.props.explosives.length > 1;
        const explosiveItemKey = 'explosive_' + explosiveId;

        return (
            <div
                key={explosiveItemKey}
                className={classNames(
                    styles.explosiveContainer,
                )}
            >
                <InputContainer
                    label={I18n.t('explosives')}
                >
                    <SelectInput
                        label={I18n.t('explosive')}
                        onChange={
                            (newExplosiveType) => {
                                this.onExplosiveTypeChanged(explosive, newExplosiveType, key);
                            }
                        }
                        options={explosivesOptions}
                        placeholder={I18n.t('selectExplosive')}
                        value={explosiveType}
                        onAddButtonPressed={this.onAddButtonPressed}
                        showRemoveButton={showRemoveButton}
                        onRemoveButtonPressed={
                            () => {
                                this.onRemoveButtonPressed(explosiveId);
                            }
                        }
                        showAddButton={isLast}
                    />
                </InputContainer>
                {
                    this.renderExplosiveValues(explosive)
                }
            </div>
        );
    };

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.explosives !== prevState.explosives) {
            return { explosives: _.cloneDeep(nextProps.explosives) };
        }

        return null;
    }

    renderExplosivesList = () => {
        const explosives = this.state.explosives;

        if (!Array.isArray(explosives) || explosives.length === 0) {
            this.onAddButtonPressed();
        }

        return _.map(
            explosives,
            (explosive, key) => {
                const isLast = key === explosives.length - 1;

                return this.renderExplosiveItem(explosive, isLast, key);
            },
        );
    };

    render () {
        return (
            this.renderExplosivesList()
        );
    }
}

const Component = ExplosivesList;

Component.propTypes = {
    explosives: PropTypes.array,
    onChange:   PropTypes.func,
    unit:       PropTypes.string,
};

Component.defaultProps = {
    explosives: [],
    onChange:   _.noop,
    unit:       'metric',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;