import _                      from 'lodash';
import AlertBox               from '../../stateless/AlertBox';
import ComponentHelper        from '../../../helper/ComponentHelper';
import React                  from 'react';
import styles                 from './styles.module.scss';
import { AlertBoxActions }    from '../../../store/actions/alertBox';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

class AlertBoxManager extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    hideButtonPressed = (index) => {
        this.props.clearSingleAlert({ index });
    };

    render () {
        return (
            <div className={styles.alertBoxManager}>
                {this.props.alertBoxes.map(this.renderAlertBox)}
            </div>
        );
    }

    renderAlertBox = (data, index) => {
        if (data) {
            return (
                <AlertBox
                    onCloseButtonClick={() => {
                        this.hideButtonPressed(index);
                    }}
                    key={'alert-' + index}
                    text={data.text}
                    type={data.type}
                />
            );
        }

        return null;
    };
}

const Component = AlertBoxManager;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [
    'alertBoxes',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        alertBoxes: state.alertBox.alertBoxes,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    AlertBoxActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);