import ComponentHelper     from '../../../helper/ComponentHelper';
import IconCheck           from '../../../assets/icons/check.svg';
import IconCross           from '../../../assets/icons/cross.svg';
import IconType            from './IconType';
import PropTypes           from '../../PropTypes';
import React               from 'react';
import SelectionHelper     from '../../../helper/SelectionHelper';
import styles              from './styles.module.scss';
import { faCalendar }      from '@fortawesome/free-solid-svg-icons';
import { faMinusCircle }   from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle }    from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Icon extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <span className={styles.icon}>
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.check]:       <IconCheck />,
                [IconType.cross]:       <IconCross />,
                [IconType.plusCircle]:  <FontAwesomeIcon icon={faPlusCircle} />,
                [IconType.minusCircle]: <FontAwesomeIcon icon={faMinusCircle} />,
                [IconType.calendar]:    <FontAwesomeIcon icon={faCalendar} />,
            },
        );
    };
}

const Component = Icon;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;