import { makeActionCreator } from '../../helper/Store';

export const LicenseTypes = {
    BUY_LICENSE_KEY:               'Settings/BUY_LICENSE_KEY',
    CHECK_LICENSE_KEY:             'Settings/CHECK_LICENSE_KEY',
    CHECK_LICENSE_KEY_FAILED:      'Settings/CHECK_LICENSE_KEY_FAILED',
    CHECK_LICENSE_KEY_SUCCEEDED:   'Settings/CHECK_LICENSE_KEY_SUCCEEDED',
    CLAIM_LICENSE_KEY:             'Settings/CLAIM_LICENSE_KEY',
    CLAIM_LICENSE_KEY_FAILED:      'Settings/CLAIM_LICENSE_KEY_FAILED',
    CLAIM_LICENSE_KEY_SUCCEEDED:   'Settings/CLAIM_LICENSE_KEY_SUCCEEDED',
    RELEASE_LICENSE_KEY:           'Settings/RELEASE_LICENSE_KEY',
    RELEASE_LICENSE_KEY_FAILED:    'Settings/RELEASE_LICENSE_KEY_FAILED',
    RELEASE_LICENSE_KEY_SUCCEEDED: 'Settings/RELEASE_LICENSE_KEY_SUCCEEDED',
    SET_E_MAIL_ADDRESS:            'Settings/SET_E_MAIL_ADDRESS',
    SET_LICENSE_KEY:               'Settings/SET_LICENSE_KEY',
};

const buyLicenseKey = makeActionCreator(
    LicenseTypes.BUY_LICENSE_KEY,
);

const checkLicenseKey = makeActionCreator(
    LicenseTypes.CHECK_LICENSE_KEY,
);

const checkLicenseKeyFailed = makeActionCreator(
    LicenseTypes.CHECK_LICENSE_KEY_FAILED,
);

const checkLicenseKeySucceeded = makeActionCreator(
    LicenseTypes.CHECK_LICENSE_KEY_SUCCEEDED,
    {
        licenseKeyId: null,
    },
);

const claimLicenseKey = makeActionCreator(
    LicenseTypes.CLAIM_LICENSE_KEY,
    {
        clientKey:    null,
        licenseKeyId: null,
    },
);

const claimLicenseKeyFailed = makeActionCreator(
    LicenseTypes.CLAIM_LICENSE_KEY_FAILED,
);

const claimLicenseKeySucceeded = makeActionCreator(
    LicenseTypes.CLAIM_LICENSE_KEY_SUCCEEDED,
    {
        licenseKeyId: null,
    },
);

const releaseLicenseKey = makeActionCreator(
    LicenseTypes.RELEASE_LICENSE_KEY,
);

const releaseLicenseKeyFailed = makeActionCreator(
    LicenseTypes.RELEASE_LICENSE_KEY_FAILED,
);

const releaseLicenseKeySucceeded = makeActionCreator(
    LicenseTypes.RELEASE_LICENSE_KEY_SUCCEEDED,
);

const setEMailAddress = makeActionCreator(
    LicenseTypes.SET_E_MAIL_ADDRESS,
    {
        eMailAddress: null,
    },
);

const setLicenseKey = makeActionCreator(
    LicenseTypes.SET_LICENSE_KEY,
    {
        licenseKey: null,
    },
);

export const LicenseActions = {
    buyLicenseKey,
    checkLicenseKey,
    checkLicenseKeyFailed,
    checkLicenseKeySucceeded,
    claimLicenseKey,
    claimLicenseKeyFailed,
    claimLicenseKeySucceeded,
    releaseLicenseKey,
    releaseLicenseKeyFailed,
    releaseLicenseKeySucceeded,
    setEMailAddress,
    setLicenseKey,
};