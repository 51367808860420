import update                 from 'immutability-helper';
import { SettingsTypes }      from '../actions/settings';
import Units                  from '../../constants/Units';
import { ShotNumberMode }     from '../../constants/ShotNumberGenerator';
import { ShotNumberBaseDate } from '../../constants/ShotNumberGenerator';

const initialState = {
    unit:                         Units.Systems.metric.name,
    shotNumberMode:               ShotNumberMode.automatic,
    shotNumberBaseDate:           ShotNumberBaseDate.dateBuild,
    installWebAppBannerDismissed: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SettingsTypes.SET_UNIT:
            return update(state, {
                unit: {
                    $set: action.unit,
                },
            });

        case SettingsTypes.SET_SHOT_NUMBER_MODE:
            return update(state, {
                shotNumberMode: {
                    $set: action.shotNumberMode,
                },
            });

        case SettingsTypes.SET_SHOT_NUMBER_BASE_DATE:
            return update(state, {
                shotNumberBaseDate: {
                    $set: action.shotNumberBaseDate,
                },
            });

        case SettingsTypes.DISMISS_INSTALL_WEB_APP_BANNER:
            return update(state, {
                installWebAppBannerDismissed: {
                    $set: true,
                },
            });

        default:
            return state;
    }
}
