import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import FooterMenu      from '../FooterMenu';
import VersionNumber   from '../VersionNumber';
import I18n            from 'i18next';

class Footer extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.container}>
                <FooterMenu />
                <div className={styles.footerLogoContainer}>
                    <img
                        alt={I18n.t('footerLogoAlternativeText')}
                        className={styles.footerLogo}
                        src={require('../../../assets/images/footerLogo.png')}
                    />
                </div>
                <div className={styles.footerRightContainer}>
                    <span>
                        {I18n.t('copyright')}
                    </span>
                    <VersionNumber />
                </div>
            </div>
        );
    }
}

const Component = Footer;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;