import Routes   from '../../constants/Routes';
import { push } from 'connected-react-router';
import { put }  from 'redux-saga/effects';

const save = function* () {
    yield put(push(Routes.home));
};

export default {
    save,
};
