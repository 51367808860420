import _                            from 'lodash';
import StatelessInstallWebAppBanner from '../../stateless/InstallWebAppBanner';
import ComponentHelper              from '../../../helper/ComponentHelper';
import React                        from 'react';
import styles                       from './styles.module.scss';
import { AlertBoxActions }          from '../../../store/actions/alertBox';
import { bindActionCreators }       from 'redux';
import { connect }                  from 'react-redux';
import PropTypes                    from 'prop-types';
import { SettingsActions }          from '../../../store/actions/settings';
import Routes                       from '../../../constants/Routes';

class InstallWebAppBanner extends React.Component {
    installPrompt = null;

    constructor (props) {
        super(props);

        this.state = {
            installable: false,
            installed:   false,
        };
    }

    componentDidMount () {
        window.addEventListener('beforeinstallprompt', this.beforeInstallPromptListener);
    }

    componentWillUnmount () {
        window.removeEventListener('beforeinstallprompt', this.beforeInstallPromptListener);
    }

    beforeInstallPromptListener = (event) => {
        event.preventDefault();

        this.installPrompt = event;

        this.setState({
            installable: true,
        });
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    shouldPrintWebAppBanner = () => {
        const alreadyInstalled = this.state.installed;
        const bannerDismissed  = this.props.installWebAppBannerDismissed;
        const isPrintView      = this.props.currentRoute === Routes.printPreview;

        return !alreadyInstalled && !bannerDismissed && !isPrintView;
    };

    onInstallPressed = () => {
        if (this.installPrompt) {
            this.installPrompt.prompt();

            this.props.dismissInstallWebAppBanner();
        }
    };

    onCancelPressed = () => {
        this.props.dismissInstallWebAppBanner();
    };

    render () {
        const visible = this.shouldPrintWebAppBanner();

        return (
            <StatelessInstallWebAppBanner
                visible={visible}
                installable={this.state.installable}
                installableText={this.props.installableText}
                bookmarkText={this.props.bookmarkText}
                onCancelPressed={this.onCancelPressed}
                onInstallPressed={this.onInstallPressed}
            />
        );
    }
}

const Component = InstallWebAppBanner;

Component.propTypes = {
    text: PropTypes.string,
};

Component.defaultProps = {
    text: '',
};

Component.renderAffectingProps = [
    'currentRoute',
    'installWebAppBannerDismissed',
];

Component.renderAffectingStates = [
    'installable',
    'installed',
];

const mapStateToProps = state => (
    {
        currentRoute:                 state.router.location.pathname,
        installWebAppBannerDismissed: state.settings.installWebAppBannerDismissed,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    SettingsActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);