import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import FooterMenuItem  from '../FooterMenuItem';
import Routes          from '../../../constants/Routes';
import I18n            from 'i18next';

class FooterMenu extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.container}>
                <FooterMenuItem
                    label={I18n.t('settings')}
                    to={Routes.settings}
                />
                <FooterMenuItem
                    label={I18n.t('p3dSolutions')}
                    to={I18n.t('p3dSolutionsLink')}
                    external={true}
                />
                <FooterMenuItem
                    label={I18n.t('imprint')}
                    to={Routes.imprint}
                />
                <FooterMenuItem
                    label={I18n.t('dataProtection')}
                    to={Routes.dataProtection}
                />
            </div>
        );
    }
}

const Component = FooterMenu;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;