import ComponentHelper from '../../../helper/ComponentHelper';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import styles          from './styles.module.scss';

class ImageBackground extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getBackgroundStyle () {
        return {
            backgroundImage: 'url(' + this.props.image + ')',
            backgroundSize:  this.props.backgroundSize,
            backgroundColor: this.props.backgroundColor,
        };
    }

    render () {
        return (
            <div
                className={styles.imageBackgroundContainer}
                style={this.getBackgroundStyle()}
            >
                {this.props.children}
            </div>
        );
    }
}

const Component = ImageBackground;

Component.propTypes = {
    image:           PropTypes.string,
    backgroundSize:  PropTypes.string,
    backgroundColor: PropTypes.string,
    children:        PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
};

Component.defaultProps = {
    image:           '',
    backgroundSize:  'cover',
    backgroundColor: '',
    children:        [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;