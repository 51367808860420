import classNames      from 'classnames';
import Icon            from '../Icon';
import IconButtonTheme from './IconButtonTheme';
import IconType        from '../Icon/IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import SelectionHelper from '../../../helper/SelectionHelper';
import styles          from './styles.module.scss';
import Link            from '../Link';
import ComponentHelper from '../../../helper/ComponentHelper';

class IconButton extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.iconButton,
            SelectionHelper.get(
                this.props.theme,
                {
                    [IconButtonTheme.bigIcon]:    styles.iconButtonBigIcon,
                    [IconButtonTheme.redRounded]: styles.iconButtonRedRounded,
                },
            ),
        );

        return className;
    };

    render () {
        const finalClassName = this.getClassName();
        const icon           = this.renderIcon();

        if (this.props.onClick) {
            return (
                <div
                    className={finalClassName}
                    onClick={this.props.onClick}
                >
                    {icon}
                </div>
            );
        } else if (this.props.to) {
            return (
                <Link
                    className={finalClassName}
                    to={this.props.to}
                >
                    {icon}
                </Link>
            );
        }

        return null;
    }

    renderIcon = () => {
        return (
            <Icon iconType={this.props.iconType} />
        );
    };
}

const Component = IconButton;

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    onClick:  PropTypes.func,
    theme:    PropTypes.oneOf(Object.values(IconButtonTheme)),
    to:       PropTypes.string,
};

Component.defaultProps = {
    iconType: null,
    onClick:  null,
    theme:    null,
    to:       null,
};

Component.renderAffectingProps = [
    'iconType',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;