import classNames                                from 'classnames';
import ComponentHelper                           from '../../../helper/ComponentHelper';
import convert                                   from 'convert-units';
import PropTypes                                 from '../../PropTypes';
import React                                     from 'react';
import styles                                    from './styles.module.scss';
import I18n                                      from 'i18next';
import { InternalPressureInKiloPascalThreshold } from '../../../constants/Explosives';
import Units                                     from '../../../constants/Units';

class NotSafeToStackInRoomAlert extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        const pressureInKiloPascal = convert(this.props.internalPressureInPSI)
            .from(Units.InternalPressureBase)
            .to(Units.InternalPressureKiloPascalUnit)
        ;

        if (pressureInKiloPascal > InternalPressureInKiloPascalThreshold) {
            return (
                <div
                    className={classNames(styles.alertBox)}
                >
                    {I18n.t('notSafeToStackInRoomAlertMessage')}
                </div>
            );
        }

        return null;
    }
}

const Component = NotSafeToStackInRoomAlert;

Component.propTypes = {
    internalPressureInPSI: PropTypes.number,
};

Component.defaultProps = {
    internalPressureInPSI: null,
};

Component.renderAffectingProps = [
    'internalPressureInPSI',
];

Component.renderAffectingStates = [];

export default Component;