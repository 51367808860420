import { makeActionCreator } from '../../helper/Store';

export const SettingsTypes = {
    DISMISS_INSTALL_WEB_APP_BANNER: 'Settings/DISMISS_INSTALL_WEB_APP_BANNER',
    SET_SHOT_NUMBER_BASE_DATE:      'Settings/SET_SHOT_NUMBER_BASE_DATE',
    SET_SHOT_NUMBER_MODE:           'Settings/SET_SHOT_NUMBER_MODE',
    SET_UNIT:                       'Settings/SET_ACTIVE_UNIT',
};

const dismissInstallWebAppBanner = makeActionCreator(
    SettingsTypes.DISMISS_INSTALL_WEB_APP_BANNER,
);
const setShotNumberBaseDate      = makeActionCreator(
    SettingsTypes.SET_SHOT_NUMBER_BASE_DATE,
    {
        shotNumberBaseDate: null,
    },
);

const setShotNumberMode = makeActionCreator(
    SettingsTypes.SET_SHOT_NUMBER_MODE,
    {
        shotNumberMode: null,
    },
);

const setUnit = makeActionCreator(
    SettingsTypes.SET_UNIT,
    {
        unit: null,
    },
);

export const SettingsActions = {
    dismissInstallWebAppBanner,
    setShotNumberBaseDate,
    setShotNumberMode,
    setUnit,
};