import _ from 'lodash';
import ComponentHelper from '../../../helper/ComponentHelper';
import I18n from 'i18next';
import InputContainer from '../InputContainer';
import PropTypes from '../../PropTypes';
import React from 'react';
import SelectInput from '../SelectInput';
import {Boosters} from '../../../constants/Explosives';

const boostersOptions = _.map(
    Boosters, (booster, key) => {
        return {
            value: key,
            label: booster.name,
        };
    },
);

class BoostersList extends React.Component {
    constructor () {
        super();

        this.state = {
            boosters: [],
        };
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    onChange = () => {
        this.props.onChange(_.cloneDeep(this.state.boosters));
    };

    onAddButtonPressed = () => {
        const boosters   = this.state.boosters;
        const newBooster = this.createNewBooster();

        if (Array.isArray(boosters)) {
            boosters.push(newBooster);
        } else {
            this.setState({
                boosters: [newBooster],
            });
        }

        this.onChange();
    };

    createNewBooster = () => {
        return {
            id: new Date().getTime(),
        };
    };

    onRemoveButtonPressed = (boosterId) => {
        const boosters     = this.state.boosters;
        const boosterIndex = _.findIndex(boosters, { id: boosterId });

        boosters.splice(boosterIndex, 1);

        this.onChange();
    };

    onBoosterTypeChanged = (booster, boosterType) => {
        booster.boosterType = boosterType.value;

        this.onChange();
    };

    renderBoosterItem = (booster = null, isLast = true) => {
        const boosterType      = _.get(booster, 'boosterType');
        const boosterId        = _.get(booster, 'id');
        const showRemoveButton = this.props.boosters.length > 1;
        const boosterItemKey   = 'booster_' + boosterId;

        return (
            <div
                key={boosterItemKey}
            >
                <InputContainer
                    label={I18n.t('booster')}
                >
                    <SelectInput
                        label={I18n.t('booster')}
                        onChange={
                            (newBoosterType) => {
                                this.onBoosterTypeChanged(booster, newBoosterType);
                            }
                        }
                        options={boostersOptions}
                        placeholder={I18n.t('selectBooster')}
                        value={boosterType}
                        onAddButtonPressed={this.onAddButtonPressed}
                        showRemoveButton={showRemoveButton}
                        onRemoveButtonPressed={
                            () => {
                                this.onRemoveButtonPressed(boosterId);
                            }
                        }
                        showAddButton={isLast}
                    />
                </InputContainer>
            </div>
        );
    };

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.boosters !== prevState.boosters) {
            return { boosters: _.cloneDeep(nextProps.boosters) };
        }

        return null;
    }

    renderBoostersList = () => {
        const boosters = this.state.boosters;

        if (!Array.isArray(boosters) || boosters.length === 0) {
            this.onAddButtonPressed();
        }

        return _.map(
            boosters,
            (booster, key) => {
                const isLast = key === boosters.length - 1;

                return this.renderBoosterItem(booster, isLast);
            },
        );
    };

    render () {
        return (
            this.renderBoostersList()
        );
    }
}

const Component = BoostersList;

Component.propTypes = {
    boosters: PropTypes.array,
    onChange: PropTypes.func,
    unit:     PropTypes.string,
};

Component.defaultProps = {
    boosters: [],
    onChange: _.noop,
    unit:     'metric',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;