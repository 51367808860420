export default {
    breacherInfo:            '/breacher-info',
    breachReports:           '/breach-reports',
    customCalculation:       '/custom-calculation',
    dataProtection:          '/data-protection',
    home:                    '/',
    imprint:                 '/imprint',
    newBreachReport:         '/new',
    newToMSDDiagram:         '/new-to-msd-diagram',
    preCalculatedBreachPops: '/pre-calculated-breach-pops',
    printPreview:            '/print',
    settings:                '/settings',
};
